import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ViewCart.scss';
import { Button } from 'components/Form';
import { IonIcon } from '@ionic/react';
import { cart as cartIcon } from 'components/Icons';
import Helper from 'Helper';
import { withNamespaces } from 'react-i18next';
import { onlyCartMapStateToProps } from 'store/maps/cart';

/**
 * View cart button.
 */
const ViewCart = ({ children, href, onClick, className, t, cart }) => {
  const buttonProps = {};
  if (href) {
    buttonProps.href = href;
  }
  return (
    <div className={classNames('view-cart', className, 'hide-if-ee')}>
      <Button onClick={onClick} {...buttonProps} full large style="background:#2ecc71">
        <div className="sides cart">
          <b>{cart.total}</b> <IonIcon icon={cartIcon} />
        </div>
        {children}
        {!children && t('View Cart')}
        <div className="sides price">{Helper.amountFormat({amount: cart.amount})}</div>
      </Button>
    </div>
  );
};

ViewCart.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

ViewCart.defaultProps = {
  onClick: () => null,
};

export default connect(onlyCartMapStateToProps, null)(withNamespaces()(ViewCart));
