import React from 'react';
import PropTypes from 'prop-types';
import { IonIcon, IonCol } from '@ionic/react';
import i18next from 'i18next';
import ReactImageAppear from 'react-image-appear';

import './List.scss';
import { withNamespaces } from 'react-i18next';

/**
 * List of categories
 */
export const List = ({ onSelect, name, img, icon, type, t }) => {
  return (
    <IonCol onClick={onSelect} size="3">
      <div className="img">
        {icon && <IonIcon icon={icon} />}
        {img && <ReactImageAppear src={img} />}
      </div>
      <div className="name">{name[i18next.language] || t(name)}</div>
    </IonCol>
  );
};

List.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      ar: PropTypes.string,
      en: PropTypes.string,
    }),
  ]),
  icon: PropTypes.node,
  img: PropTypes.string,
  onSelect: PropTypes.func,
};

List.defaultProps = {
  type: 'icons',
  onSelect: () => null,
};

export default withNamespaces()(List);
