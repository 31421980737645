import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Page.scss';
import {
  IonPage,
  IonTitle,
  IonIcon,
  IonContent,
  IonModal,
  IonHeader,
  IonToolbar,
  IonSearchbar,
  IonCol,
  IonGrid,
  IonRow,
  IonLoading
} from '@ionic/react';

import { Header } from 'components';
import { search as searchIcon, chevronDownOutline, closeOutline } from 'ionicons/icons';
import Helper from 'Helper';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { cart as cartIcon } from 'components/Icons';
import ProductItem from 'components/Customs/ProductItem/ProductItem';
import { onlyCartMapStateToProps } from 'store/maps/cart';
import AppService from 'services/app.service';
import ProductModal from 'containers/account/ProductView/ProductModal';

import { useHistory } from 'react-router';

const service = new AppService();

/**
 * A page container for the app.
 */
const Page = ({
  children,
  id,
  t,
  line,
  hasSearch,
  hasCart,
  hasNeedHelp,
  hasDeliverTo,
  className,
  withPage,
  cart,
  noHeader,
  headerChildren,
  auth,
  loading,
  ...resetProps
}) => {
  const [search, setSearch] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResutls] = useState([]);
  const [searching, setSearching] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  // const [showLoading, setShowLoading] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (search) {
      setSearchLoading(true);
      service
        .search(search)
        .then((results) => {
          setSearchResutls(results);
          setSearchLoading(false);
        })
        .catch(() => setSearchLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  let headerButtons = [];
  headerButtons = headerButtons.concat(resetProps.headerButtons);

  // const NeedHelp = () => <div className="need-help">{t('Need help?')}</div>;
  // if (hasNeedHelp) headerButtons.push({ render: NeedHelp, href: Helper.getRoute('support') });

  if (hasSearch)
    headerButtons.push({ icon: searchIcon, onClick: () => {
      history.push(Helper.getRoute('products'), {
        mode : 'search'
      });
    }});

  if (hasCart)
    headerButtons.push({
      icon: cartIcon,
      href: Helper.getRoute('cart'),
      badge: String(cart.total),
    });

    

  /**
   * With IonPage component.
   */
  const withIonPage = <IonContent>{children}</IonContent>;

  const { store } = auth;

  const handleSearchClick = (p) => {
    setSearching(false);
    setTimeout(() => {
      history.push(Helper.getRoute('products'), {
        search : p
      });
    }, 300);
    return false;
  }

  return (
    <>
      <IonPage className={classNames('page', id, line && 'inline', className)}>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={loading}
          // onDidDismiss={() => setShowLoading(false)}
          message={t('Please wait...')}
        />
        {noHeader == false ? (
          <Header buttons={headerButtons} toolbar={headerChildren} {...resetProps}>
            {hasDeliverTo && (
              <IonTitle className="deliver-to">
                <Link to={Helper.getRoute('locations')}>
                  <span>الطلب من</span>
                  <button>
                    البيت <IonIcon icon={chevronDownOutline} />
                  </button>
                </Link>
              </IonTitle>
            )}
          </Header>
        ) : ''}
        {withPage ? withIonPage : children}
      </IonPage>

      <IonModal isOpen={searching} cssClass="gs-search search-query-modal">
        <IonHeader>
          <IonToolbar>

            <IonSearchbar
              mode="md"
              showCancelButton="always"
              placeholder={t('Search')}
              onIonChange={(e) => setSearch(e.detail.value)}
              onIonCancel={() => {
                setSearching(!searching);
                setProducts([]);
              }}
              cancelButtonIcon={closeOutline}
            />

          </IonToolbar>
        </IonHeader>
        <IonContent>
          {searchLoading && (
            <IonGrid className="gs-products-grid">
              <IonRow>
                {
                  [1, 2, 3, 4, 5, 6].map((p) => (
                    <IonCol className="loading" key={p} size="12">
                      <ProductItem loading />
                    </IonCol>
                  ))
                }
              </IonRow>
            </IonGrid>
          )}

          {!searchLoading && (
            <div className="search-result">
              {
                searchResults.map((p) => (
                  <div key={p.id} className="search-result-item" onClick={() => { handleSearchClick(p) }}>
                    <div>{p.label}</div>
                  </div>
                ))
              }
            </div>
          )}

        </IonContent>
      </IonModal>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  noHeader : PropTypes.bool,
  className: PropTypes.string,
  line: PropTypes.bool,
  hasSearch: PropTypes.bool,
  hasCart: PropTypes.bool,
  hasNeedHelp: PropTypes.bool,
  hasDeliverTo: PropTypes.bool,
  headerButtons : PropTypes.array
  // headerChildren : PropTypes.node
};

Page.defaultProps = {
  headerButtons:[],
  withPage: true,
  noHeader : false,
  hasSearch: false,
  hasCart: false,
  line: false,
  hasNeedHelp: false,
  hasDeliverTo: false,
};

export default connect(onlyCartMapStateToProps, null)(withNamespaces()(Page));
