import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  IonSlides,
  IonSlide,
  IonContent,
  IonPage,
  IonFooter,
  IonHeader,
  IonToolbar,
  IonLabel,
} from '@ionic/react';

import './Splash.scss';
import { Button, Radio } from 'components/Form';
import { chevronForwardOutline } from 'ionicons/icons';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import Helper from 'Helper';
import ReactImageAppear from 'react-image-appear';
import { Modal } from 'components';
import i18next from 'i18next';

import AuthService from 'services/auth.service';
import AuthAction from 'store/actions/auth';
import CacheAction from 'store/actions/cache';
import { Mixpanel } from '@ionic-native/mixpanel';


export class Splash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEnd: false,
      lang: 'en',
      isLang: false,
      hideSlides: true,
    };

    this.service = new AuthService();

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSliderRef = this.handleSliderRef.bind(this);
    this.handleSkip = this.handleSkip.bind(this);

    this.slider = null;

  }

  /**
   * Handles the confirmation button
   */
  handleConfirm() {
    const { history } = this.props;
    this.setState({ hideSlides: true }, () => {
      if (history) {
        history.push(Helper.getRoute('login'));
      }
    });
  }

  componentDidMount() {
    const { cache, setCache, auth, history } = this.props;

    console.log('version', cache['version']);

    if (isNaN(cache['version']) || cache['version'] < 1.2) {
      setCache({
        address : {},
        version : 1.3
      });

    }

    setTimeout(() => {
      if (history['location']['pathname'] == '/') {
        Mixpanel.track('Intro wait');
        this.navToHome();
      }
    }, 5000);
  }

  // handleSkip() {
  //   const { history } = this.props;
  //   history.push(Helper.getRoute('tap_payment'), {
  //     url : "https://invoices.sandbox.tap.company/invoice/inv_Gyte1311012nLzV527580"
  //   });
  // }

  handleSkip() {
    Mixpanel.track('Skip Intro video');
    this.navToHome();
  }

  navToHome() {
    const { history } = this.props;
    history.push(Helper.getRoute('home'));
  }

  componentWillUnmount() {
    this.setState({ hideSlides: true });
  }

  handleSliderRef(ref) {
    if (!this.slider) {
      this.slider = ref;
      if (ref) {
        ref.addEventListener('ionSlideWillChange', (e) => {
          this.setState({ isEnd: e.target.swiper.isEnd });
        });
      }
    }
  }

  handleStart() {
    // return this.props.history.push(Helper.getRoute('home'));;
    this.service.signInAnonymously()
      .then(user => {
        this.props.setUser(user);
        console.log('user', user);
        this.props.history.push(Helper.getRoute('home'));
      })
      .catch(err => {
        console.log('err', err);
      })
  }

  render() {
    const { t } = this.props;
    const { isEnd, hideSlides, isLang } = this.state;

    const slideOpts = {
      initialSlide: 0,
      speed: 200,
    };


    return (
      <>
        <IonPage className="splash-page">
          <IonContent>
            <div >
              <div className="gif-container">
                <img src="assets/images/splash-video.gif"></img>
              </div>
            </div>
          </IonContent>
          <IonFooter className="skip-button-container" onClick={this.handleSkip}>
            <div className="btn-skip">
              تخطي
            </div>
          </IonFooter>
        </IonPage>
      </>
    );
  }
}

// export default withNamespaces()(withRouter(Welcome));
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(Splash)));


/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setUser: (data) => dispatch(AuthAction.setUser(data)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, cache }) {
  return {
    cache,
    auth,
  };
}