import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Checkout.scss';
import { Page } from 'components';
import { Button, Radio, Input } from 'components/Form';
import ReactImageAppear from 'react-image-appear';
import { withNamespaces } from 'react-i18next';
import { onlyCartMapStateToProps } from 'store/maps/cart';
import i18next from 'i18next';
import OrderService from 'services/order.service';
import Helper from 'Helper';
import CartAction from 'store/actions/cart';
import CacheAction from 'store/actions/cache';
import AuthAction from 'store/actions/auth';

import TapHelper from 'libs/tap-helper';
import { IonContent, IonFooter, IonItem, 
    IonSegment,
    IonLabel,
  IonSegmentButton } from '@ionic/react';
import AuthService from 'services/auth.service';

import mixpanel from 'mixpanel-browser';

// import Helper from 'Helper';

class Checkout extends Component {
  constructor(props) {
    super(props);
    
    let { address } = props.cache;
    if (isNaN(address['id'])) {
      address['id'] = 0;
      console.log('error address');
    }

    // if (this.props.history != null) {
    //   address = this.props.history.location.state['address'];
    // } else {
    //   address['id'] = 
    // }
    
    this.state = {
      ladoing: false,
      address_id : address['id'],
      paymentMethod: '',
      notFoundMethod: 'anytime',
      note: '',
      paymentMethodAvailable : [],
      contactInfo : {
        name : '',
        email : '',
        phone : ''
      },
      enableContactForm : false,
      formError : {
        name : '',
        email : '',
        phone : '',
        password : ''
      },
      orderTypes : [],
      type : 3,
      email : '',
      name : '',
      phone : '',
      password : '',
      signTap : 'signup',
    };

   this.orderService = new OrderService();
   this.authService = new AuthService;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.navToLogin = this.navToLogin.bind(this);
  }

  componentDidMount() {
    let { address } = this.props.cache;
    const { t, auth, history } = this.props;
    const { user } = auth;

    if (address == null || address == undefined) {
      history.push(Helper.getRoute('cart'));
      return;
    }

    if (user.type == 'guest') {
      this.setState({enableContactForm : true})
    }

    // if ()

    const paymentMethod = [
      {
        label: t('Cash'),
        value: 'CASH',
        checked : true
      },
      {
        label: t('Online payment'),
        value: 'ONLINE',
        // disabled : true
      }
    ];

    const orderTypes = [
      {
        label : t('Livraison'),
        value : 3,
        checked : true
      },
      {
        label : t('Emporter'),
        value : 4
      },
    ];

    this.setState({
      orderTypes : orderTypes,
    });

    // const riyadhArr = ['riyadh', 'الرياض', 'Riyadh'];
    // if (riyadhArr.includes(address['city'])) {
      
    // } else {
    //   this.setState({
    //     paymentMethodAvailable : [
    //       {
    //         label: t('الدفع الإلكتروني'),
    //         value: 'ONLINE',
    //       }
    //     ]
    //   })
    // }
  }


  /**
   * Fetch cart items by product type.
   */
  getItemsByType(type) {
    const { cart } = this.props;
    return cart.items.filter((item) => item.type === type);
  }

  /**
   * Handle submit.
   */
  handleSubmit() {
    const { t, cart, auth, history, emptyCart, cache, setCache, setUser } = this.props;
    const { paymentMethod, notFoundMethod, note, address_id, name, phone, email, password, enableContactForm, type } = this.state;
    const { branch } = cache;

    if (enableContactForm) {
      let errors = {};
      if (name == '') {
        errors['name'] = t('Please type your full name');
        mixpanel.track('Invalid name', {
          error : true,
          severity : 1
        });
      }

      if (Helper.phone_validator(phone)) {
        mixpanel.track('Invalid phone', {
          email : phone,
          error : true,
          severity : 1
        });
        errors['phone'] = t('Phone number is incorrect');
      }
  
      if (Helper.email_validator(email)) {
        mixpanel.track('Invalid email', {
          email : email,
          error : true,
          severity : 1
        });
        errors['email'] = t('Email is invalid');
      }

      if (password == '') {
        mixpanel.track('Invalid password', {
          error : true,
          severity : 1
        });
        errors['password'] = t('Please type a valid password');
      }

      if (Object.keys(errors).length) {
        return this.setState({
          formError : errors
        });
      } 
    }
    

    let coupon_code = '';
    if ('coupon_code' in cache) {
      coupon_code = cache['coupon_code'];
    }

    // if (paymentMethod === '') {
    //   mixpanel.track('Select payment method', {
    //     error : true,
    //     severity : 1
    //   });
    //   return alert(t("Please select a payment method"));
    // }

    // if (notFoundMethod === '') {
    //   return alert(t("الرجاء اختيار وقت توصيل الشحنة"));
    // }

    this.setState({ loading: true });

    const items = cart.items.map(item => ({
      product_id: item.id,
      quantity: item.quantity,
      variants: item.variants || null
    })) || [];

    let params = {
      items,
      payment_method: 'CASH',
      address_id,
      coupon_code,
      branch_id : branch['id'],
      type
    }

    if (enableContactForm) {
      params = {...params, name, phone, email, password};
    }

    this.orderService
      .createOrder(params)
      .then(async ({order, payment}) => {
        
        const resp = []
        this.setState({ loading: false });
        setCache({coupon_code : ''});

        if (paymentMethod === 'ONLINE' && payment?.type === 'ONLINE') {

          if (payment?.url === false) {
            alert('error');
            history.push(Helper.getRoute('thank-you'));
            return;
          }

          // history.push(Helper.getRoute('tap_payment'), {
          //   url : resp['online_payment']['url']
          // });

          this.handleOnlinePayment(payment?.url);

        } else {
          emptyCart(auth);
          history.push(Helper.getRoute('thank-you'));
        }

        mixpanel.track('Order Placed', {

        });

        this.authService.refresh()
          .then(({user, access_token}) => {
            setUser({...user, access_token})
          })
          .catch(err => {
            Helper.logError('Checkout - refresh auth', err)
          })

      })
      .catch(() => this.setState({ loading: false }));
  }

  navToLogin() {
    const { history } = this.props;
    history.push(Helper.getRoute('login-password'), {
      returnTo : Helper.getRoute('checkout')
    });
  }

  handleOnlinePayment(url) {
    const { history, emptyCart, auth } = this.props;
    this.setState({ loading: true });

    const tapHelper = new TapHelper({ url });
    const browser = tapHelper.open();
    browser.on('message').subscribe(event => {
      // Event Looklike ==> {"type":"message","data":{"action":"close"}}
      const { data } = event;

      browser.close();

      setTimeout(() => {
        this.setState({ loading: false });

        if (data['action'] == 'done') {
          emptyCart(auth);
          history.push(Helper.getRoute('thank-you'));
        } else if (data['action'] == 'error') {
          alert('لم تتم عملية الدفع بنجاح، يمكنك اختيار طريقة دفع اخرى');
        }

      }, 300);
    });

  }

  /**
   * Render the component.
   */
  render() {
    const { 
      paymentMethod,
      notFoundMethod,
      note, 
      loading, 
      paymentMethodAvailable, 
      contactInfo, 
      enableContactForm,
      formError,
      name,
      phone,
      email,
      password,
      signTap,
      orderTypes,
      type
    } = this.state;
    const { t } = this.props;

    const inStockItems = this.getItemsByType('MAIN') || [];
    const outOfStockItems = this.getItemsByType('CUSTOM') || [];

    const SigninForm = () => {

      return (
        <>
          <div className='form-group item-phone'>
            <Input
              type="tel"
              hasError={formError.phone}
              value={phone}
              onChange={(phone) => this.setState({ phone })}
              placeholder={t('Phone number')}
            />
          </div>
          <div className='form-group item-password'>
            <Input
              name="password"
              type="password"
              hasError={formError.password}
              autofocus
              value={password}
              onChange={(password) => this.setState({ password })}
              placeholder={t('Password')}
            />
          </div>
        </>
      )
    }

    const SignupForm = () => {

      return (
        <>
          <div className='form-group'>
            <Input
              name="full_name"
              hasError={formError.name}
              autofocus
              value={name}
              onChange={(name) => this.setState({ name })}
              placeholder={t('Full name')}
            />
          </div>
          <div className='form-group item-phone'>
            <Input
              type="tel"
              hasError={formError.phone}
              value={phone}
              onChange={(phone) => this.setState({ phone })}
              placeholder={t('Phone number')}
            />
          </div>
          <div className='form-group item-email'>
            <Input
              name="email"
              hasError={formError.email}
              autofocus
              value={email}
              onChange={(email) => this.setState({ email })}
              placeholder={t('Email')}
            />
          </div>
          <div className='form-group item-password'>
            <Input
              name="password"
              type="password"
              hasError={formError.password}
              autofocus
              value={password}
              onChange={(password) => this.setState({ password })}
              placeholder={t('Password')}
            />
          </div>
        </>
      )
    }

    return (
      <>
        <Page
          className="checkout-page has-no-tabs"
          backButton
          title={t('Checkout')}
          hasNeedHelp
          withPage={false}
        >
          <IonContent>
            <div className="checkout-page-wrapper">
                {enableContactForm && (
                  <div className='contact-info'>
                    <div className="checkout-section-title">{t('Create an account')}</div>
                    <p style={{fontSize : '13px', 'margin' : 0}}>{t('Please enter your full name and phone number to continue')}</p>
                    <p style={{fontSize : '13px', color : '#3498db'}}
                      onClick={this.navToLogin}
                    >{t('You have an account?')}</p>

                    {/* <div className='' style={{paddingBottom : '10px'}}>
                      <IonSegment value={signTap} onIonChange={(e) => this.setState({signTap : e.target.value})}>
                        <IonSegmentButton value="signup">
                          <IonLabel>{t('Create account')}</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="signin">
                          <IonLabel>{t('Login')}</IonLabel>
                        </IonSegmentButton>
                      </IonSegment>
                    </div> */}

                    <div className='form-group'>
                      <Input
                        name="full_name"
                        hasError={formError.name}
                        autofocus
                        value={name}
                        onChange={(name) => this.setState({ name })}
                        placeholder={t('Full name')}
                      />
                    </div>
                    <div className='form-group item-phone'>
                      <Input
                        type="tel"
                        hasError={formError.phone}
                        value={phone}
                        onChange={(phone) => this.setState({ phone })}
                        placeholder={t('Phone number')}
                      />
                    </div>
                    <div className='form-group item-email'>
                      <Input
                        name="email"
                        hasError={formError.email}
                        autofocus
                        value={email}
                        onChange={(email) => this.setState({ email })}
                        placeholder={t('Email')}
                      />
                    </div>
                    <div className='form-group item-password'>
                      <Input
                        name="password"
                        type="password"
                        hasError={formError.password}
                        autofocus
                        value={password}
                        onChange={(password) => this.setState({ password })}
                        placeholder={t('Password')}
                      />
                    </div>
                    
                    
                  </div>
                )}
                

                <div className="form-control">
                  <div className="checkout-section-title">{t("sélectionnez l'une de ces options")}</div>

                  <Radio
                    name="payment_method"
                    // full
                    value={type}
                    onChange={(type) => {
                      if(type == 'ONLINE') {
                        return;
                      }
                      this.setState({ type })
                    }}
                    cols={true}
                    options={orderTypes}
                  />
                </div>

              
                <div className="label">
                  <h4>{t('Note')}</h4>
                </div>
                <div className="form-control d-none">
                  <div className="label">
                    {t("متحنة ؟")}
                  </div>
                  <Radio
                    name="not_found_method"
                    value={notFoundMethod}
                    onChange={(notFoundMethod) => this.setState({ notFoundMethod })}
                    options={[
                      {
                        label: t('في أي وقت'),
                        value: 'anytime',
                      },
                      {
                        label: t('صباحاً'),
                        value: 'morning',
                      },
                      {
                        label: t('مساءاً'),
                        value: 'evening',
                      }
                    ]}
                  />
                </div>
                <div className="form-control">
                  <Input
                    type="textarea"
                    value={note}
                    placeholder={t('Do you have any note?')}
                    onChange={(note) => this.setState({ note })}
                  />
                </div>
                
            </div>  
          </IonContent>
          <IonFooter>
            <div className="form-control p-2">
              <Button loading={loading} large full onClick={this.handleSubmit}>
                {t('Order now')}
              </Button>
            </div>
          </IonFooter>
        </Page>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Checkout));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({cache, select, ...state }) {
  return {
    ...onlyCartMapStateToProps(state),
    history: select.history,
    cache,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setUser: (data) => dispatch(AuthAction.setUser(data)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
    emptyCart: (auth) => dispatch(CartAction.emptyCart(auth)),
  };
}
