import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Login.scss';
import { IonItem, IonLabel, IonText, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { Input, Button, Select } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import Layout from '../Layout/Layout';
import { withRouter } from 'react-router';
import AuthService from 'services/auth.service';
import AuthAction from 'store/actions/auth';
import Helper from 'Helper';

import * as _ from 'lodash';

import CacheAction from 'store/actions/cache';
import Http from 'libs/http';

class LoginWithPassword extends Component {
    constructor(props) {
        super(props);

        const { name, phone, email } = _.get(this.props, 'cache.authPage', {});
        
        this.state = {
          name: name || '',
          phone: (phone || '').replace('+966', ''),
          email :  email || '',
          password : '',
          mobile: 2,
          alert: null,
          loading: false,
          returnTo : null
        };

        // this.history = useHistory();

        this.http = new Http;

        this.service = new AuthService();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
      const {setCache, cache} = this.props;
      const { authPage } = cache;
      const returnTo = _.get(this.props, 'history.location.state.returnTo', null);
      console.log('return', returnTo);
      if (returnTo != null) {
        setCache({
          authPage : {...authPage, returnTo}
        });
      }
    }

    handleNameChange(name) {
      if(name.match("^[a-zA-Z\u0621-\u064A0-9٠-٩_]*$")){
        this.setState({name})
      }
    }

    handleSubmit(e) {
      e.preventDefault();
      const { name, phone, email, password } = this.state;
      const { state } = this.props.location;
      const { cache } = this.props;
      const { authPage } = cache;

      const returnTo = _.get(this.props, 'history.location.state.returnTo', null);
      // const { name, phone, email } = authPage;
      

      this.setState({ loading: true, alert: null });

      this.http.post('auth/login', {
        phone,
        password,
      })
      .then(data => {
        const { error, user, message, access_token } = data;
        this.setState({ loading: false });

        if (error) {
          setTimeout(() => {
            this.setState({ alert: null })
          }, 5000);
          return this.setState({ alert: message });
        }

        this.props.setUser({ ...user, access_token });
        this.props.history.push(returnTo || Helper.getRoute('home'));
      })
      .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
      });
    }

    /**
     * Handles form submit.
     * @param {Object} e
     */
    async handleSubmit_normal(e) {
      const {setCache, cache} = this.props;
      const { authPage } = cache;
        e.preventDefault();
        const { name, phone, loading, email } = this.state;

        setCache({
          authPage : {...authPage, name, phone, email}
        });

        let fullNumber = phone;
        if (!name || !phone) {
            this.setState({ alert: this.props.t('Please fill all fields.') });
            return;
        }

        if(!(phone.match(/^[05][0-9]{8,9}$/) && ((phone.length == 9 && phone.startsWith('5')) || (phone.length == 10 && phone.startsWith('0'))))){
            this.setState({ alert: this.props.t('Phone number is not valid') });
            return;
        }
        if(phone.length === 9){
            fullNumber = '0' + phone
        }

        
        if(!loading){
            this.setState({ loading: true, alert: null });
            this.service
                .signIn({
                    name,
                    phone: fullNumber,
                })
                .then((res) => {
                    this.setState({ loading: false });
                    if (res.error) {
                      return this.setState({ alert: res.message });
                    }
    
                    this.props.history.push(Helper.getRoute('verify'), {});
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    console.error(err);
                });
        }
    }

    /**
     * Handles back button action.
     */
    handleGoBack() {
        // return this.props.history.goBack();
    }

    /**
     * Render the component.
     */
    render() {
        const { loading, name, phone, alert, email, username, password } = this.state;
        const { t } = this.props;

        return (
            <Layout title={t('Login')}>
                <form onSubmit={this.handleSubmit}>
                    <IonItem lines="none">
                        <IonLabel className="head ion-text-wrap">
                            <IonText color="primary">
                                <h1>{t('Login')}</h1>
                            </IonText>
                            <p>{t('Please enter your phone number and your password.')}</p>
                        </IonLabel>
                    </IonItem>
                    {alert !== null && <div className="form-control alert alert-danger">{alert}</div>}
                    <IonItem lines="none" className='item-email'>
                      <Input
                        name="phone"
                        autofocus
                        value={phone}
                        onChange={(phone) => this.setState({phone})}
                        placeholder={t('Your phone number')}
                      />
                    </IonItem>
                    <IonItem lines="none" className='item-email'>
                      <Input
                        name="password"
                        type="password"
                        autofocus
                        value={password}
                        onChange={(password) => this.setState({password})}
                        placeholder={t('Your password')}
                      />
                    </IonItem>
                    <IonItem lines="none">
                      <Button type="submit" loading={loading} full large>
                        {t('Login')}
                      </Button>
                    </IonItem>
                </form>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(LoginWithPassword)));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, select, cache }) {
    return {
      cache,
      history: select.history,
      auth,
    };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setUser: (data) => dispatch(AuthAction.setUser(data)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}
