import { SET_COLLECTION, RESET_CACHE } from '../actions/collection';

const initialState = {
  collections : {}
};

const collection = (state = initialState, action) => {

  const withStore = (data) => {
    return {
      blocks : [],
      html : '',
      script : '',
      plugins : [],
      ...data
    };
  };

  switch (action.type) {
    case SET_COLLECTION:
      state['collections'][action.data['id']] = withStore(action.data);
      return {
        ...state
      };

    case RESET_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default collection;
 