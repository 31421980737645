import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Profile.scss';
import { Page } from 'components';
import { withNamespaces } from 'react-i18next';
import { logOutOutline } from 'ionicons/icons';
import { Input, Button, Select } from 'components/Form';
import { IonItem } from '@ionic/react';
import AuthService from 'services/auth.service';
import Helper from 'Helper';
import AuthAction from 'store/actions/auth';
import AppService from 'services/app.service';
import * as _ from 'lodash';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      name: _.get(props, 'auth.user.name') || '',
      phone: _.get(props, 'auth.user.phone') || '',
      email: _.get(props, 'auth.user.email') || '',

      building_number: _.get(props, 'auth.user.building_number') || '',
      residential_number: _.get(props, 'auth.user.residential_number') || '',
      additional_instructions: _.get(props, 'auth.user.additional_instructions') || '',
    };

    this.service = new AppService();
    this.auth = new AuthService();

    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { auth } = this.props;

    console.log('atuh', auth);
  }

  /**
   * Handles submit
   */
  handleSubmit(e) {
    e.preventDefault();

    const { building_number, residential_number, additional_instructions } = this.state;

    this.setState({ loading: true });
    this.service
      .updateProfile(this.props.auth, {
        building_number,
        residential_number,
        additional_instructions,
      })
      .then(() => {
        const user = this.props.auth.user;
        this.props.setUser({
          ...user,
          building_number,
          residential_number,
          additional_instructions,
        });
        this.setState({ loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  /**
   * Handles logout.
   */
  async handleLogOut() {
    const { history, reset } = this.props;

    // Logout from firebase
    await this.auth.logOut();

    // Reset redux
    reset();

    history.push(Helper.getRoute('login'));

    return;
  }

  /**
   * Render the component.
   */
  render() {
    const { building_number, residential_number, additional_instructions } = this.state;
    const { t, auth } = this.props;
    // const buttons = [{ icon: logOutOutline, color: 'danger', onClick: this.handleLogOut }];

    return (
      <Page className="profile-page" backButton title={t('Profile')}>
        {  }
        <form onSubmit={this.handleSubmit}>
          <div className="form-control">
            <Input placeholder={t('Full name')}  
              value={auth.user.name} 
              onChange={(name) => this.setState({name})}
            />
          </div>
          <div className="form-control">
            <IonItem className="phone-numbers item-phone" lines="none">
              <Input
                type="tel"
                value={auth.user.phone}
                placeholder={t('Your phone number')}
                onChange={(phone) => this.setState({phone})}
              />
            </IonItem>
          </div>
          <div className="form-control">
            <IonItem className="phone-numbers item-email" lines="none">
              <Input
                type="tel"
                value={auth.user.email}
                placeholder={t('Email')}
                onChange={(email) => this.setState({email})}
              />
            </IonItem>
          </div>
          <h5 style={{ marginTop: 35 }}>{t('Address informations')}</h5>
          <div className="form-control">
            <Input
              value={building_number}
              onChange={(v) => this.setState({ building_number: v })}
              placeholder={t('Building/Villa N°')}
            />
          </div>
          <div className="form-control">
            <Input
              value={residential_number}
              onChange={(v) => this.setState({ residential_number: v })}
              placeholder={t('Residential Unit N°')}
            />
          </div>
          <div className="form-control">
            <Input
              value={additional_instructions}
              onChange={(v) => this.setState({ additional_instructions: v })}
              placeholder={t('Additional Instructions')}
            />
          </div>
          <div className="form-control">
            <Button type="submit" large full>
              {t('Save')}
            </Button>
          </div>
        </form>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Profile));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ select, auth }) {
  return {
    history: select.history,
    auth,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    reset: () => dispatch(AuthAction.reset()),
    setUser: (data) => dispatch(AuthAction.setUser(data)),
  };
}
