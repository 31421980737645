import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Loading.scss';

/**
 * A sample button.
 */
const Loading = ({
  className,
  ...props
}) => {

  return (
    <div className={classNames(className)}>
      {/* <video preload="auto" muted loop playsInline autoPlay
        
        src="/assets/images/loading.mp4"
      ></video> */}
      <img src="/assets/images/loading.gif" style={{width:'100px',height:'100px'}}></img>
    </div>
  );
};

export default Loading;
