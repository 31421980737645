import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

import './Billing.scss';
import { Page } from 'components';
import { withNamespaces } from 'react-i18next';
import ReactImageAppear from 'react-image-appear';
import { IonText, IonItem, IonLoading, IonContent } from '@ionic/react';
import SelectAction from 'store/actions/select';
import { Redirect } from 'react-router';
import Helper from 'Helper';
import i18next from 'i18next';
import { noImageFound } from 'components/Icons';
import Http from 'libs/http';
import { IonIcon } from '@ionic/react';
import { rollingLoading } from 'components/Icons';

const Billing = ({ t, order, ...props }) => {

  const [orderDetails, setOrderDetails] = useState({});
  const [orderBillingHtml, setOrderBillingHtml] = useState({});
  const [loading, setLoading] = useState(false);
  const http = new Http;

  useEffect(() => {
    loadOrderBilling();
  }, []);

  const loadOrderBilling = () => {
    console.log('order', order);

    // rendered-content/products
    // rendered-content/billing

    setLoading(true);
    http.post('rendered-content/order/billing', {
      order_id : order.id
    })
      .then(res => {
        setLoading(false);
        console.log('res', res);
        setOrderBillingHtml(res.html);
        console.log('res', res);
      })
      .catch(err => {
        console.error('res', err);
        setLoading(false);
      })
  }

  if (!order) {
    return <Redirect to={Helper.getRoute('orders')} />;
  }


  return (
    <Page backButton title='الفاتورة' className="billing-page">
      <IonContent class="container not-pulling">

          <IonLoading mode="ios" isOpen={loading} />

          {loading == false && (  
          <div >
            <div dangerouslySetInnerHTML={{__html: orderBillingHtml}}></div>
          </div>
          )}
      </IonContent>
    </Page>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Billing));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ select }) {
  return {
    order: select.order,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setOrder: (auth) => dispatch(SelectAction.setOrder(auth)),
  };
}
