import { db, firebase } from './firebase/firebase';
import Helper from 'Helper';
import Http from 'libs/http';

export default class AddressService {
    constructor() {
      this._favs = [];
      this.apiUrl = process.env.REACT_APP_API_URL;
      this.headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
      };

      this.http = new Http();
    }



    async getUserAddresses() {
      
      return this.http.post('customer/address/index')
    }


    /**
     * Fetch user location.
     */
    async getUserLocation(userId) {
        // const query = this.getLocations(userId);
        // const locations = await query.get();
        // for (const doc of locations.docs) {
        //   const l = doc.data();
        //   if (l.is_default) {
        //     return { id: doc.id, ...l };
        //   }
        // }
        return null;
    }

    /**
     * Set user location.
     */
    async setUserLocation(userId, locationId, data) {
        try {
            if (locationId == null) {
                return this.getLocations(userId).add(data);
            } else {
                const query = this.getLocations(userId).doc(locationId);
                return await query.update(data);
            }
        } catch (e) {}
    }

    /**
     * Fetch stores by user location.
     * @param {Object} latLon Google map latitube & longitube
     */
    async getStoresByLocation(latLon) {
        const result = [];
        const query = db.collection('stores');
        const stores = await query.get();

        /**
         * Get delivery time.
         * @param {Object} d
         */
        const deliveryTime = (d) => {
            if (!d) {
                return null;
            }
            const date = new Date();
            const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
            const day = days[date.getDay()];
            return d[day] || null;
        };

        for (const row of stores.docs) {
            const data = row.data();

            if (data.geolocation) {
                const distance = Helper.getDistance(latLon, data.geolocation);
                if (distance < process.env.REACT_APP_MAX_DISTANCE || data['is_global'] === true) {
                    result.push({
                        id: row.id,
                        ...data,
                        distance,
                        delivery_times: deliveryTime(data.delivery_times),
                    });
                }
            }
        }

        return result;
    }
}
