import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Searchbar.scss';
import { IonItem, IonLabel, IonText, IonSearchbar, IonToolbar } from '@ionic/react';
import { Input, Button, Select } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import Http from 'libs/http';
import Helper from 'Helper';
import classNames from 'classnames';

class Searchbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery : '',
            loading: false,
            results : []
        };

        this.http = new Http;
    }

    componentDidMount() {
      // let tabsBarHeight = $('ion-tabs').css('height').replace('px', '');
      // let tabsBarHeight = document.getElementsByTagName("ion-tab-bar")[0].style['height'].replace('px', '');
      // console.log('tabsBarHeight', tabsBarHeight);
      // footerEl.style['bottom'] = (Number(tabsBarHeight) + 1) + 'px';

      setTimeout(() => {
        const bodyHeight = document.body.clientHeight;
        const toolbarHeight = document.querySelector('.toolbar-searchbar')?.clientHeight || 0;

        const headerHeight = document.querySelector('.header')?.clientHeight || 0;
        const tabsbarHeight = document.querySelector('.main-tabs-bar')?.clientHeight || 0;

        document.querySelector('.search-term-results').style['height'] = (bodyHeight - headerHeight - toolbarHeight - tabsbarHeight) + 'px';
      }, 400);
    }

    handleSearching() {
      const { searchQuery } = this.state;

      this.http.post('search/index', {term : searchQuery})
        .then(results => {
          
          this.setState({results})
        })
        .catch(err => {
          console.error(err);
        })
    }

    handleSelectTerm(term) {
      const { history } = this.props;

      console.log('gr', term);
      // if (term['target'] == 'Term') {
      //   history.push(Helper.getRoute('search-results'), {
      //     term
      //   });
      // }

      // else if (term['target'] == 'Group') {
      //   history.push(Helper.getRoute('products'), {
      //     group_id : term.id
      //   });  
      // }

      return history.push(`search-results/${term.term}`, {
        term
      });
      
    }


    /**
     * Render the component.
     */
    render() {
        const { loading, name, phone, alert, results, searchQuery } = this.state;
        const { t } = this.props;

        return (
          <div className=''>
            <IonToolbar>
              <IonSearchbar
                placeholder={t('Search')}
                onClick={() => this.setState({searchMode : true})}
                onIonChange={(e) => {
                  this.setState({ searchQuery : e.detail.value })
                  this.handleSearching()
                }}
              />
            </IonToolbar>
            <div className={classNames('search-term-results', {active : searchQuery.length != 0})} 
              style={{ overflow : 'auto'}}
            >
              <div className=''>
                {results.map(item => (
                  <div className="term-item" key={`${item.target}-${item.id}`} onClick={() => this.handleSelectTerm(item)}>
                    {item.term}
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Searchbar));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, cache, select }) {
    return {
      history: select.history,
      cache,
      auth,
    };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
    return {
        // setCache: (data) => dispatch(CacheAction.setCache(data)),
    };
}
