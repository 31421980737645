import { heartOutline, flameOutline } from 'ionicons/icons';
import { percentage, timeOutline } from 'components/Icons';

export const byStats = [
  {
    id: '8132747195318272',
    name: { en: 'Previous items', ar: 'Previous items' },
    icon: timeOutline,
    href: '/products',
  },
  {
    id: '7183997836722176',
    name: { en: 'Top Saving', ar: 'Top Saving' },
    icon: percentage,
    href: '/products',
  },
  {
    id: '8160801764409344',
    name: { en: 'Trending', ar: 'Trending' },
    icon: flameOutline,
    href: '/products',
  },
  {
    id: '840185222791168',
    name: { en: 'Favorite', ar: 'Favorite' },
    icon: heartOutline,
    href: '/products',
  },
];

export const byCetegory = [
  { name: 'Steak', img: '/assets/images/food/001-steak.svg', href: '/products' },
  { name: 'Meat', img: '/assets/images/food/002-meat.svg', href: '/products' },
  {
    name: 'Onion',
    img: '/assets/images/food/003-onion ring.svg',
    href: '/products',
  },
  { name: 'Pizza', img: '/assets/images/food/004-pizza.svg', href: '/products' },
  { name: 'Cake', img: '/assets/images/food/005-cake.svg', href: '/products' },
  { name: 'Rice', img: '/assets/images/food/006-rice.svg', href: '/products' },
  { name: 'Sushi', img: '/assets/images/food/007-sushi.svg', href: '/products' },
  { name: 'Tacos', img: '/assets/images/food/008-tacos.svg', href: '/products' },
  { name: 'Salad', img: '/assets/images/food/009-salad.svg', href: '/products' },
  {
    name: 'Tempura',
    img: '/assets/images/food/010-tempura.svg',
    href: '/products',
  },
  {
    name: 'Lunch',
    img: '/assets/images/food/011-lunch box.svg',
    href: '/products',
  },
  { name: 'Jam', img: '/assets/images/food/012-jam.svg', href: '/products' },
  {
    name: 'Noodles',
    img: '/assets/images/food/013-noodles.svg',
    href: '/products',
  },
  {
    name: 'Bread',
    img: '/assets/images/food/014-bread.svg',
    href: '/products',
  },
];
