import React from 'react';
import { connect } from 'react-redux';
import CacheAction from 'store/actions/cache';
import { withNamespaces } from 'react-i18next';

import AppService from '../services/app.service';

import $ from 'jquery';

export default class BlockHelper {

  constructor(collection_id) {
    this.collection_id = collection_id;
  
    this.blocks = [];
    this.html = '';
    this.scripts = '';

    this.cache = {};
    this.service = new AppService;

    this.parentRef = React.createRef();

    this.cache[this.collection_id] = {
      blocks : [],
      html : '',
      script : '',
      plugins : [],
    };
    
   

    
  }

  setParentEl(el) {
    // this.parentEl = el;
  }

  isLoaded() {
    return this.blocks.length;
  }

  setCache(id, data) {
    this.cache[this.collection_id] = Object.assign(this.cache[this.collection_id], data);
  }

  getCollectionData() {
    return {
      id : this.collection_id,
      blocks : this.blocks,
      html : '',
      script : '',
      plugins : []
    };
  }

  async init() {
    this.blocks = await this.service.loadCollections(this.collection_id);
    this.register();
  }

  async loadBlocks(id) {
    return this.service.loadCollections(id);
  }

  setBlocks(blocks = []) {
    this.blocks = blocks;
  }
  
  register() {
    if ( "allHtml" in $.fn == false) {
      $.fn.allHtml = function() {
        return $('<div />').append(this.eq(0).clone()).html();
      };
    }

    let screenWidth = document.body.clientWidth;
    this.blocks.forEach(block => {
      let elBlock = $(block.html);
      if ('plugins' in block && typeof block['plugins'] == 'object') {
        if (block['plugins'].includes('ajax')) {
          let url = $(block.html).find('[block-ajax-url]').attr('block-ajax-url');
          $.get(url, function( res ) {
            try {
              let result = JSON.parse(res);
              if (typeof result == 'object' || result.hasOwnProperty("block")) {
                
                $(`[block-id='${block.id}']`).find('[block-ajax-url]').html(result.block.html);
              }
            }
            catch(err) {
              console.log('err', err);
            }
          });
        }
        
      }

      this.html += elBlock.allHtml();

      this.scripts += block.script + ';';
    });
    
    // if (block['plugins']) {
      
    // }
  }

  didMounted(containerRef) {
    $(containerRef.current).find('.pholder').each((i, el) => {
      let type = $(el).attr('pholder-type');
      let width = $(el).attr('pholder-width');
      let height = Number($(el).attr('pholder-height'));

      let targetWidth = Number($(el).attr('pholder-target-width'));
      
      let targetHeight = 'auto';
      let parentWidth = $(el).parent().width();

      targetHeight = ((height * Number(parentWidth)) / width);
      
      
      $(el).css({
        height : targetHeight
      });
      
      // console.log('block', block.id, targetHeight);
    });



    // if (block['plugins'].includes('pholder')) { 

    // }
  }

  getHtml() {
    return this.html;
  }

  // render() {
  //   let html = '';
    
  //   this.blocks.forEach(block => {
  //     html += block.html;
  //   });

  //   return html;
  // }

  runPlugins() {
    this.blocks.forEach(block => {
      
      if ('plugins' in block && typeof block['plugins'] == 'object') {
        if (block['plugins'].includes('ajax')) {
          let url = $(block.html).find('[block-ajax-url]').attr('block-ajax-url');
          $.get(url, function( res ) {
            try {
              let result = JSON.parse(res);
              if (typeof result == 'object' || result.hasOwnProperty("block")) {
                
                $(`[block-id='${block.id}']`).find('[block-ajax-url]').html(result.block.html);
              }
            }
            catch(err) {
              console.log('err', err);
            }
          });
        }
      }

      // this.scripts += block.script + ';alert("marw");';
    });
  }

  runScript() {
    /*eslint-disable */

    if (collectionScripts[this.collection_id] == true) {
    } else {
      // eval(this.scripts + `;collectionScripts['${this.collection_id}'] = true;`);
    }
    eval(this.scripts + `;collectionScripts['${this.collection_id}'] = true;`);
    
    // this.runPlugins(); 

    /*eslint-enable */
  }

  render() {
    console.log('run render', this.html);
    return (
      <>
        <div className="blocks-container">
          <div 
            ref={(ref) => (this.parentRef = ref)}
            dangerouslySetInnerHTML={{__html: this.html}}></div>
        </div>
      </>
    );
  }

}

// export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(BlockHelper));

// /**
//  * Map the store's state to the component's props
//  * @param  {Object} state
//  * @return {Object}
//  */
// function mapStateToProps({ cache }) {
//   return {
//     cache
//   };
// }

// /**
//  * Map the dispatch function of the store to the component's props
//  * @param  {Function} dispatch The dispatch function
//  * @return {Object}
//  */
// export function mapDispatchToProps(dispatch) {
//   return {
//     setCache: (data) => dispatch(CacheAction.setCache(data))
//   };
// }
