import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { InAppBrowser } from '@ionic-native/in-app-browser';
import { Mixpanel } from '@ionic-native/mixpanel';

import './TapPayment.scss';

import { withNamespaces } from 'react-i18next';
import { Page } from 'components';
import { Button } from 'components/Form';
import { checkmarkSharp, cardOutline } from 'ionicons/icons';

import { IonIcon, IonLoading } from '@ionic/react';
import { shopFlat } from 'components/Icons';
import { Link } from 'react-router-dom';
import AppService from 'services/app.service';
import AddressService from 'services/address.service';
import Helper from 'Helper';
import AuthAction from 'store/actions/auth';
import CartAction from 'store/actions/cart';

class TapPayment extends Component {
  constructor(props) {
    super(props);
    
    const { url } = this.props.history.location.state;

    this.state = {
      url : url,
      loading: false,
      stores: [],
      addresses : []
    };

    this.service = new AppService();
    this.addressService = new AddressService();

    this.openUrl = this.openUrl.bind(this);
    this.handleGoBackHome = this.handleGoBackHome.bind(this);
  }

  async componentDidMount() {
    // this.setState({ loading: true });
    const { auth } = this.props;
    const user = auth.user;

  }

  openUrl() {
    const { url } = this.state;
    Mixpanel.track('Open Tap Link', {
      screen : 'Tap Payment'
    });

    const browser = InAppBrowser.create(url, '_blank', 'location=yes');
    browser.on('message').subscribe(event => {
      const action = event['data']['action'];
      if (action == 'done') {

      } else if (action == '') {

      } else {

      }
      
    });
    browser.on('loadstop').subscribe(event => {

      browser.executeScript({
        code : `
          setTimeout(function() {
            alert('ok');
            postCordovaMessage();
          }, 5000);

          if(!webkit.messageHandlers.cordova_iab){
            console.warn('Cordova IAB postMessage API not found!');
            throw 'Cordova IAB postMessage API not found!';
          }else{
            console.log('Message sent!');
            webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));
          }

          function postCordovaMessage(){
            // Send an action = 'close' JSON object to Cordova via postMessage API
            var message = { action: 'close' };
            if(!webkit.messageHandlers.cordova_iab){
              console.warn('Cordova IAB postMessage API not found!');
              throw 'Cordova IAB postMessage API not found!';
            }else{
              console.log('Message sent!');
              webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));
            }
          }
        `
      })
    });
    
  //   browser.on('beforeload').subscribe(event => {
      // browser.executeScript({code : `
      //   alert('ok');
      //   $("body").append('<div>please wait</div>');
      // `});
  //     browser.insertCSS({ code: `
  //       #gosell-payment-gateway-page{
  //         display:none !important;
  //       }
  //       #root{
  //         display:none !important;
  //       }
  //     `})

  //  });

  //   browser.on('loadstop').subscribe(event => {

  //     browser.executeScript({
  //       code : `
  //         function injectScriptAndUse() {
  //           var head = document.getElementsByTagName("head")[0];
  //           var script = document.createElement("script");
  //           script.src = "//ajax.googleapis.com/ajax/libs/jquery/1.11.1/jquery.min.js";
  //           script.onload = function() {
  //             jQuery('body').append('<div class="enab-back-btn">الرجوع</div>');
  //             jQuery('.enab-back-btn').click(function(){
  //               this.close();
  //             });
  //           };
  //           head.appendChild(script);
  //         };
          
  //         injectScriptAndUse();
  //       `
  //     })

  //     browser.insertCSS({ code: `
  //       #gosell-payment-gateway-page{
  //         background : linear-gradient(90deg, rgba(237,138,171,1) 0%, rgba(245,193,158,1) 100%) !important;
  //       }
  //       .tap-payments-page, .tap-payments-page-bg-img, .tap-payments-page-small-img{
  //         display:none !important;
  //       }

  //       #gosell-payment-gateway-page{
  //         display:block !important;
  //       }

  //       #root{
  //         display:block !important;
  //       }

  //       .enab-back-btn{
  //         padding: 20px;
  //         background: #333;
  //         color: #fff;
  //         z-index: 2000;
  //         position: fixed;
  //         left: 0;
  //         right: 0;
  //         bottom: 0;
  //         text-align: center;
  //       }
  //     `})

  //     browser.insertCSS({ code: `
  //       #gosell-payment-gateway-page{
  //         background : linear-gradient(90deg, rgba(237,138,171,1) 0%, rgba(245,193,158,1) 100%) !important;
  //       }
  //       .tap-payments-page, .tap-payments-page-bg-img, .tap-payments-page-small-img{
  //         display:none !important;
  //       }
  //       .tap-payments-modal-header{
  //         display:none !important;
  //       }
  //       .gosell-gateway-footer-container{
  //         display:none !important;
  //       }
  //     `})
      // alert('browser stop loading');
  //  });


  }

  /**
   * Handles select.
   * @param {Object} store
   */
  handleSelect(address) {
    const { setStore, initCart, cart, auth, history } = this.props;
    
    history.push(Helper.getRoute('checkout'), {
      address
    });
  }

  handleCall() {
    Mixpanel.track('Click Call', {
      screen : 'Tap Payment'
    });
  }

  handleGoBackHome() {
    const { history } = this.props;
    Mixpanel.track('Back Home', {
      screen : 'Tap Payment'
    });
    history.push(Helper.getRoute('home'));
  }

  render() {
    const { addresses, stores, loading } = this.state;
    const { t } = this.props;

    /**
     * Item content.
     */
    const Content = ({
      address,
      country,
      city,
      phone,
      name,
      icon,
      min_order,
      delivery_times,
      distance,
      isLocation,
      children,
    }) => (
      <>
        {isLocation && (
          <div>
            <strong>{address}</strong>
            <div className="attrs">
              <p>
                {phone}
              </p>
              <p>
                { country }, الرياض
              </p>
            </div>
          </div>
        )}
        {children}
      </>
    );

    return (
      <div className="tap-pay-page hide-if-ee">
          <div>
            <div className="logo" style={{textAlign : 'center', marginBottom: '20px'}}>
              <img src="/assets/images/logo-icon.png" alt="logo"
                style={{ width : '100px', height : '100px' }}
              />
            </div>

            <IonIcon color="secondary" icon={cardOutline} />

            <h3>{t('ادفع بأمان')}
            
            </h3>
            <h3>{t('لكي يتم اكمال طلبكم بنجاح.')}</h3>

            <Button className="btn-pay" full large onClick={this.openUrl}>
              {t('رابط الدفع')}
            </Button>

            <Button full large onClick={this.handleGoBackHome} className="btn-back-home">
              {t('الرجوع للصفحة الرئيسية')}
            </Button>

            <a href="tel:92223523" onClick={this.handleCall}>
              <Button full large className="btn-support">
                للدعم تواصل معانا : 
                <p dir="ltr">
                  9200 23523
                </p>
              </Button>
            </a>
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(TapPayment));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, cart, select }) {
  return {
    auth,
    cart,
    history: select.history,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setStore: (data) => dispatch(AuthAction.setStore(data)),
    initCart: (auth) => dispatch(CartAction.emptyCart(auth)),
  };
}
