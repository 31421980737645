import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Wishlist.scss';
import { Page } from 'components';
import { 
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonIcon,
  IonCol
} from '@ionic/react';
import ReactImageAppear from 'react-image-appear';
import ProductItem from 'components/Customs/ProductItem/ProductItem';
import { withNamespaces } from 'react-i18next';
import Helper from 'Helper';
import { store } from 'store';
import { trashOutline } from 'ionicons/icons';
import { noImageFound } from 'components/Icons';

import Http from 'libs/http';
import { cdnImage } from 'libs/pipes';

class Wishlist extends Component {
    constructor(props) {
      super(props);

      this.http = new Http;

      this.state = { 
        items : [],
        loading : true,
        showAlert: null,
        loginModalOpen : false
      };
      // this.state.cart = store.getState().cart;
      this.state.store = store.getState();
      this.handleNext = this.handleNext.bind(this);
      this.handleCloseLoginModal = this.handleCloseLoginModal.bind(this);
    }

    /**
     * Delivery fee.
     */
    get fee() {
        const { delivery_fees } = this.state;
        return 20;
    }

    /**
     * Total amount.
     */
    get total() {
      const { cart } = this.props;

      const amount = cart.amount + this.fee;

      return Helper.amountFormat({amount});
    }

  /**
   * On init
   */
  async componentDidMount() {
    this.loadWishlistItems();
  }

  handleCloseLoginModal() {
    this.setState({loginModalOpen : false});
  }

  loadWishlistItems() {
    this.setState({
      loading : true
    })
    this.http.post('wishlist', {})
      .then(res => {
        if(!res.error){
          this.setState({
            loading : false,
            items : res.wishlist
          });
        }else {
          this.setState({
            loading: false,
            showAlert: "Error while loading wishlist"
          })
        }

      })
      .catch(err => {
        this.setState({
          loading: false,
          items : []
        })
      })
  }

  /**
   * Handles next button.
   */
  handleNext() {
    const { t, cart, history, auth } = this.props;
    const { store, loginModalOpen } = this.state;

    const { user } = auth;

    if (user['isGuest'] == true) {
      // history.push(Helper.getRoute('login'));
      this.setState({ loginModalOpen : !loginModalOpen })
      return;
    }

    
    const hasCustomProduct = cart.items.find((item) => item.type !== 'MAIN');

    if (!hasCustomProduct && cart.amount < store.min_order) {
      return this.setState({
        showAlert: t('The minimum amount for the order is') + ': ' + Helper.amountFormat({amount: store.min_order, html: false}),
      });
    }

    history.push(Helper.getRoute('checkout/select-address'));
  }

  handleGoToProduct(product) {
    window['blockLink']('product', product['id'], JSON.stringify(product));
  }

  handleRemoveProduct(p) {
    const { items } = this.state;
    let wishlistId = 0
    let wishlists = items.filter(item => {
      if (item.product.id != p.id) {
        return item;
      } 
      else {
        wishlistId = item.id
      }
    });

    this.setState({
      items : wishlists
    });

    this.http.post('wishlist/delete', {
      id: wishlistId
    })
    .then(res => {
      
    })
    .catch(err => {
      console.log('error', err);
    });
  }

  /**
   * Render the component.
   */
  render() {
      const { showAlert, loginModalOpen, loading, items } = this.state;
      const { t, cart } = this.props;

      /**
       * Loading animation
       */
      const Loading = () => (
        <div >
          <IonList className="product-list loading">
            <IonGrid className="gs-products-grid">
              <IonRow>
                {[0, 1, 2, 3, 4, 5].map((e) => (
                  <IonCol key={String(e)} size="12">
                    <ProductItem loading style={{minHeight : '120px', borderRadius : '5px'}} />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          </IonList>
        </div>
      );

      const ProductCard = (p) => {
        const { product } = p;
        const { name, base_price, image } = product;

        return (
          <div className='cart-item gs-block first inline'>
            <div className="item">
              <IonItem lines="none">
                <div className="image image-loader" slot="start" onClick={ () => { this.handleGoToProduct(product) } }>
                  <ReactImageAppear src={cdnImage(image.file_name) || noImageFound} />
                </div>
                <div className="info">
                  <h2 onClick={ () => { this.handleGoToProduct(product) } }>{name}</h2>

                  <p onClick={ () => { this.handleGoToProduct(product) } }>
                    العلامة التجارية
                  </p>

                  <span style={{color : '#333', fontSize : '14px'}} onClick={ () => { this.handleGoToProduct(product) } }>
                    { base_price } 
                  </span>

                  <div style={{'float' : 'left', 'padding' : '5px'}}>
                    <span style={{fontSize:'16px'}} onClick={ () => { this.handleRemoveProduct(product) } }>
                      <IonIcon icon={trashOutline}></IonIcon>
                    </span>
                  </div>
                </div>
              </IonItem>
            </div>
          </div>
        );
      }
      
      return (
          <>
            <Page 
              className="wishlist-page has-no-tabs"
              title={t('قائمة المفضلة')}
              backButton
              hasNeedHelp>
                <div className="wrapper">
                  {loading ? (
                    <Loading />
                  ) : (
                    <div>
                      {items.length <= 0 ? (

                        <div style={{paddingTop : '130px', textAlign : 'center'}}>
                          <img src="assets/icon/wishlist.png" style={{width:'130px'}} />
                          <div className="empty-cart" style={{paddingTop : '5px'}}>{t('لم يتم إضافة منتجات في المفضلة')}</div>
                        </div>
                        
                    ) : (
                        <>
                          <div className="label d-none" style={{display : 'none'}}>
                            {t('التفاصيل')}{' '}
                            <span>
                              {cart.items.length} {t('Products')}
                            </span>
                          </div>
                          <div className="products">
                            {items.map((p) => (
                              <ProductCard key={p.id} {...p} onClick={this.handleProductClick} />
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                    
                </div>
            </Page>
          </>
      );
  }
}

export default connect(mapStateToProps, null)(withNamespaces()(Wishlist));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ cart, auth, select }) {
    const userCart = () => {
        try {
            return cart;
        } catch (e) {
            return {
                items: [],
                amount: 0,
                totla: 0,
            };
        }
    };
    return {
      auth : auth,
      cart: userCart(),
      store: auth.store,
      history: select.history,
    };
}
