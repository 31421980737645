import Http from 'libs/http';
import { db, firebase } from './firebase/firebase';

export default class ProductService {
    constructor() {
      this._favs = [];
      this.apiUrl = process.env.REACT_APP_API_URL;
      this.headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
      };

      this.http = new Http();
    }

    async getProductWithView(product_id) {
      return this.http.post('products/product-details', {
        id : product_id
      })
      .then(data => {
        const { product, shop } = data;
        return {
          product,
          shop
        };
      });
    }

    async productSearch(data) {
      return this.http.post('rendered-content/search', data)
        .then(res => {
          return res;
        });
    }

    /**
     * Fetch products.
     * @param {Object} params
     */
    async getProducts({ storeId, category, search, subCategory, orderByPrice }) {
      return new Promise((resolve, reject) => {
        try {
          // return this.http.get(`products/category/${category}`, {})
          // for testing 
          return this.http.get(`products`, {})
            .then(res => {
              return resolve(res.data);
            })
        } catch (e) {
          // reject(e);
          return [];
        }
      })
    }

    async getFavoriteProducts(auth) {
      return new Promise(async (resolve, reject) => {
        try {
          let favs = await db
            .doc('users/' + auth.user.id)
            .get()
            .then((doc) => {
              if ('favorite' in doc.data()) {
                return doc.data()['favorite'];
              }
              return resolve([]);
            });
  
          let promises = [];
          favs.forEach((p_id) => {
            promises.push(db.doc('stores/' + auth.store.id + '/products/' + p_id).get());
          });
  
          let result = [];
          return await Promise.all(promises).then((products) => {
            for (let i = 0; i < products.length; i++) {
              let obj = products[i];
              let product = obj.data();
              if (typeof product == 'object' && 'category' in product) {
                product['id'] = obj['id'];
                result[i] = product;
              }
            }
            return resolve(result);
          });
        } catch (err) {
          reject(err);
        }
      });
    }

}
