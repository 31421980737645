import { SET_USER, SET_STORE, RESET } from '../actions/auth';

const initialState = {
  user: {
    isGuest : true
  },
  isGuest : true,
  store: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.data,
      };

    case SET_STORE:
      return {
        ...state,
        store: action.data,
      };

    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default auth;
