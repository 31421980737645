import { db, firebase } from './firebase/firebase';
import moment from 'moment';
import Helper from 'Helper';
import i18next from 'i18next';
import hash from 'custom-hash';
import axios from 'axios';
import Http from 'libs/http';

hash.configure({ charSet: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'], maxLength: 5 });

export default class OrderService {
	constructor() {
		this._favs = [];
		this.apiUrl = process.env.REACT_APP_API_URL;
		this.headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		};

		this.http = new Http();
	}

	/**
	 * Create an order.
	 * @param {Object} params
	 */
	async createOrder(params) {
		return new Promise(async (resolve, reject) => {
			this.http.post('customer/order/place', params)
				.then(res => {
					const { order, payment } = res
					return resolve({
						order,
						payment
					});
				})
				.catch(err => {
					alert('A problem occurred while creating the order')
					reject(err);
				})
		});
	}

	/**
	 * Fetch orders by user.
	 * @param {String} userId
	 * @return {Array}
	 */
	async getOrdersByUser() {
		return this.http.post('orders')
			.then(data => {
				return data?.orders || [];
			});
	}

	/**
	 * Fetch order details
	 * @param {String} userId
	 * @return {Array}
	 */
	async getOrderDetails(order_id) {
		return this.http.post('user/order-details', {
			order_id
		})
	}

	/**
	 * Fetch products.
	 * @param {Object} params
	 */
	async getProducts({ storeId, category, search, subCategory, orderByPrice }) {
		return new Promise((resolve, reject) => {
			try {
				// return this.http.get(`products/category/${category}`, {})
				// for testing 
				return this.http.get(`products`, {})
					.then(res => {
						return resolve(res.data);
					})
			} catch (e) {
				// reject(e);
				return [];
			}
		})
	}
}
