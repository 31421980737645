import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './ProductItem.scss';
import { 
  IonItem,
  IonLabel,
  IonText, 
  IonGrid,
  IonRow,
  IonCol,
  IonBadge
} from '@ionic/react';
import ReactImageAppear from 'react-image-appear';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/Form';
import { cartOutline } from 'ionicons/icons';
import { withNamespaces } from 'react-i18next';
import Helper from 'Helper';
import i18next from 'i18next';
import { cartMapStateToProps, cartMapDispatchToProps } from 'store/maps/cart';
import SelectAction from 'store/actions/select';
import { noImageFound } from 'components/Icons';

import { cdnImage, amountFormat } from 'libs/pipes';

import ProductsHelper from 'containers/account/Products/ProductsHelper';
// import ProductsHelper from '../Products/ProductsHelper';

/**
 * Product Item.
 */
const ProductItem = ({ children, product, loading, onClick, auth, inCart, t, ...props }) => {
  const [cart, setCart] = useState(inCart);
  const [prices, setPrices] = useState({
    sale_price : product?.price,
    discount : 0,
    discountValue : 0,
  });
  const history = useHistory();



  /**
   * Listen to cart quantity changes.
   */
  useEffect(() => {
    if (!loading) {
      const { price, discount, discount_type } = product
      const { discountedPrice, percentOff } = ProductsHelper.getDiscountedPrice({ price, discount, discount_type });

      setPrices({
        sale_price : discountedPrice,
        discount : percentOff
      })
    }
    

    if (cart !== inCart) {
      if (!inCart) {
        // Add to cart
        props.addToCart({
          ...product,
          quantity : 1,
          variants : [],
        }, auth);
      } else {
        if (cart > inCart) {
          // Add quantity
          props.addQuantity(product, auth);
        } else if (cart > 0) {
          // Sub. quantity
          props.subQuantity(product, auth);
        } else {
          // Remove from cart
          props.removeFromCart(product, auth);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  if (loading) {
    return <IonItem lines="none" className="product-item loading" {...props} />;
  }


  /**
   * Handle redirect to product page.
   * @param {Element} e
   */
  const goToProduct = (e) => {
    e.preventDefault();
    props.setProduct(product);
    history.push(Helper.getRoute('product'));
  };

  // const navToLogin = () => {
  //   history.push(Helper.getRoute('login'));
  // }

  let isGuest = false;
  if (auth != null && auth['user'] != null) {
    if (auth.user['isGuest'] == true) {
      isGuest = true;
    }
  }

  const { name, price, image, hasOptions } = product;

  return (
    product && (
      <div className="product-item">
        <div onClick={onClick}>
        
          <div className='product-item-image'>
            <div style={{background : `url(${cdnImage(image)})`}}></div>
          </div>
          {/* <div className="image">
            
            <ReactImageAppear
              loaderClass="loader"
              animation="none"
              showLoader
              src={cdnImage(image) || noImageFound}
            />
          </div> */}
          <IonLabel className="info">
            <h3 className="name">{name}</h3>
            
            <h3 className="price">{Helper.amountFormat({ amount: prices.sale_price })}</h3>      
            {prices.discount > 0 && (
              <IonText color="secondary">
                <IonBadge className='badge' style={{marginRight: 5, backgroundColor: '#FFE2E2', color: '#FF2F2F'}}>{t('Discount')}  {prices.discount}%</IonBadge>
                <strike>
                  {Helper.amountFormat({ amount: prices.discount })}
                </strike>
              </IonText>
            )}
                
                
            
            {/* <h3 className="name">{name[i18next.language]}</h3> */}
            
            
            {children}
          </IonLabel>
        </div>
        <div className="actions">
          {cart <= 0 && (
            <div>
              <Button
                icon={cartOutline}
                onClick={onClick}
                variant="outline"
                small
                raduis={false}
                full
                track={['Add to cart', {}]}
              >
                {t('Add')}
              </Button>
            </div>
          )}
          {cart > 0 && (
            <div className="add-remove">
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <Button className={'btn-add-remove'} onClick={() => setCart(cart + 1)} track="Cart Change - plus">
                      +
                    </Button>
                  </IonCol>
                  <IonCol size="4"><span className="quantity">{cart}</span></IonCol>
                  <IonCol size="4">
                  <Button className={'btn-add-remove'} onClick={() => cart && setCart(cart - 1)} track="Cart Change - minus">
                    -
                  </Button>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )}
        </div>
      </div>
    )
  );
};

ProductItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  product: PropTypes.shape({
    // name: PropTypes.shape({
    //   en: PropTypes.string,
    //   ar: PropTypes.string,
    // }),
    name : PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.string,
    img: PropTypes.string,
    image : PropTypes.string,
    quantity: PropTypes.number,
  }),
};

ProductItem.defaultProps = {
  product: null,
  loading: false,
};

export default connect(cartMapStateToProps, mapDispatchToProps)(withNamespaces()(ProductItem));

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setProduct: (data) => dispatch(SelectAction.setProduct(data)),
    ...cartMapDispatchToProps(dispatch),
  };
}
