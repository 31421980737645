import React, { Component } from 'react';
import { connect } from 'react-redux';

import './TextOrder.scss';
import { Page } from 'components';
import { Button, Input } from 'components/Form';
import Helper from 'Helper';
import { IonIcon, IonFooter, IonToolbar, IonContent } from '@ionic/react';
import { checkmarkSharp } from 'ionicons/icons';
import { withNamespaces } from 'react-i18next';
import CartAction from 'store/actions/cart';

class TextOrder extends Component {
  constructor(props) {
    super(props);

    
    this.state = {
      textOrder : '',
      addCustomProduct: false,
      customProducts: [],
    };

    this.handleAddCustomProducts = this.handleAddCustomProducts.bind(this);
  }

  /**
   * Handle Add Custom Products.
   */
  handleAddCustomProducts() {
    const { textOrder } = this.state;
    const { addToCart, auth, history } = this.props;

    let customProducts = textOrder.replace(/\r\n/g,"\n").split("\n");;

    for (const product of customProducts) {
      addToCart(
        {
          id: Helper.uid(16),
          name: { en: product, ar: product },
          price: 0,
          img: null,
          type: 'CUSTOM',
        },
        auth
      );
    }

    // this.setState({ addCustomProduct: false });
    history.push(Helper.getRoute('checkout'));
  }

  /**
   * Render the component.
   */
  render() {
    const { textOrder } = this.state;
    const { t } = this.props;
    return (
      <>
        <Page className="text-order-page" backButton title="طلب كتابي">
          <IonContent>
            <div className="group" style={{ padding : '0' }}>
              <div className="form-control">
                <Input type="textarea" 
                  className="text-order-control"
                  style={{ 'min-height': '400px'}}
                  placeholder="حليب نادك" 
                  value={textOrder}
                  onChange={(v) => this.setState({ textOrder: v })}
                ></Input>
                <p style={{ 'font-size' : '14px', color : '#666' }}>
                  تكفا لا تكتب ملاحضاتك هنا.
                </p>
              </div>
              {/* <div className="form-control">
                
              </div> */}
            </div>

          </IonContent>
          <IonFooter className="ion-no-border">
            <IonToolbar>
              <Button onClick={this.handleAddCustomProducts} large full>
                ارسال الطلب
              </Button>
            </IonToolbar>
          </IonFooter>
        </Page>
      </>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(TextOrder));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ select, cart, auth }) {
  return {
    cart,
    auth,
    history: select.history,
  };
}


/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    addToCart: (data, auth) => dispatch(CartAction.addToCart(data, auth)),
  };
}