import React from 'react';
import { connect } from 'react-redux';
import CacheAction from 'store/actions/cache';
import { withNamespaces } from 'react-i18next';

import { InAppBrowser } from '@ionic-native/in-app-browser';

import $ from 'jquery';

export default class TapHelper {

  constructor({url}) {
    this.url = url;
    this.event = Function;
    this.browser = null;
  }

  open(callback) {

    const browser = InAppBrowser.create(this.url, '_blank', 'location=yes');
    this.browser = browser;
    this.event = browser.on('message').subscribe;

    // browser.on('message').subscribe(event => {
    //   // const action = event['data'];
    //   console.log('we recieved some data', event);
    //   // this.event(function(data) {
    //   //   return data; 
    //   // })
    // });


    browser.on('beforeload').subscribe(event => {

      browser.executeScript({
        code : `

          function postCordovaMessage(message){
            // Send an action = 'close' JSON object to Cordova via postMessage API
            // var message = { action: 'close' };
            if(!webkit.messageHandlers.cordova_iab){
              console.warn('Cordova IAB postMessage API not found!');
              throw 'Cordova IAB postMessage API not found!';
            }else{
              console.log('Message sent!');
              webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));
            }
          }

          // setTimeout(function() {
          //   console.log('start sending message');
          //   // alert('ok');
          //   // postCordovaMessage();
          // }, 5000);

        `
      })
    });

    // callback()

    return browser;
  }

  render() {
    console.log('run render', this.html);
    return (
      <>
        <div className="blocks-container">
          <div 
            ref={(ref) => (this.parentRef = ref)}
            dangerouslySetInnerHTML={{__html: this.html}}></div>
        </div>
      </>
    );
  }

}
