import { SET_HISTORY, SET_CATEGORY, SET_PRODUCT, SET_ORDER, SET_LOGIN, SET_SHOP, RESET } from '../actions/select';

const initialState = {
  history: null,
  shop : null,
  category: null,
  product: null,
  order: null,
  login: null
};

const selected = (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY:
      return {
        ...state,
        history: action.data,
      };

    case SET_LOGIN:
        return {
          ...state,
          login: action.data,
        };

    case SET_SHOP:
      return {
        ...state,
        shop: action.data,
      };
  
    case SET_CATEGORY:
      return {
        ...state,
        category: action.data,
      };

    case SET_PRODUCT:
      return {
        ...state,
        product: action.data,
      };

    case SET_ORDER:
      return {
        ...state,
        order: action.data,
      };

    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default selected;
