import React, { Component } from 'react';
import { connect } from 'react-redux';

import './ThanksPage.scss';
import { Button } from 'components/Form';
import Helper from 'Helper';
import { IonIcon } from '@ionic/react';
import { checkmarkSharp } from 'ionicons/icons';
import { withNamespaces } from 'react-i18next';

class ThanksPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    console.log('thanks-page', props);
  }

  handleNavToHome() {

  }

  handleNavToWhatsapp() { 
    const { cache } = this.props;
    const { shop } = cache;
    if (shop.delivery_whatsapp_number == null)  {
      return;
    }

    const phoneNumberWhatsappFormat = shop.delivery_whatsapp_number;
    const WhatsAppMessage = encodeURIComponent("Bonjour, je suis le livreur. Veuillez m'envoyer votre localisation.");
    const whatsAppUrl = `https://api.whatsapp.com/send/?phone=${phoneNumberWhatsappFormat}&text=${WhatsAppMessage}&type=phone_number&app_absent=0`;

    window.open(whatsAppUrl, '_blank');
  }



  /**
   * Render the component.
   */
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="thank-you-page hide-if-ee">
          <div>
            <IonIcon color="secondary" icon={checkmarkSharp} />
            <h3>{t('Thank you!')}</h3>
            <p>{t('suivez votre commande sur WhatsApp')}</p>

            <Button full large onClick={() => {
              this.handleNavToWhatsapp();
            }}>
              {t('Envoyer sur WhatsApp')}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

// export default withNamespaces()(ThanksPage);


export default connect(mapStateToProps)(withNamespaces()(ThanksPage));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, select, cache }) {
    return {
      cache,
      history: select.history,
      auth,
    };
}
