import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './TimePicker.scss';
import { IonChip, IonLabel } from '@ionic/react';
import { Button } from 'components/Form';
import { Modal } from 'components';

/**
 * A sample TimePicker
 */
export const TimePicker = ({
  title,
  columns,
  buttonText,
  onDismiss,
  className,
  onConfirm,
  isOpen,
  values,
}) => {
  // Generate html for value
  const toStrong = (text) => {
    let parts = text.split(/\*\*(.*?)\*\*/gi);
    for (var i = 1; i < parts.length; i += 2) {
      parts[i] = <b key={i}>{parts[i]}</b>;
    }
    return parts;
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    const data = {};
    for (const column of columns) {
      data[column.name] = e.target.elements[column.name].value;
    }
    return onConfirm(data);
  };

  // Generate column row
  const Row = ({ column }) => {
    return column.options.map((o, x) => {
      const { name } = column;
      const random = Math.floor(Math.random() * 9999);
      const id = `${name}-${random}`;
      const checked = values && values[name] === o.value;
      return (
        <label key={x} htmlFor={id} className="item">
          <input defaultChecked={checked} type="radio" id={id} name={name} value={o.value} />
          <IonChip mode="ios" outline>
            <IonLabel>{toStrong(o.text)}</IonLabel>
          </IonChip>
        </label>
      );
    });
  };

  return (
    <div className={classNames('time-picker', className)}>
      <Modal isOpen={isOpen} title={title} onDismiss={onDismiss}>
        <form onSubmit={handleConfirm}>
          {columns.map((c, i) => (
            <div key={i} className="picker">
              <div className="title">{c.label}</div>
              <div className="options">
                <Row column={c} />
              </div>
            </div>
          ))}
          <div className="footer">
            <Button type="submit" large full>
              {buttonText}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

TimePicker.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          value: PropTypes.any,
        })
      ),
    })
  ),
  values: PropTypes.object,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onDismiss: PropTypes.func,
};

TimePicker.defaultProps = {
  isOpen: true,
  buttonText: 'Confirm',
  columns: [],
  onDismiss: () => null,
  onConfirm: (e) => console.log(e),
};

export default TimePicker;
