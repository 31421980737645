import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Radio.scss';
import {
  IonRadio,
  IonRadioGroup,
  IonLabel,
  IonItem,
  IonRow,
  IonCol,
} from '@ionic/react';

/**
 * Radio is used to create a checkable interactive control for web-based forms.
 */
const Radio = ({ className, name, onChange, options, color, value, full, cols }) => {
  const handleChange = (e) => (!onChange ? null : onChange(e.target.value));
  const col = (i) => {
    const size = parseInt(12 / i);
    return String(size);
  };

  return (
    <div
      className={classNames(
        'radio',
        className,
        color,
        full && 'full',
        !cols && !full && 'no-cols',
        !full && 'size-' + col(options.length)
      )}
    >
      <IonRow>
        <IonRadioGroup name={name} onIonChange={handleChange} value={value}>
          {options.map((o, key) => (
            <IonCol
              size={(cols || full) && (full ? '12' : col(options.length))}
              key={key}
            >
              <IonItem mode="ios" lines="none" className="radio-wrapper">
                <IonRadio
                  id={o.id}
                  mode="md"
                  value={o.value}
                  disabled={o.disabled}
                />
                <IonLabel className="radio-label">{o.label}</IonLabel>
                {o.render && o.render()}
              </IonItem>
            </IonCol>
          ))}
        </IonRadioGroup>
      </IonRow>
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  full: PropTypes.bool,
  value: PropTypes.string,
  cols: PropTypes.bool,
  color: PropTypes.oneOf(['secondary', 'tertiary']),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.nool,
    })
  ),
};

Radio.defaultProps = {
  className: '',
  cols: false,
  full: false,
  color: 'secondary',
  options: [
    { label: 'Test 1', value: '1' },
    { label: 'Test 2', value: '2' },
  ],
};

export default Radio;
