import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { cart, auth, select, cache, fav, collection } from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'cart', 'cache', 'fav', 'collection'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({ cart, auth, select, cache, fav, collection })
);

let composeEnhancers = compose;
if (process.env.NODE_ENV !== 'production') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: true,
    });
  }
}

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
const persistor = persistStore(store);

export { store, persistor };
