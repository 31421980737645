import axios from 'axios';
import { store } from 'store';

export default class Http {
  constructor() {
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    
    const { lang } = store.getState().cache;
    const { user } = store.getState().auth;
    const { isGuest, access_token } = user
    let headersOptions = {
      
    };

    if (user && !isGuest) {
      headersOptions['Authorization'] = `Bearer ${access_token}`;
    }

    headersOptions['X-Lang'] = lang || 'fr';

    // REACT_APP_SHOPNAME
    headersOptions['X-Shopname'] = process.env.REACT_APP_SHOPNAME;

    this.axios = axios.create({
      headers: Object.assign(headers, headersOptions)
    });
  }

  get(url, params, headers = null) {
    if (url.startsWith != 'http') {
      url = process.env.REACT_APP_API_URL + '/' + url;
    }
    
    return this.axios.get(url, params)
      .then((res) => {
        return res.data;
      })
  }

  post(url, params, config = {}) {
    url = process.env.REACT_APP_API + '/' + url;

    return new Promise((resolve, reject) => {
      return this.axios.post(url, params, config)
      .then((res) => {
        if ('error' in res && res['error'] == true) {
          return reject(res);
        }

        if ('data' in res.data) {
          return resolve(res.data.data);
        }

        return resolve(res.data);
      })
      .catch(err => {
        reject(err);
      })
    });
  }

  pgraph(query) {
    return this.post('graphql', query);
  }
}

