import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as smoothscroll from 'smoothscroll-polyfill';
import {
  isPlatform,
} from '@ionic/react';

import { Loading } from 'components';
import { withNamespaces } from 'react-i18next';

import AppService from 'services/app.service';
import Http from '../../libs/http';
import BlockHelper from '../../libs/block-helper';
import { createBrowserHistory } from 'history';
import CacheAction from 'store/actions/cache';
import CollectionAction from 'store/actions/collection';
require('./BlockBuilder.scss')

smoothscroll.polyfill();

class BlockBuilder extends Component {
  constructor(props) {
    super(props);

    this.http = Http;
    this.blockHelper = new BlockHelper(props.collection_id);
    
    this.state = {
      collection_id : props.collection_id,
      loaded : false,
      blocksHtml : '',
      categories: [],
      category: {},
      products: [],
      blocks : [],
      categoryType: 'MAIN',
      subCategory: 0,
      orderBy: '1',
      customImg: null,
      uploadedFile: null,

      productName: null,
      productDescription: null,

      refresher: false,
      refreshing: false,
      addingProduct: false,
      loading: true,
      uploading: false,
      sort: false,
    };

    this.container = null;
    this.containerRef = React.createRef();
    this.categoryRef = null;
    this.uploadImageRef = null;
    this.refresher = null;
    this.subCategoryRef = null;
    this.subCategoryItemsRef = null;
    this.timeoutRef = null;

    this.service = new AppService();

  }


  /**
   * On init
   */
  async componentDidMount() {
    const { collection, collection_id, setCollection } = this.props;
    const { collections } = collection;
    let blocks = [];
    
    if (this.state.collection_id in collections && 1 == null) {
      blocks = collections[this.state.collection_id]['blocks'];
      this.blockHelper.setBlocks(blocks);
      this.blockHelper.register();

      let html = this.blockHelper.getHtml();

      this.setState({
        html
      }, () => {
        this.blockHelper.didMounted(this.containerRef);
        this.blockHelper.runScript();
        // this.blockHelper.runPlugins();
      });
    }
    else {
      
      blocks = await this.blockHelper.loadBlocks(collection_id);
      this.blockHelper.setBlocks(blocks);
      this.blockHelper.register();
      let collectionData = this.blockHelper.getCollectionData();
      
      let html = this.blockHelper.getHtml();

      this.setState({
        html,
        loading : false
      }, () => {
        this.blockHelper.didMounted(this.containerRef);
        this.blockHelper.runScript();
        // this.blockHelper.runPlugins();
      });

      setCollection(collectionData);
    }
    

    // this.getBlocks();
  }

  /**
   * On unload
   */
  async componentWillUnmount() {
    
  }

  /**
   * On state update.
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(pp, ps) {
    
  }

  async getBlocks() {
    const { setCache } = this.props;

    

    this.setState({
      loading : true
    });
  }

  /**
   * Handles the selected category align.
   */
  handleSubCategoryChange(e, value) {
    if (e != null) {
      e.preventDefault();  
    }
    this.setState({ subCategory: value }); 
  }

  /**
   * Handles scroll event.
   * @param {Event} e
   */
  handleScrollEvent(e) {
    const { refresher } = this.state;
    const top = e.detail.scrollTop;

    // if (isPlatform('ios')) {
    if (this.subCategoryRef) {
      if (top > 0) {
        this.subCategoryRef.style.position = 'fixed';
      } else {
        this.subCategoryRef.style.position = 'absolute';
      }
    }

    if (top < 0 && Math.abs(top) >= 120 && !refresher) {
      this.handleRefresh();
      this.setState({ refresher: true });
    }
    // }
  }

  /**
   * Handles on refresh event.
   */
  handleRefresh() {
    if (!isPlatform('ios')) {
      // this.subCategoryRef.style.top = '0px';
    }

    this.setState({ refreshing: true });
  }


  /**
   * Render the component.
   */
  render() {
    const { t } = this.props;
    const { html, loading } = this.state;
    
    // if (!categories) {
    //   return <Redirect to={Helper.getRoute('store')} />;
    // }

    return (
      <>
        {loading == true ? (
          <div style={{ padding : '60px 0', 'textAlign' : 'center' }}>
            <Loading></Loading>
          </div>
        ) : (
          <div ref={this.containerRef} dangerouslySetInnerHTML={{__html: html}}></div>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(BlockBuilder));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ collection, cache, auth, select }) {
  const { location } = createBrowserHistory();
  return {
    collection,
    cache,
    auth,
    history: select.history,
    location,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setCollection: (data) => dispatch(CollectionAction.setCollection(data)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}
