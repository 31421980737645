import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Login.scss';
import { IonItem, IonLabel, IonText, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { Input, Button, Select } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import Layout from '../Layout/Layout';
import { withRouter } from 'react-router';
import AuthService from 'services/auth.service';
import AuthAction from 'store/actions/auth';
import Helper from 'Helper';

import * as _ from 'lodash';

import CacheAction from 'store/actions/cache';
import Http from 'libs/http';

class Signup extends Component {
    constructor(props) {
        super(props);

        const { name, phone } = _.get(this.props, 'cache.authPage', {});
        
        this.state = {
          name: name || '',
          phone: (phone || '').replace('+212', ''),
          code :  '',
          password : '',
          alert: null,
          loading: false,
          returnTo : null,
          formError : {
            name : '',
            phone : '',
            code : '',
            password : ''
          }
        };

        // this.history = useHistory();

        this.http = new Http;

        this.service = new AuthService();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.navToLogin = this.navToLogin.bind(this);
    }

    componentDidMount() {
      const {setCache, cache} = this.props;
      const { authPage } = cache;
      const returnTo = _.get(this.props, 'history.location.state.returnTo', null);
      console.log('return', returnTo);
      if (returnTo != null) {
        setCache({
          authPage : {...authPage, returnTo}
        });
      }
    }

    navToLogin() {
      this.props.history.push(Helper.getRoute('login'));
    }

    handleNameChange(name) {
      if(name.match("^[a-zA-Z\u0621-\u064A0-9٠-٩_]*$")){
        this.setState({name})
      }
    }

    handleSubmit(e) {
      e.preventDefault();
      const { name, phone, email, password, code } = this.state;
      const { state } = this.props.location;
      const { setCache, cache, t } = this.props;
      const { authPage } = cache;

      const returnTo = _.get(this.props, 'history.location.state.returnTo', null);
      
      let errors = {};

      if (name == '') {
        errors['name'] = t('Please type your full name');
      }

      if (Helper.phone_validator(phone)) {
        errors['phone'] = t('Phone number is incorrect');
      }

      // if (Helper.email_validator(email)) {
      //   errors['email'] = t('Email is invalid');
      // }

      // if (password == '') {
      //   errors['password'] = t('Password is invalid');
      // }

      if (Object.keys(errors).length) {
        return this.setState({
          formError : errors
        });
      }


      setCache({
        authPage : {
          ...authPage,
          name,
          phone,
          email
        }
      });

      this.setState({ loading: true, alert: null });

      this.http.post('auth/guest', {
        name,
        phone,
        code,
        password
      })
      .then(data => {
        const { error, errors, user, message, access_token } = data;
        this.setState({ loading: false });
        if (error) {
          setTimeout(() => {
            this.setState({ alert: null })
          }, 5000);

          return this.setState({ formError : errors });
        }

        // this.props.setUser({ ...user, access_token });
        this.props.history.push(Helper.getRoute('verify'), {
          returnTo
        });
      })
      .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
      });
    }


    /**
     * Handles back button action.
     */
    handleGoBack() {
        // return this.props.history.goBack();
    }

    /**
     * Render the component.
     */
    render() {
        const { loading, name, phone, alert, email, password, formError } = this.state;
        const { t } = this.props;

        return (
            <Layout className="signup-page" title={t('Signup')}>
                <form onSubmit={this.handleSubmit}>
                    <IonItem lines="none">
                        <IonLabel className="head ion-text-wrap">
                            <IonText color="primary">
                              <h1>{t('Create an account')}</h1>
                            </IonText>
                            <p>{t('Please enter your full name and phone number to continue')}</p>
                            {' '}
                            <p className="text-w" onClick={this.navToLogin}>{t('You have an account?')}</p>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="none">
                      <Input
                        hasError={formError.name}
                        name="full_name"
                        autofocus
                        value={name}
                        onChange={(name) => this.setState({name})}
                        placeholder={t('Type your full name')}
                      />
                    </IonItem>
                    <IonItem className="phone-numbers item-phone" lines="none">
                      <Input
                        hasError={formError.phone}
                        type="tel"
                        value={phone}
                        onChange={(phone) => this.setState({ phone })}
                        placeholder={t('Type your phone')}
                      />
                    </IonItem>
                    {/* <IonItem lines="none" className='item-email'>
                      <Input
                        hasError={formError.email}
                        name="email"
                        autofocus
                        value={email}
                        onChange={(email) => this.setState({email})}
                        placeholder={t('Type your email')}
                      />
                    </IonItem> */}
                    {/* <IonItem lines="none" className='item-password'>
                      <Input
                        type="password"
                        hasError={formError.password}
                        name="password"
                        value={password}
                        onChange={(password) => this.setState({password})}
                        placeholder={t('Choose a password')}
                      />
                    </IonItem> */}
                    <IonItem lines="none">
                      <Button type="submit" loading={loading} full>
                        {t('Create account')}
                      </Button>
                    </IonItem>
                    <IonItem lines="none">

                      {/* <Button type="button" className="login-button" onClick={this.navToLogin} full>
                        {t('Login')}
                      </Button> */}
                    </IonItem>
                </form>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(Signup)));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, select, cache }) {
    return {
      cache,
      history: select.history,
      auth,
    };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setUser: (data) => dispatch(AuthAction.setUser(data)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}
