import React from 'react';
import PropTypes from 'prop-types';

import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonButton,
  IonBadge,
} from '@ionic/react';

import './Header.scss';
import { useHistory } from 'react-router';

/**
 * Dynamic app header.
 */
const Header = ({ toolbar, children, title, className, backButton, buttons, ...props }) => {
  const history = useHistory();

  /**
   * Handles back button action.
   */
  const handleGoBack = () => {
    return history.goBack();
  };

  return (
    <IonHeader mode="ios" className={'header ion-no-border ' + className}>
      <IonToolbar>
        <IonButtons slot="start">
          {backButton && (
            <IonButton className="back-button" onClick={handleGoBack}>
              <IonIcon icon="chevron-back" />
            </IonButton>
          )}
        </IonButtons>
        {title && <IonTitle>{title}</IonTitle>}

        <IonButtons mode="ios" slot="end">
          {buttons &&
            buttons.map((b, i) => (
              <IonButton key={i} routerLink={b.href} onClick={b.onClick}>
                {b.render && b.render()}
                {b.icon && <IonIcon mode="ios" color={b.color} icon={b.icon} />}
                {b.badge && b.badge !== '0' && (
                  <IonBadge color="secondary">{b.badge}</IonBadge>
                )}
              </IonButton>
            ))}
        </IonButtons>
      </IonToolbar>
      {toolbar && (
        <div>
          {toolbar}
        </div>
      )}
    </IonHeader>
  );
};

Header.propTypes = {
  // Custom content
  children: PropTypes.node,
  // Header title
  title: PropTypes.string,
  // Custom class name
  classeName: PropTypes.string,
  // Back button
  backButton: PropTypes.bool,
  // Right side buttons
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      href: PropTypes.string,
      onClick: PropTypes.func,
      badge: PropTypes.string,
    })
  ),
};

// Default values...
Header.defaultProps = {
  title: null,
  className: '',
  backButton: null,
  buttons: [],
};

export default Header;
