export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const IN_CART = 'IN_CART';
export const ADD_QUANTITY = 'ADD_QUANTITY';
export const SUB_QUANTITY = 'SUB_QUANTITY';
export const EMPTY_CART = 'EMPTY_CART';

export default class CartAction {
  /**
   * Add to cart action
   * @param {Object} data
   */
  static addToCart(data, auth) {
    return {
      type: ADD_TO_CART,
      data,
      auth,
    };
  }

  /**
   * Remove from cart action
   * @param {Object} data
   */
  static removeFromCart(data, auth) {
    return {
      type: REMOVE_FROM_CART,
      data,
      auth,
    };
  }

  /**
   * Remove from cart action
   * @param {Object} data
   */
  static inCart(data, auth) {
    return {
      type: IN_CART,
      data,
      auth,
    };
  }

  /**
   * Subtract cart item quantity action.
   * @param {Object} data
   */
  static subQuantity(data, auth) {
    return {
      type: SUB_QUANTITY,
      data,
      auth,
    };
  }

  /**
   * Add cart item quantity action.
   * @param {Object} data
   */
  static addQuantity(data, auth) {
    return {
      type: ADD_QUANTITY,
      data,
      auth,
    };
  }

  /**
   * Empty cart action.
   */
  static emptyCart(auth) {
    return {
      type: EMPTY_CART,
      auth,
    };
  }
}
