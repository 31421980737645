import React from 'react';
import { Routes } from './AppRouter';
import i18next from 'i18next';

export default class Helper {
  /**
   * Gets the current route path.
   * @param {String} name
   */
  static getRoute(name) {
    for (const route of Routes) {
      if (route.name === name) {
        return route.path;
      }
    }
    return '/' + name;
  }

  /**
   * Gets list of route paths.
   */
  static getRoutePaths() {
    const paths = [];
    for (const route of Routes) {
      paths.push(route.path);
    }
    paths.reverse();
    return paths;
  }

  /**
   * Gets list of route paths.
   */
  static imageUrl(name) {
    const cdn = process.env.REACT_APP_CDN;
    return cdn + '/' + name;
  }

  /**
   * Scroll to.
   * @param {HTMLElement} e
   * @param {*} to
   */
  static scrollTo(e, to) {
    let activedChild = null;
    if (e) {
      for (let i = 0; i < e.children.length; i++) {
        if (e.value === e.children[i].value) {
          activedChild = e.children[i];
        }
      }
    }

    if (activedChild) {
      const left = activedChild.offsetLeft - e.clientWidth / 2 + activedChild.clientWidth / 2;
      e.scrollTo({
        [to]: left,
        behavior: 'smooth',
      });
    }
  }

  /**
   * Get Distance Between two points.
   * @param {Object} point1
   * @param {Object} point2
   * @return {Number} Returns the distance in meter
   */
  static getDistance(point1, point2) {
    const rad = (x) => {
      return (x * Math.PI) / 180;
    };

    const R = 6378137; // Earth’s mean radius in meter
    const dLat = rad(point2.lat - point1.lat);
    const dLong = rad(point2.lng - point1.lng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(point1.lat)) *
        Math.cos(rad(point2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return parseInt(d);
  }

  static calculateDistance(currentPoint, oldPoint) {
    // The radius of the Earth in kilometers
    const R = 6371;

    // Convert latitude and longitude from degrees to radians
    const radLat1 = (Math.PI * currentPoint.lat) / 180;
    const radLon1 = (Math.PI * currentPoint.lng) / 180;
    const radLat2 = (Math.PI * oldPoint.lat) / 180;
    const radLon2 = (Math.PI * oldPoint.lng) / 180;

    // Haversine formula
    const dLat = radLat2 - radLat1;
    const dLon = radLon2 - radLon1;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(radLat1) * Math.cos(radLat2) *
              Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;

    return distance;
  }

  static mapProductToCart(product, quantity, variants) {

    let price = Number(product.price);

    variants.map(item => {
      if (item.type == 'product') {
        price = price + Number(item.price); 
      }
    });

    return {
      cart_id : Helper.uid(12),
      product_id : product['id'],
      ...product,
      variants,
      price,
      quantity
    };
  }

  /**
   * Format the amount.
   * @param {Object} amount
   */
  static amountFormat({ amount, html = true, unitStyle = {}, priceStyle = {}, unitClassName = '', priceClassName = '', strike = false }) {
    const price = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount > 0 ? amount : 0);

    const currency = i18next.language === 'ar' ? 'درهم' : 'DH';

    if (!html) {
      return price + ' ' + currency;
    }

    return (
      <>
        {strike ? <strike className={priceClassName} style={priceStyle}>{price}</strike> : <strong className={priceClassName} style={priceStyle}>{price}</strong>} <small className={unitClassName} style={unitStyle}>{currency}</small>
      </>
    );
  }

  static email_validator(email) {
    var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (re.test(email)) {
      return false
    }
    return true;
  }

  static phone_validator(phone) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    if (re.test(phone)) {
      return false
    }
    return true;
  }

  static logError(title, obj = {}) {
    return console.error(title, obj);
  }

  /**
   * Generate uid
   * @param {Number} length
   */
  static uid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
