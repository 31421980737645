import React, { Component } from 'react';
import { connect } from 'react-redux';

import { firebase } from 'services/firebase/firebase';

import {
  IonSlides,
  IonSlide,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonLabel,
} from '@ionic/react';

import { IonItem, IonText, IonButtons, IonButton, IonIcon } from '@ionic/react';

import './Welcome.scss';
import { Button, Radio, Input } from 'components/Form';
import { chevronForwardOutline } from 'ionicons/icons';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import Helper from 'Helper';
import ReactImageAppear from 'react-image-appear';
import { Loading, Modal } from 'components';
import i18next from 'i18next';

import AuthService from 'services/auth.service';
import AuthAction from 'store/actions/auth';
import CacheAction from 'store/actions/cache';
import Http from 'libs/http';
import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import SignupForm from 'containers/auth/Login/SignupForm';


export class Welcome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEnd: false,
      lang: 'en',
      isLang: false,
      hideSlides: true,
      step : 1,
      alert : null,
      email : '',
      name : '',
      phone : '',
      password : '',
      loading : false,
      formError : {
        name : '',
        phone : '',
        email : '',
        password : ''
      }
    };

    this.service = new AuthService();

    this.http = new Http

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSliderRef = this.handleSliderRef.bind(this);
    this.handleChangeLang = this.handleChangeLang.bind(this);
    this.handleLater = this.handleLater.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.navToLogin = this.navToLogin.bind(this);

    this.slider = null;

    this.signupFormValidator = null;

  }

  /**
   * Handles the confirmation button
   */
  handleConfirm() {
    const { history } = this.props;
    this.setState({ hideSlides: true }, () => {
      if (history) {
        history.push(Helper.getRoute('login'));
      }
    });
  }

  componentDidMount() {

    // console.log('me', mixpanel);

    // mixpanel.track('PageView');
    // setTimeout(() => {
    //   this.setState({ hideSlides: false });
    // }, 300);
    const { cache, setCache, auth, history } = this.props;
    
    if (auth['user']['isGuest'] == false || cache.firstTime == false) {

      return history.push(Helper.getRoute('home'));
    }
    

    // this.handleChangeLang();

    // if (cache.firstTime) {
    //   setCache({ firstTime: false });
    // }
  }

  componentWillUnmount() {
    this.setState({ hideSlides: true });
  }

  handleSliderRef(ref) {
    
    if (!this.slider) {
      this.slider = ref;
      if (ref) {
        ref.addEventListener('ionSlideWillChange', (e) => {
          this.setState({ isEnd: e.target.swiper.isEnd });
        });
      }
    }
  }

  handleAuth(user, access_token) {
    const { cache, setCache, auth, history, setUser } = this.props;
    const { lang, name, email, phone } = this.state;

    return this.http.post('auth/create-user', {
      lang,
      name,
      email,
      phone
    })
    .then(data => {

      const { error, user, message, access_token } = data;
      this.setState({ loading: false });

      if (error) {
        setTimeout(() => {
          this.setState({ alert: null })
        }, 5000);
        return this.setState({ alert: message });
      }

      setUser({ ...user, access_token });

      return ;
    })
  }

  handleLater() {
    const { cache, setCache, auth, history, setUser } = this.props;
    const { lang, name, email, phone } = this.state;

    this.http.post('auth/create-user', {
      lang,
      guest : true
    })
    .then(data => {
      const { error, user, message, access_token } = data;

      this.setState({ 
        loading: false,
        // firstTime: false
      });

      setCache({ firstTime : false });

      if (error) {
        setTimeout(() => {
          this.setState({ alert: null })
        }, 5000);
        return this.setState({ alert: message });
      }

      setUser({ ...user, access_token });

      return history.push(Helper.getRoute('home'));
    })
  }

  setError() {

  }

  handleSubmit() {

    const { cache, setCache, auth, history, setUser, t } = this.props;
    const { lang, name, email, phone, password, alert, formError } = this.state;

    let errors = {};

    if (name == '') {
      errors['name'] = t('Please type your full name');
    }

    if (Helper.phone_validator(phone)) {
      errors['phone'] = t('Phone number is incorrect');
    }

    if (Helper.email_validator(email)) {
      errors['email'] = t('Email is invalid');
    }

    if (password == '') {
      errors['password'] = t('Password is invalid');
    }

    if (Object.keys(errors).length) {
      return this.setState({
        formError : errors
      });
    }

    this.http.post('auth/create-user', {
      lang,
      name,
      email,
      phone,
      password
    })
    .then(data => {
      const { error, user, message, access_token } = data;

      this.setState({ 
        loading: false,
      });

      if (error) {
        setTimeout(() => {
          this.setState({ alert: null })
        }, 5000);
        return this.setState({ alert: message });
      }

      setCache({ lang, firstTime : false });

      setUser({ 
        ...user,
        isGuest : true,
        access_token
       });

      return history.push(Helper.getRoute('home'));
    })
    .catch(err => {

    })
  }

  handleChangeLang() {
    const { cache, setCache, auth, history, setUser } = this.props;
    const { lang, name, email, phone } = this.state;

    document.querySelector('#root > div').className = lang === 'ar' ? 'rtl' : 'ltr';
    i18next.changeLanguage(lang);
    this.setState({ isLang: false, loading : true});
    
    this.http.post('auth/create-user', {
      lang,
      guest : true
    })
    .then(data => {
      const { error, user, message, access_token } = data;

      this.setState({ 
        loading: false,
      });

      setCache({ lang, firstTime : false });

      if (error) {
        setTimeout(() => {
          this.setState({ alert: null })
        }, 5000);
        return this.setState({ alert: message });
      }

      setUser({ ...user, type : 'guest', access_token });

      return history.push(Helper.getRoute('home'));
    })
    .catch(err => {
      this.setState({ loading : true });
    })
  }

  navToLogin() {
    this.props.history.push(Helper.getRoute('login-password'));
  }

  handleStart() {
    // this.props.history.push(Helper.getRoute('home'));

    this.setState({
      isLang : true
    });
  }

  render() {
    const { t } = this.props;
    const { isEnd, hideSlides, isLang, lang, name, email, phone, password, loading, alert, formError } = this.state;

    const slideOpts = {
      initialSlide: 2,
      speed: 200,
    };

    return (
      <>
        <IonPage className="welcome-page">
          <IonContent fullscreen>
            <div className="content">
              <div >
                <div className="img ">
                  <img src="/assets/images/logo.jpg" alt="logo"
                    style={{ width : '100px', height : '100px' }}
                  />  
                </div>
                <div className="title">
                  Tacos de nice
                </div>
                <div className="description">
                  <br></br>
                  Original tacos france
                  
                </div>
              </div>
              <IonSlides pager="true" options={slideOpts} ref={this.handleSliderRef}>
                <IonSlide>
                  {loading == false ? (
                    <>
                      <div style={{ padding: '0 15px', paddingTop : '50%', display : 'none' }}>
                      <IonLabel style={{ marginBottom: 5 }}>إختر اللغة</IonLabel>
                      <Radio
                        full
                        onChange={(value) => {
                          if (value) {
                            this.setState({lang : value})
                          }
                        }}
                        value={lang}
                        options={[
                          {
                            label: 'العربية',
                            value: 'ar',
                          },
                          {
                            label: 'English',
                            value: 'en',
                          },
                          {
                            label: 'Français',
                            value: 'fr',
                          },
                        ]}
                      />
                    </div>
                    <div style={{ padding: '15px 15px' }}>
                      <Button full style={{width : '100%'}} 
                        onClick={this.handleChangeLang}
                        track={['Set Lang', {lang}]}
                        loading={loading}
                      >
                        {t('Découvrez notre menu')}
                      </Button>
                    </div>
                    </>
                  ) : (
                    <>
                      <Loading />
                    </>
                  )}
                  <div dir='ltr' className={classNames('ltr')}>
                    
                  </div>
                </IonSlide>
                {/* <IonSlide>
                  <div className={classNames({'rtl' : lang == 'ar'})}
                    style={{ padding: '0 15px', paddingTop : '23%' }}
                  >
                    <IonItem lines="none">
                      <IonLabel className="head ion-text-wrap">
                          <IonText color="primary">
                              <h1>{t('Create an account')}</h1>
                          </IonText>
                          <p>
                            {t('Please enter your full name and phone number to continue')}
                            {' '}
                          </p>
                          <span style={{color : '#8bca21 !important'}} onClick={this.navToLogin}>{t('You have an account?')}</span>
                      </IonLabel>
                    </IonItem>
                    {alert !== null && <div className="form-control alert alert-danger">{alert}</div>}
                    <IonItem lines="none">
                      <Input
                        hasError={formError.name}
                        name="full_name"
                        autofocus
                        value={name}
                        onChange={(name) => this.setState({name})}
                        placeholder={t('Type your full name')}
                      />
                    </IonItem>
                    <IonItem className="phone-numbers item-phone" lines="none">
                      <Input
                        hasError={formError.phone}
                        type="tel"
                        value={phone}
                        onChange={(phone) => this.setState({ phone })}
                        placeholder={t('Type your phone')}
                      />
                    </IonItem>
                    <IonItem lines="none" className='item-email'>
                      <Input
                        hasError={formError.email}
                        name="email"
                        autofocus
                        value={email}
                        onChange={(email) => this.setState({email})}
                        placeholder={t('Type your email')}
                      />
                    </IonItem>
                    <IonItem lines="none" className='item-password'>
                      <Input
                        type="password"
                        hasError={formError.password}
                        name="password"
                        value={password}
                        onChange={(password) => this.setState({password})}
                        placeholder={t('Choose a password')}
                      />
                    </IonItem>
                    <IonItem lines="none">
                      <Button full style={{width : '100%'}} onClick={this.handleSubmit}
                        track="Signup Btn Welcome"
                      >
                        {t('Continue')}
                      </Button>
                    </IonItem>
                    <IonItem lines="none">
                      <Button className={'btn-later'} full style={{width : '100%'}} onClick={this.handleLater}
                        track="Skip Welcome"
                      >
                        {t('Skip')}
                      </Button>
                    </IonItem>
                  </div>
                </IonSlide> */}
              </IonSlides>
              
            </div>
          </IonContent>
        </IonPage>
        {/* <Modal isOpen={isLang} raduis={false}>
          <div style={{ padding: '0 15px' }}>
            <IonLabel style={{ marginBottom: 5 }}>Select your language</IonLabel>
            <Radio
              full
              onChange={(value) => {
                if (value) {
                  this.setState({lang : value})
                }
              }}
              value={lang}
              options={[
                {
                  label: 'العربية',
                  value: 'ar',
                },
                {
                  label: 'English',
                  value: 'en',
                },
                {
                  label: 'Frensh',
                  value: 'fr',
                },
              ]}
            />
          </div>
          <div style={{ padding: '15px 15px' }}>
            <Button full style={{width : '100%'}} 
              onClick={this.handleChangeLang}
            >{t('Confirm')}</Button>
          </div>
          
        </Modal> */}
      </>
    );
  }
}

// export default withNamespaces()(withRouter(Welcome));
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(Welcome)));


/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setUser: (data) => dispatch(AuthAction.setUser(data)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, cache }) {
  return {
    cache,
    auth,
  };
}