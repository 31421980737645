import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Login.scss';
import { IonItem, IonLabel, IonText, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { Input, Button, Select } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import Helper from 'Helper';

import * as _ from 'lodash';

import CacheAction from 'store/actions/cache';

class SignupForm extends Component {
    constructor(props) {
        super(props);

        const { name, phone, email } = _.get(this.props, 'cache.authPage', {});
        
        this.state = {
          name: name || '',
          phone: (phone || '').replace('+966', ''),
          email :  email || '',
          password : '',
          formError : {
            name : '',
            email : '',
            phone : '',
            password : ''
          }
        };

    }

    componentDidMount() {
      const { setValidator } = this.props;
      console.log('validator has been s');
      setValidator(this.handleValidation);

      // const {setCache, cache} = this.props;
      // const { authPage } = cache;
      // const returnTo = _.get(this.props, 'history.location.state.returnTo', null);
      // console.log('return', returnTo);
      // if (returnTo != null) {
      //   setCache({
      //     authPage : {...authPage, returnTo}
      //   });
      // }
    }

    handleValidation() {
      const { t } = this.props;
      const { name, email, phone, password, formError } = this.state;
      console.log('check validation',  this.state)
      let errors = {};
      if (name == '') {
        errors['name'] = t('Please type your full name');
      }

      if (Helper.phone_validator(phone)) {
        errors['phone'] = t('Phone number is incorrect');
      }

      if (Helper.email_validator(email)) {
        errors['email'] = t('Email is invalid');
      }

      if (Object.keys(errors).length) {
        return this.setState({
          formError : errors
        });
      }

      return;
    }

  
    /**
     * Render the component.
     */
    render() {
        const { loading, name, phone, email, password, formError } = this.state;
        const { t } = this.props;

        return (
          <div>
            <IonItem lines="none">
              <Input
                hasError={formError.name}
                name="full_name"
                autofocus
                value={name}
                onChange={(name) => this.setState({name})}
                placeholder={t('Full name')}
              />
            </IonItem>
            <IonItem className="phone-numbers item-phone" lines="none">
              <Input
                hasError={formError.phone}
                type="tel"
                value={phone}
                onChange={(phone) => this.setState({ phone })}
                placeholder={t('Phone number')}
              />
            </IonItem>
            <IonItem lines="none" className='item-email'>
              <Input
                hasError={formError.email}
                name="email"
                autofocus
                value={email}
                onChange={(email) => this.setState({email})}
                placeholder={t('Email')}
              />
            </IonItem>
            <IonItem lines="none" className='item-password'>
              <Input
                hasError={formError.password}
                name="password"
                value={password}
                onChange={(password) => this.setState({password})}
                placeholder={t('Password')}
              />
            </IonItem>        
          </div>
        );
    }
}

export default connect(null, null)(withNamespaces()(withRouter(SignupForm)));

