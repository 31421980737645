import React, { Component } from 'react';

import { StatusBar } from '@ionic-native/status-bar';
import { OneSignal } from '@ionic-native/onesignal';
import mixpanel from 'mixpanel-browser';
import { AppVersion } from '@ionic-native/app-version';
import { Capacitor } from '@capacitor/core';
import { Market } from '@ionic-native/market';
import { withNamespaces } from 'react-i18next';
import { remoteConfig } from 'services/firebase/firebase';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Theme variables */
import './theme/global.scss';
import 'animate.css';
import './theme/tailwind.css';

import AppRouter from './AppRouter';
import i18next from 'i18next';
import { IonApp, IonButton, IonIcon } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';

import { store } from 'store';

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
    this.appVersion = AppVersion
    this.market = Market

    const { lang } = store.getState().cache;

    i18next.changeLanguage(lang || 'en');
  }

  handleUpdateAction() {
    if(Capacitor.getPlatform() === 'android'){
      Market.open('com.enab.buyer')
    }
    else if(Capacitor.getPlatform() === 'ios'){
      Market.open('1572350356')
      // https://apps.apple.com/us/app/enab-shopping-عنب-للتسوق/id1572350356
    }
  }

  async componentDidMount() {
    Sentry.init({
      dsn: "https://3bd4c2ff485249b1ab001392a633c1c9@o4504531923107840.ingest.sentry.io/4504531925139457",
      integrations: [new BrowserTracing()],
    
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });

    Promise.all([
      remoteConfig.fetchAndActivate(),
      this.appVersion.getVersionNumber()
    ])
    .then(([isFetched, currentAppVersion]) => {
      const remoteVersion = remoteConfig.getValue("mobile_app_version")?._value
      if(remoteVersion && remoteVersion !== currentAppVersion){
        this.setState({
          newVersion: remoteVersion,
          showModal: true
        })
      }
    })

    console.log('mixpanel', mixpanel)

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      ignore_dnt : true,
      debug: false
    }); 
    
    mixpanel.track('App Lunch');

    StatusBar.styleBlackOpaque();

    OneSignal.startInit(
      process.env.REACT_APP_ONESIGNAL_API_KEY,
      process.env.REACT_APP_ONESIGNAL_APP_ID
    );

    OneSignal.inFocusDisplaying(OneSignal.OSInFocusDisplayOption.InAppAlert);

    OneSignal.handleNotificationReceived().subscribe(() => {
      // do something when notification is received
    });

    OneSignal.endInit();
    
  }

  /**
   * Render the component.
   */
  render() {
    const { t } = this.props
    const isRTL = i18next.language === 'ar';
    return (
      <div className={isRTL ? 'rtl' : 'ltr'}>
        <IonApp>
          <AppRouter />
          {
            this.state.showModal && (
              <div className='modal-back-drop'>
                <div className='update-modal'>
                  <IonIcon style={{fontSize: 30, color: '#aaa', marginRight: 10, marginTop: 10}} icon={closeCircleOutline} className="action-btn" onClick={() => this.setState({showModal: false})}/>
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 10, paddingTop: 0, textAlign: 'center'}}>
                    <h3>{t('versionUpdate')}</h3>
                    <img src='/assets/icon/icon.jpg' width={80} height={80} style={{borderRadius: 12, marginTop: 5, marginBottom: 5}}/>
                    <h4>{this.state.newVersion}</h4>
                    <h5>{t('versionUpdateDescription')}</h5>
                    <IonButton style={{marginTop: 10, marginBottom: 10}} color="secondary" onClick={this.handleUpdateAction}>{t('updateApp')}</IonButton>
                  </div>
                </div>
              </div>
            )
          }
        </IonApp>
      </div>
    );
  }
}

export default withNamespaces()(App);
