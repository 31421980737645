import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Button.scss';
import { IonIcon } from '@ionic/react';
import { Link } from 'react-router-dom';
import { rollingLoading } from 'components/Icons';
import mixpanel from 'mixpanel-browser'

/**
 * A sample button.
 */
const Button = ({
  label,
  children,
  type,
  shadow,
  raduis,
  large,
  small,
  full,
  variant,
  className,
  loading,
  icon,
  onClick,
  disabled,
  href,
  track,
}) => {
  const Element = (props) =>
    href ? (
      <Link to={href}>
        <button {...props}></button>
      </Link>
    ) : (
      <button {...props}></button>
    );

  
  const handleClick = () => {
    
    onClick();

    if (Array.isArray(track)) {
      let label = track[0] || '';
      let data = track[1] || null;
      mixpanel.track(label, data);
    }
    else if (typeof(track) == 'string') {
      mixpanel.track(track);
      console.log('track', track)
    }

  };

  return (
    <Element
      type={type}
      onClick={handleClick}
      disabled={disabled || loading}
      className={classNames(
        'btn',
        className,
        shadow && 'shadow',
        raduis && 'raduis',
        large && 'large',
        small && 'small',
        full && 'full',
        icon && 'has-icon',
        variant
      )}
    >
      <span>{loading ? <IonIcon icon={rollingLoading}></IonIcon> : label || children}</span>
      <div className="class"></div>
      {icon && <IonIcon icon={icon}></IonIcon>}
    </Element>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  shadow: PropTypes.bool,
  raduis: PropTypes.bool,
  /** Large Button */
  large: PropTypes.bool,
  // Small button
  small: PropTypes.bool,
  /** Full with */
  full: PropTypes.bool,
  /** Use 'outline' or 'solid'. Other values are deprecated */
  variant: PropTypes.oneOf([
    'outline',
    'primary',
    'secandary',
    'secandary-lighten',
    'tertiary',
    'transparent',
  ]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.node,
  href: PropTypes.string,
  track : PropTypes.any
};

Button.defaultProps = {
  track : null,
  label: '',
  type: 'button',
  shadow: false,
  raduis: true,
  large: false,
  small: false,
  full: false,
  variant: 'primary',
  className: '',
  loading: false,
  onClick: () => null,
};

export default Button;
