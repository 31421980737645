import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  ADD_QUANTITY,
  SUB_QUANTITY,
  EMPTY_CART,
} from '../actions/cart';

const initialState = {
  items: [],
  total: 0,
  amount: 0,
  coupon : ''
};

const cart = (stateData = initialState, action) => {
  const product = action.data;
  const auth = action.auth;

  const withUserStore = (data) => {
    let total = 0;
    let amount = 0;
    for(let i = 0; i < data.items.length; i++) {
      amount += (data.items[i]['price'] * data.items[i]['quantity']);
      total++;
    }

    return {...data, total, amount};
  };

  const getState = () => {
    try {
      return stateData;
    } catch (e) {
      return initialState;
    }
  };

  const state = getState();

  switch (action.type) {
    case ADD_TO_CART:
      let items = state.items;
      let amount = state.amount;
      let total = state.total;

      let added = false;
      for(let i = 0;i < state.items.length; i++) {
        if (product.id == state.items[i]['id']) {
          items[i] = product;
          amount += product.price * product.quantity;
          added = true;
          break;
        }
      }

      if (added == false) {
        items = [ ...items, product ];
        total++;
      }

      return withUserStore({
        // items: [...state.items, { ...action.data }],
        items : items,
        // items: state.items.map((item) =>
        //   item.id === action.data.id ? { ...item } : item
        // ),
        amount: state.amount + product.price,
        total: total,
      });

    case REMOVE_FROM_CART:
      return withUserStore({
        items: state.items.filter((item) => {
          if (item.id !== product.id) {
            return item;
          }
          return false;
        }),
        amount: state.amount - product.price,
        total: state.total - 1,
      });

    case ADD_QUANTITY:
      return withUserStore({
        items: state.items.map((item) =>
          item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
        ),
        amount: state.amount + product.price,
        total: state.total + 1,
      });

    case SUB_QUANTITY:
      return withUserStore({
        items: state.items.map((item) =>
          item.id === product.id
            ? {
                ...item,
                quantity: item.quantity !== 1 ? item.quantity - 1 : 1,
              }
            : item
        ),
        amount: state.amount - product.price,
        total: state.total > 1 ? state.total - 1 : 0,
      });

    case EMPTY_CART:
      return withUserStore(initialState);
    default:
      return stateData;
  }
};

export default cart;
