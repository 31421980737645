export const SET_USER = 'SET_USER';
export const SET_STORE = 'SET_STORE';
export const RESET = 'RESET';

export default class AuthAction {
  /**
   * Set user action.
   * @param {Object} data
   */
  static setUser(data) {
    return {
      type: SET_USER,
      data,
    };
  }

  /**
   * Set store action.
   * @param {Object} data
   */
  static setStore(data) {
    return {
      type: SET_STORE,
      data,
    };
  }

  /**
   * Reset action.
   */
  static reset() {
    return {
      type: RESET,
    };
  }
}
