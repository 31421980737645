import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import './MyPage.scss';
import { Page, Modal } from 'components';
import { withNamespaces } from 'react-i18next';
import Helper from 'Helper';
import AuthAction from 'store/actions/auth';
import { IonList, IonItem, IonIcon, IonLabel,
  IonRadioGroup,  IonRadio, IonModal } from '@ionic/react';
import { avatar } from 'components/Icons';

import Http from 'libs/http'

import { 
  chatbubbleEllipsesOutline as ceo, 
  logOutOutline,
  barcodeOutline,
  languageOutline,
  heartOutline,
  closeCircleOutline
 } from 'ionicons/icons';

import AuthService from 'services/auth.service';

import CacheAction from 'store/actions/cache';
import i18next from 'i18next';

// import { QRScanner, QRScannerStatus } from '@ionic-native/qr-scanner';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';
import { Button } from 'components/Form';

const MyPage = ({ t, history, reset, ...props }) => {
  const [currentLang, setCurrentLang] = useState('en');
  const [langModal, setLangModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const href = (r) => history.push(Helper.getRoute(r));

  useEffect(() => {
    setCurrentLang(i18next.language);

  }, []);

  const http = useMemo(() => new Http(), []);
  const auth = useMemo(() => new AuthService(), []);
  
  const handleLogOut = async () => {
    const { setCache } = props;
    setCache({
      firstTime : true
    });
    // Logout from firebase
    await auth.logOut();

    // Reset redux
    reset();

    history.push(Helper.getRoute('welcome'));
  };

  const handleChangeLang = async () => {
    setLangModal(false);
    
    const { setCache } = props;

    setCache({
      lang : currentLang
    });

    i18next.changeLanguage(currentLang);
  }

  const changeLanguage = async () => {
    setLangModal(true);
  }

  const deleteAccount = () => {
    setDeleteModal(true);
  }

  const handleDeletingAccount = () => {
    setDeleteLoading(true);

    http.post('user/delete', {
      pass : "code"
    })
    .then(res => {
      console.log('res', res);
      setDeleteLoading(false);
      handleLogOut();
    })
    .catch(err => {
      setDeleteLoading(false);
      console.error(err);
    })
  }

  const list = [
    // { label: 'Scan', href: () => {}, icon: barcodeOutline },
    // { label: t('Wishlist'), href: () => href('wishlist'), icon: heartOutline },
    // { label: t('Change language'), href: changeLanguage, icon: languageOutline },
    { label: t('Profile'), href: () => href('profile'), icon: avatar },
    { label: t('Support'), href: () => href('support'), icon: ceo },
    { label: t('Delete my data'), href: deleteAccount, icon: closeCircleOutline },
    { label: t('Logout'), href: handleLogOut, icon: logOutOutline },
  ];

  let authG = props['auth'];
  let isGuest = false;
  if (authG != null && authG['user'] != null) {
    if (authG['user']['type'] == 'guest') {
      isGuest = true;
    }
  }

  const navToLogin = () => {
    history.push(Helper.getRoute('signup'));
  }

  return (
    <Page className="my-page" title={t('My account')}>
      { isGuest == true && (
        <div style={{ background:"#fff", padding:"20px" }}>
          <div>
          {t('Please you should sign-in first')}
          </div>
          <div style={{ paddingTop : "10px" }}>
            <button className="btn btn-xs" style={{ fontSize:"12px", padding: "4px 20px", "height" : "unset" }}
              onClick={() => navToLogin()}
            >
              {t('Sign-in')}
            </button>
          </div>
        </div>
      )}

      { isGuest != true && (
        <IonList className="list" mode="ios" lines="none">
          {list.map(({ label, href, icon }, i) => (
            <IonItem key={i} button onClick={href} detail={false}>
              <IonIcon slot="start" icon={icon}></IonIcon>
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      )}

      <IonModal isOpen={deleteModal}>
        <Page title={t('Delete my data')} className="delete-page-modal" loading={deleteLoading}>
          <h1>{t('Delete my data?')}</h1>
          <p>{t('If you deleted your data you will not be able to sign-in again to your account. Are you sure you want to delete all your data?')}</p>
          
          <Button type="danger" className="btn btn-default" 
            onClick={() => setDeleteModal(false)}
          >{t('Cancel')}</Button>
          <Button type="danger" className="btn btn-danger" onClick={() => handleDeletingAccount()}>{t('Delete')}</Button>
        </Page>
      </IonModal>

        <Modal
          title={t('Change language')}
          onDismiss={() => setLangModal(false)}
          isOpen={langModal}
          className="filter-modal-container"
        >
          <div className='modal-lang-conatiner'>
            <IonList> 
              <IonRadioGroup value={currentLang}>
                <IonItem onClick={() => setCurrentLang('ar')}>
                  <IonLabel>العربية</IonLabel>
                  <IonRadio slot="end" value="ar"></IonRadio>
                </IonItem>

                <IonItem onClick={() => setCurrentLang('en')}>
                  <IonLabel>English</IonLabel>
                  <IonRadio slot="end" value="en"></IonRadio>
                </IonItem>

                <IonItem onClick={() => setCurrentLang('fr')}>
                  <IonLabel>Fronce</IonLabel>
                  <IonRadio slot="end" value="fr"></IonRadio>
                </IonItem>
              </IonRadioGroup>
            </IonList>
            <Button onClick={handleChangeLang}>{t('Apply')}</Button>
          </div>
        </Modal>
    </Page>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(MyPage));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ select, auth }) {
  return {
    history: select.history,
    auth,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setCache: (data) => dispatch(CacheAction.setCache(data)),
    reset: () => dispatch(AuthAction.reset()),
    setUser: (data) => dispatch(AuthAction.setUser(data)),
  };
}
