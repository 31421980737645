import axios from 'axios';
// import { firebase } from './firebase/firebase';

import AuthAction from 'store/actions/auth';
import Http from 'libs/http';

export default class AuthService {
  
    constructor() {
      this.apiUrl = process.env.REACT_APP_API;
      this.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      };

      this.http = new Http;
    }

    /**
     * Sign up.
     * @param {Object} params
     */
    async signUp(params) {
        return new Promise((resolve, reject) => {
          axios
            .post(`${this.apiUrl}/auth/login-byphone`, params, {
                headers: this.headers,
            })
            .then((res) => {
                resolve(res.data);
            })
            .catch(reject);
        });
    }

    async refresh() {
      return this.http.post('auth/refresh');
    }

    /**
     * Sign in.
     * @param {Object} params
     */
    async signIn(params) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this.apiUrl}/auth/verify-phone`, params, {
                    headers: this.headers,
                })
                .then(async ({ data }) => {
                    if (data) {
                        return resolve(data);
                    }

                    reject(data);
                })
                .catch(reject);
        });
    }

    /**
     * Sign in.
     * @param {Object} params
     */
    async signInAnonymously(params) {
        // return firebase
        //     .auth()
        //     .signInAnonymously()
        //     .then((res) => {
        //         return {
        //             isGuest: true,
        //             id: res.user.uid,
        //         };
        //     });
    }

    /**
     * Log out.
     */
    logOut() {
        // this.dispatch(RESET, null);
    }
}
