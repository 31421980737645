import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

import { IonModal } from '@ionic/react';

import Home from './containers/account/Home/Home';
import Collection from './containers/account/Collection/Collection';
import Login from './containers/auth/Login/Login';
import LoginWithPassword from './containers/auth/Login/LoginWithPassword';
import { Modal } from 'components';
import { useHistory, Route, useLocation, Redirect } from 'react-router-dom';
import AppTabs from './AppTabs';

import SlideRoutes from 'react-slide-routes';

import Store from './containers/account/Store/Store';
import Categories from './containers/account/Categories/Categories';
import TextOrder from './containers/account/TextOrder/TextOrder';

import Profile from './containers/account/Profile/Profile';
import Wishlist from './containers/account/Wishlist/Wishlist';

import Orders from './containers/account/Orders/Orders';
import Order from 'containers/account/Orders/Order/Order';
import Billing from 'containers/account/Orders/Billing/Billing';

import Cart from './containers/account/Cart/Cart';
import { readerOutline as reader } from 'ionicons/icons';
import { cartOutline as cartIcon } from 'ionicons/icons';
import { appsOutline as categoriesIcon } from 'ionicons/icons';

import Welcome from 'containers/first-time/Welcome/Welcome';
import Verify from 'containers/auth/Verify/Verify';
import { avatar, store } from 'components/Icons';
import Locations from 'containers/account/Locations/Locations';
import Products from 'containers/account/Products/Products';
import Shop from 'containers/account/Shop/Shop';

import ProductView from 'containers/account/ProductView/ProductView';
import Helper from 'Helper';
import Product from 'containers/account/Products/Product/Product';
import Checkout from 'containers/account/Checkout/Checkout';
import ThanksPage from 'containers/account/ThanksPage/ThanksPage';

import Support from 'containers/account/Support/Support';
import Location from 'containers/account/Locations/Location/Location';

import SelectAddress from 'containers/account/Checkout/SelectAddress/SelectAddress';
import TapPayment from 'containers/account/Checkout/TapPayment/TapPayment';
import AddLocation from 'containers/account/Checkout/AddLocation/AddLocation';

import MyPage from 'containers/account/MyPage/MyPage';

import Splash from 'containers/splash/Splash';
import mixpanel from 'mixpanel-browser';
import SearchResults from 'containers/account/Products/SearchResults';
import Signup from 'containers/auth/Login/Signup';
import Guest from 'containers/auth/Login/Guest';
// import mixpanel from 'mixpanel-browser';

/**
 * Tabs.
 * @param {Object} icon
 * @param {Boolean} hideTab
 */
const tab = (icon, hideTab = false) => ({
  tab: {
    hideTab,
    icon,
  },
});

/**
 * App routes.
 */
export const Routes = [
  { path: '/welcome', name: 'default', component: Welcome },
  { 
    path: '/',
    name: 'home',
    component: Home,
    ...tab(store),
    guard: { nostore: true }
  },
  { 
    path: '/categories',
    name: 'categories',
    component: Categories,
    // ...tab(categoriesIcon),
    guard: { nostore: true }
  },
  { 
    path: '/collection/:id',
    name: 'collection',
    component: Collection
  },
  { path: '/welcome', name: 'welcome', component: Welcome },
  { path: '/thank-you', name: 'thank-you', component: ThanksPage, guard: {} },
  { path: '/product/:id', name: 'product', component: Product, guard: {nostore : true} },
  { path: '/product-view/:id', name: 'product-view', component: ProductView, guard: {nostore : true} },
  // { path: '/products', name: 'products', component: Products, guard: {nostore : true} },
  
  { path: '/shop', name: 'shop', component: Shop, guard: {nostore : true} },
  { 
    path: '/search-results/:query',
    name: 'search-results',
    component: SearchResults
  },
  { path: '/login', name: 'login', component: Login },
  { path: '/signup', name: 'signup', component: Signup },
  { path: '/guest', name: 'guest', component: Guest },
  { path: '/login-password', name: 'login-password', component: LoginWithPassword },
  { path: '/verify', name: 'verify', component: Verify },
  { path: '/order', name: 'order', component: Order, guard: {} },

  { path: '/wishlist', name: 'wishlist', component: Wishlist, guard: {} },

  {
    path: '/locations/find',
    name: 'find-location',
    component: Location,
    guard: { nostore: true },
  },
  {
    path: '/checkout/select-address',
    name: 'checkout/select-address',
    component: SelectAddress,
    guard: { nostore: true },
  },
  {
    path: '/checkout/tap-payment',
    name: 'tap_payment',
    component: TapPayment
  },
  {
    path: '/checkout/add-location',
    name: 'checkout/add-location',
    component: AddLocation,
    guard: { nostore: true },
  },
  {
    path: '/order/text',
    name: 'text-order',
    component: TextOrder,
    guard: { nostore: true },
  },
  { path: '/store', name: 'store', component: Store, guard: {} },
  // { path: '/home', name: 'store', component: Store, ...tab(store), nostore : true },
  {
    path: '/locations',
    name: 'locations',
    component: Locations,
    guard: { nostore: true },
  },
  {
    path: '/orders',
    name: 'orders',
    label : 'My orders',
    component: Orders,
    ...tab(reader),
    guard: { nostore: true },
  },
  {
    path: '/orders/billing',
    name: 'order-billing',
    component: Billing
  },
  {
    path: '/my-page',
    name: 'my page',
    component: MyPage,
    ...tab(avatar),
    guard: {},
  },
  { path: '/cart', name: 'cart', component: Cart, ...tab(cartIcon), guard: { nostore: true } },
  { path: '/checkout', name: 'checkout', component: Checkout, guard: { nostore: true } },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    guard: { nostore: true },
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
    guard: {},
  },

  { path: '/:shop', name: 'products', component: Products, guard: {nostore : true} },
];

/**
 * Guarded Route
 */
const GuardedRoute = ({ render, auth, guard, ...rest }) => (
  <Route
    {...rest}
    render={(p) => {
      if (!auth.user) {
        return <Redirect to={Helper.getRoute('login')} />;
      }

      if (!auth.store && !guard.nostore) {
        return <Redirect to={Helper.getRoute('locations')} />;
      }

      return render();
    }}
  />
);

// const ProductModal = ({product, isOpen}) => {
  
  

//   return (
//     <Modal
//       onDismiss={() => handleProductViewDismiss()}
//       isOpen={isOpen}
//     >
//       <div style={{padding : '20px'}}>
//         marwan

//         {/* <button onClick= */}
//       </div>
//     </Modal>
//   )
// };

/**
 * Render Routes
 */
const AppRouter = ({ auth }) => {
  const history = useHistory();
  const location = useLocation();
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    history.listen((e) => {
      mixpanel.track('PageView', {
        pathname : e.pathname
      });
      setProductModalOpen(false);
    });
  }, [])

  
  window['blockLink'] = function(name, id = null, data = null) {
    mixpanel.track('Dynamic Link', {
      name,
      id,
      data
    });

    if (data != null) {
      try {
        data = JSON.parse(data);
      } catch(err) {
        console.error('block link', err);
      }
    } else {
      data = {
        id : id
      };
    }

    if (name == 'collection') {
      history.push('/collection/' + id, id);
    } else if (name == 'product') {
      // history.push('/product-view/' + id, data);
      setProductData(data);
      setProductModalOpen(true);
    } else if (name == 'products') {
      history.push('/products', data);
    } else {
      // history.push('/collection/' + data, data);
      return;
    }
  }  

  const handleProductViewDismiss = () => {
    setProductModalOpen(false);
    setProductData(null);
  }

  return (
    <>
      <SlideRoutes pathList={Helper.getRoutePaths()} location={location}>
        {Routes.map(({ tab, guard, ...props }, key) =>
          // guard ? (
          //   <GuardedRoute key={key} exact guard={guard} auth={auth} {...props} />
          // ) : (
            
          // )
          <Route key={key} exact {...props} />
        )}
      </SlideRoutes>
      <IonModal
        onDismiss={() => handleProductViewDismiss()}
        isOpen={productModalOpen}
      >

        <div style={{padding : '20px', height : '500px'}}>
          <ProductView productData={productData} onDismiss={handleProductViewDismiss}/>
        </div>
      </IonModal>
      {/* <ProductModal isOpen={productModalOpen}></ProductModal> */}
      <AppTabs />
    </>
  );
};

export default connect(mapStateToProps, null)(AppRouter);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth }) {
  return {
    auth,
  };
}
