export const SET_COLLECTION = 'SET_COLLECTION';
export const RESET_CACHE = 'RESET_CACHE';


export default class CollectionAction {
  /**
   * Set cache action.
   * @param {Object} data
   */
  static setCollection(data) {
    return {
      type: SET_COLLECTION,
      data,
    };
  }

  /**
   * Reset cache action.
   * @param {Object} data
   */
  static resetCache() {
    return {
      type: RESET_CACHE,
    };
  }
}
