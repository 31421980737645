import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './Locations.scss';

import { withNamespaces } from 'react-i18next';
import { Page } from 'components';
import { IonIcon, IonLoading } from '@ionic/react';
import { shopFlat } from 'components/Icons';
import { Link } from 'react-router-dom';
import AppService from 'services/app.service';
import Helper from 'Helper';
import AuthAction from 'store/actions/auth';
import CartAction from 'store/actions/cart';

class Locations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      stores: [],
    };

    this.service = new AppService();
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { auth } = this.props;
    const user = auth.user;

    let location = null;

    const userLocation = await this.service.getUserLocation(user.id);
    
    let firstUserLocationId = null;
    if (userLocation != null) {
      firstUserLocationId = userLocation['id'];
    }

    const state = this.props.history.location.state;

    if (state) {
      location = state.location;
      this.service.setUserLocation(user.id, firstUserLocationId, {
        geolocation: location,
        label: state.label,
      });
    } else if (
      userLocation &&
      userLocation.geolocation &&
      Object.keys(userLocation.geolocation).length
    ) {
      location = userLocation.geolocation;
    } else {
      return this.props.history.push(Helper.getRoute('find-location'));
    }

    this.service.getStoresByLocation(location).then((stores) => {
      this.setState({ stores, loading: false });
    });
  }

  /**
   * Handles select.
   * @param {Object} store
   */
  handleSelect(store) {
    const { setStore, initCart, cart, auth, history } = this.props;

    if (!store.delivery_times) {
      alert('Store is not open at the moment.');
      return;
    }

    const cartExists = () => {
      try {
        return cart[auth.user.id][store.id];
      } catch (e) {
        return false;
      }
    };

    if (!cartExists()) {
      initCart({ ...auth, store });
    }

    setStore(store);

    history.push(Helper.getRoute('store'));
  }

  render() {
    const { stores, loading } = this.state;
    const { t } = this.props;

    /**
     * Item content.
     */
    const Content = ({
      name,
      icon,
      min_order,
      delivery_times,
      distance,
      isLocation,
      children,
    }) => (
      <>
        <div className="icon">
          <IonIcon icon={icon} />
        </div>
        {isLocation && (
          <div>
            <h2>{name}</h2>
            <div className="attrs">
              {delivery_times ? (
                <>
                  <p>
                    AM: {delivery_times.am} | PM: {delivery_times.pm}
                  </p>
                  <p>
                    <span>{t('Minimum')} :</span> {Helper.amountFormat({amount: min_order})}
                  </p>
                  <p>
                    <span>{t('Distance')} :</span> {distance} meter
                  </p>
                </>
              ) : (
                <p>Closed</p>
              )}
            </div>
          </div>
        )}
        {children}
      </>
    );

    /**
     * Item options.
     */
    const Options = ({ isDefault }) => (
      <div className="options">
        <button className={classNames('text', isDefault ? 'default' : '')}>
          {isDefault ? t('Default') : t('Make default')}
        </button>
      </div>
    );

    return (
      <Page className="location-page">
        <IonLoading cssClass="gs-loading" mode="ios" isOpen={loading} />
        {!loading && stores.length > 0 && <h3>{t('Stores near you')}</h3>}
        <div className="locations">
          {stores.map((store, i) => (
            <div key={i} className="item">
              {false && <Options {...store} />}
              <div onClick={() => this.handleSelect(store)}>
                <Content isLocation icon={shopFlat} {...store} />
              </div>
            </div>
          ))}
          <div style={{ marginTop: 40, textAlign: 'center' }}>
            {!loading && stores.length <= 0 && (
              <span>
                {t('No store was found in your current location.')}
                <br />
              </span>
            )}
            {!loading && (
              <Link to={Helper.getRoute('find-location')}>{t('Change your location')}</Link>
            )}
          </div>
        </div>
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Locations));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, cart, select }) {
  return {
    auth,
    cart,
    history: select.history,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setStore: (data) => dispatch(AuthAction.setStore(data)),
    initCart: (auth) => dispatch(CartAction.emptyCart(auth)),
  };
}
