import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Checkbox.scss';
import { IonToggle } from '@ionic/react';

/**
 * Checkbox is used to create a checkable interactive control for web-based forms.
 */
const Checkbox = ({ id, className, onChange, value, disabled }) => {
  const handleChange = (e) => (!onChange ? null : onChange(e.target.checked));
  return (
    <div className={classNames('checkbox', className)}>
      <IonToggle
        id={id}
        mode="ios"
        onIonChange={handleChange}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  id: 'my-checkbox',
  value: false,
  className: '',
  label: '',
};

export default Checkbox;
