// import { db, firebase } from './firebase/firebase';
import moment from 'moment';
import Helper from 'Helper';
import i18next from 'i18next';
import hash from 'custom-hash';
import axios from 'axios';
import Http from 'libs/http';

hash.configure({ charSet: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'], maxLength: 5 });

export default class AppService {
  constructor() {
    this._favs = [];
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    };

    this.http = new Http();
  }

  async editFavorite(auth, product_id, action) {
    
  }

  async loadCollections(id) {
    return new Promise(async (resolve, reject) => {
      
    });
  }

  /**
   * Fetch all categories.
   * @return {Array}
   */
  async getCategories() {
    return new Promise(async (resolve, reject) => {
      return this.http.get('categories', {})
        .then(res => {
          console.log('get categories data', res);
          let categories = [];
          res.data.forEach(cat => {
            cat['sub'] = [];
            categories.push(cat);
          })
          resolve(categories);
        })
        .catch(err => {
          reject(err);
        })
    });
  }

  /**
   * Fetch categories.
   * @return {Array}
   */
  async getExploreCategories() {
    return new Promise(async (resolve, reject) => {
      return this.http.post('category/explore', {})
        .then((data) => {
          const { categories } = data;
          resolve(categories);
        })
        .catch(err => {
          reject(err);
        })
    });
  }

  async search(query) {
    return new Promise((resolve, reject) => {
      this.http.post('products/search', {
        name : query
      })
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          return reject('error');
        });
    })
  }

  /**
   * Fetch stores by user location.
   * @param {Object} latLon Google map latitube & longitube
   */
  async getStoresByLocation(latLon) {
    // const result = [];
    // // const query = db.collection('stores');
    // const stores = await query.get();

    // /**
    //  * Get delivery time.
    //  * @param {Object} d
    //  */
    // const deliveryTime = (d) => {
    //   if (!d) {
    //     return null;
    //   }
    //   const date = new Date();
    //   const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    //   const day = days[date.getDay()];
    //   return d[day] || null;
    // };

    // for (const row of stores.docs) {
    //   const data = row.data();

    //   if (data.geolocation) {
    //     const distance = Helper.getDistance(latLon, data.geolocation);
    //     if (distance < process.env.REACT_APP_MAX_DISTANCE || data['is_global'] === true) {
    //       result.push({
    //         id: row.id,
    //         ...data,
    //         distance,
    //         delivery_times: deliveryTime(data.delivery_times),
    //       });
    //     }
    //   }
    // }

    // return result;
  }

  /**
   * Update user profile.
   * @param {Object} auth
   * @param {Object} data
   */
  async updateProfile(auth, data) {
    
  }

  /**
   * Upload file.
   * @param {File} file
   */
  async uploadFile(file) {
    // return new Promise(async (resolve, reject) => {
    //   const allowedTypes = ['image/x-png', 'image/png', 'image/gif', 'image/jpeg'];

    //   if (allowedTypes.indexOf(file.type) < 0) {
    //     return reject('Only images are allowed.');
    //   }

    //   const date = new Date();
    //   const filename = `${date.getTime()}-${file.name.replace(/\s|\+|\(|\)/g, '')}`;

    //   const storageRef = firebase.storage().ref();
    //   const mountainImagesRef = storageRef.child(`images/${filename}`);

    //   mountainImagesRef.put(file).then(resolve).catch(reject);
    // });
  }

  /**
   * Get locations collection.
   * @param {String} userId
   */
  getLocations(userId) {
    // return db.collection('users').doc(userId).collection('locations');
  }
}
