import {
  ADD_TO_FAV,
  REMOVE_FROM_FAV
} from '../actions/fav';

const initialState = {
  items: [],
  total: 0,
};

const fav = (stateData = initialState, action) => {
  const product = action.data;
  const auth = action.auth;

  const getState = () => {
    try {
      return stateData;
    } catch (e) {
      return initialState;
    }
  };

  const state = getState();

  switch (action.type) {
    case ADD_TO_FAV:
      return {
        items: [...state.items, action.data],
        // total: state.total + 1,
      };

    case REMOVE_FROM_FAV:
      console.log('typed', action.data)
      return {
        items: state.items.filter((item) => {
          if (item !== action.data) {
            return item;
          }
          return false;
        }),
        // total: state.total - 1,
      };
      
    default:
      return stateData;
  }
};

export default fav;
