import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import './Login.scss';
import {
  IonItem,
  IonLabel,
  IonText
} from '@ionic/react';
import { withNamespaces } from 'react-i18next';

import { Modal } from 'components';
import { Input, Button } from 'components/Form';
import AuthService from 'services/auth.service';

import AuthAction from 'store/actions/auth';

const authService = new AuthService();

/**
 * A page container for the app.
 */
const LoginModal = ({
  children,
  t,
  line,
  className,
  auth,
  ...props
}) => {

  const [loginForm, setLoginForm] = useState({
    id : '',
    name : '',
    phone : '',
    code : ''
  });

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verifyStep, setVerifyStep] = useState(false);
  
  const [state, setState] = useState({
    modalOpen : false,
  });

  /**
   * Handles form submit.
   * @param {Object} e
   */
  const handleLogin = (e) => {
    e.preventDefault();

    const { name, phone } = loginForm;
    let fullNumber = phone;
    if (!name || !phone) {
      setAlert(t('Please fill all fields.'));
      return;
    }

    if(!(phone.match(/^[05][0-9]{8,9}$/) && ((phone.length == 9 && phone.startsWith('5')) || (phone.length == 10 && phone.startsWith('0'))))){
      setAlert(t('Phone number is not valid'));
      return;
    }
    if(phone.length === 9){
      fullNumber = '0' + phone
    }

    if (!name || !phone) {
      setAlert(t('Please fill all fields.'));
      return;
    }

    if(!loading){
      setAlert(null);
      setLoading(true);
      authService
        .signIn({
          name,
          phone: fullNumber,
          mobile: 2,
        })
        .then((res) => {
          setLoading(false);
          setVerifyStep(true);
          setLoginForm({ ...loginForm, user_id : res.user_id })
          if (!res.result) {
            return setAlert(null);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }

  }

  /**
   * Handle resend button.
   */
  const handleResend = (e) => {
    e.preventDefault();

    const { name, phone } = loginForm;
    setAlert(null);
    authService
        .signUp({
          name: name,
          phone: phone,
          resend: true,
        })
        .then((res) => {
            if (!res.success) {
                // return this.setState({ resending: false, alert: res.message });
            }

            // this.setState({ sentCode: true, resending: false });
        })
        .catch((err) => {
            // this.setState({ resending: false, alert: 'Something went wrong, please try again.' });
            console.error(err);
        });
  }

  /**
   * Handles submit button.
   */
  const handleVerify = (e) => {
      e.preventDefault();

      // const { code } = loginForm;

      const { code, phone } = loginForm;

      if (!code) {
        return setAlert(t('Please enter the code.'));
      }

      setLoading(true);
      setAlert(null);

      authService
          .signIn({
            code,
            phone,
          })
          .then((data) => {
            const { error, message, user, access_token } = data;
            setLoading(false);
            if (error) {
              setAlert(message)
              return
            }
              props.setUser({ ...user, access_token });
              props.onDismiss();
          })
          .catch((err) => {
            setLoading(false);
            setAlert(err.message);
            console.error(err);
          });
  }

  return (
    <>
      <Modal {...props} className="login-modal" style="background:#fff">
        <div className="group" style={{ padding: '0 10px'}}>
          
          { verifyStep == false ? (
            <form onSubmit={handleLogin}>
              <IonItem lines="none">
                  <IonLabel className="head ion-text-wrap">
                      <IonText color="primary">
                          <h1>{t('Mobile number verification')}</h1>
                      </IonText>
                      <p>{t('Please enter your full name and phone number to send the verification code')}</p>
                  </IonLabel>
              </IonItem>
              {alert !== null && <div className="form-control alert alert-danger">{alert}</div>}
              <IonItem lines="none">
                  <Input
                    name="full_name"
                    autofocus
                    value={loginForm.name}
                    onChange={(name) => setLoginForm({...loginForm, name : name})}
                    placeholder={t('Full name')}
                  />
              </IonItem>
              <IonItem lines="none">
                  {/* <Select
                      disabled
                      columns={[
                          {
                              name: 'phone_number',
                              selectedIndex: 0,
                              options: [{ text: '+966', value: '966' }],
                          },
                      ]}
                  /> */}
                  <Input className="phone-number"
                      type="tel"
                      value={loginForm.phone}
                      onChange={(phone) => setLoginForm({...loginForm, phone})}
                      placeholder={t('5xxxxxxxx')}
                  />
              </IonItem>
              <IonItem lines="none">
                  <Button type="submit" loading={loading} full large>
                      {t('Send the code')}
                  </Button>
              </IonItem>
          </form>
          ) : (
            <form onSubmit={handleVerify}>
              <IonItem lines="none">
                  <IonLabel className="head ion-text-wrap">
                      <IonText color="primary">
                          <h1>{t('Mobile number verification')}</h1>
                      </IonText>
                      <p>{t('Please enter your full name and phone number to send the verification code')}</p>
                  </IonLabel>
              </IonItem>
              {alert !== null && <div className="form-control alert alert-danger">{alert}</div>}
              <IonItem lines="none" className="verification-code-item">
                  <Input
                      type="number"
                      value={loginForm.code}
                      onChange={(code) => setLoginForm({...loginForm, code})}
                      placeholder={t('كود التفعيل')}
                  />
              </IonItem>
              <IonItem lines="none">
                  <Button type="submit" loading={loading} full large>
                    {t('تأكيد')}
                  </Button>
              </IonItem>
              <IonItem lines="none">
                  <div onClick={() => setVerifyStep(false)} style={{ fontSize: '14px', color: '#666' }}>
                    {t('الرجوع')}
                  </div>
              </IonItem>
          </form>
          )}
        </div>
      </Modal>
    </>
  );
};

LoginModal.propTypes = {
  
};

LoginModal.defaultProps = {
  isOpen: false,
  title : '',
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(LoginModal));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, select }) {

  return {
    auth : auth,
    history: select.history,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
      setUser: (data) => dispatch(AuthAction.setUser(data)),
  };
}
