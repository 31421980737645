import { isPlatform } from '@ionic/react';

export default class ProductsHelper {
  static async stickySubCategpry(e, container) {
    if (container) {
      const scroll = await container.getScrollElement();
      const top = parseFloat(
        scroll.style.transform.replace(/(.*)translateY\((.*?)px\)(.*)/g, '$2')
      );

      if (top > 0) {
        e.style.top = '0px';
      } else {
        e.style.top = '82px';
      }
    }
  }

  static fixStickyAnimation(e, refreshing) {
    if (e && !isPlatform('ios')) {
      if (!refreshing) {
        e.style.top = '82px';
      }
    }
  }

  static getDiscountedPrice({price, discount, discount_type}) {
    let discountedPrice = price
    let percentOff;
    
    if(discount_type === 'amount' && discount > 0){
      discountedPrice = price - discount
      percentOff = Math.round(( discount / price ) * 100)
    }
    else if (discount_type === 'percent' && discount > 0){
      discountedPrice = price - Math.round(( discount * price ) / 100)
      percentOff = discount
    }

    return { discountedPrice, percentOff }
  }
}
