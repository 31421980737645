import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './FavButton.scss';
import { IonItem, IonLabel, IonText } from '@ionic/react';
import ReactImageAppear from 'react-image-appear';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/Form';
import { cartOutline } from 'ionicons/icons';
import { withNamespaces } from 'react-i18next';
import Helper from 'Helper';
import i18next from 'i18next';

import { cartMapStateToProps, cartMapDispatchToProps } from 'store/maps/cart';
import SelectAction from 'store/actions/select';
import FavAction from 'store/actions/fav';
import { noImageFound } from 'components/Icons';
import AppService from 'services/app.service';
import { IonIcon } from '@ionic/react';
import { heartOutline, heart } from 'ionicons/icons';

/**
 * Product Item.
 */
const FavButton = ({ children, product, favList, loading, onClick, auth, inCart, t, ...props }) => {
  const [cart, setCart] = useState(inCart);
  const history = useHistory();

  if (loading) {
    return <IonItem lines="none" className="product-item loading" />;
  }

  const service = new AppService();

  /**
   * Handle redirect to product page.
   * @param {Element} e
   */
  const goToProduct = (e) => {
    e.preventDefault();
    service.editFavorite(auth, product.id, 'add')
      .then(res => {
        props.addToFav(
          product.id,
          auth
        );
      })
  };

  const removeFromNav = (e) => {
    e.preventDefault();
    service.editFavorite(auth, product.id, 'remove')
      .then(res => {
        props.removeFromFav(
          product.id,
          auth
        );
      })
    
  };

  const { name, price, img: image } = product;
  
  let isInFav = false;
  let Button = '';
  console.log('favList', favList);
  favList.items.forEach(item => {
    if (item == product.id) {
      isInFav = true;
    }
  })

  if ( isInFav ) {
    Button = (
      <button className="btn raduis add-to-fav remove" onClick={removeFromNav} >
        <IonIcon icon={heart}></IonIcon>
      </button>
    );
  } else {
    Button = (
      <button className="btn raduis default add-to-fav" onClick={goToProduct} >
        <IonIcon icon={heartOutline}></IonIcon>
      </button>
    );
  }

  return (
    product && (
      <div className="fav-button" onClick={onClick}>
        { Button }
      </div>
    )
  );
};

FavButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  product: PropTypes.shape({
    name: PropTypes.shape({
      en: PropTypes.string,
      ar: PropTypes.string,
    }),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.string,
    img: PropTypes.string,
    quantity: PropTypes.number,
  }),
};

FavButton.defaultProps = {
  product: null,
  loading: false,
};

export default connect(cartMapStateToProps, mapDispatchToProps)(withNamespaces()(FavButton));

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    addToFav: (data) => dispatch(FavAction.addToFav(data)),
    removeFromFav: (data) => dispatch(FavAction.removeFromFav(data)),
    setProduct: (data) => dispatch(SelectAction.setProduct(data)),
    ...cartMapDispatchToProps(dispatch),
  };
}


// /**
//  * Map the store's state to the component's props
//  * @param  {Object} state
//  * @return {Object}
//  */
// export function mapStateToProps({ fav }) {
//   return {
//     fav
//   };
// }
