import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IonRow, IonCol, IonSlides, IonSlide } from '@ionic/react';
import { Link } from 'react-router-dom';

import './Banner.scss';

/**
 * Dynamic Banners.
 */
export const Banner = ({ banners, slide }) => {
  const Col = ({ imageUrl, href }) => (
    // <Link to={href}>
      <div className="img" style={{ backgroundImage: 'url(' + imageUrl + ')' }} />
    // </Link>
  );

  const Slider = () => (
    <IonSlides options={{ autoplay: true, loop: true }} mode="ios" pager={true}>
      {banners.map((l, i) => (
        <IonSlide key={i}>
          <Col {...l} />
        </IonSlide>
      ))}
    </IonSlides>
  );

  return (
    <div className={classNames('hero-banner', slide && 'slide')}>
      <IonRow>
        {slide ? (
          <Slider />
        ) : (
          banners.map((l, i) => (
            <IonCol key={i}>
              <Col {...l} />
            </IonCol>
          ))
        )}
      </IonRow>
    </div>
  );
};

Banner.propTypes = {
  banners: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      img: PropTypes.string,
    })
  ),
  slide: PropTypes.bool,
};

Banner.defaultProps = {
  slide: false,
  banners: [],
};

export default Banner;
