import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './ProductItem.scss';
import { IonItem, IonLabel, IonText, IonGrid,
  IonRow,
  IonCol, 
  IonIcon} from '@ionic/react';
import ReactImageAppear from 'react-image-appear';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/Form';
import { cartOutline, trashOutline, removeOutline, addOutline } from 'ionicons/icons';
import { withNamespaces } from 'react-i18next';
import Helper from 'Helper';
import i18next from 'i18next';
import { cartMapStateToProps, cartMapDispatchToProps } from 'store/maps/cart';
import SelectAction from 'store/actions/select';
import { noImageFound } from 'components/Icons';

import { cdnImage, amountFormat } from 'libs/pipes';

/**
 * Product Item.
 */
const ProductItem = ({ children, product, loading, onClick, auth, inCart, t, handleSelect, ...props }) => {
  const [cart, setCart] = useState(inCart);
  const history = useHistory();

  /**
   * Listen to cart quantity changes.
   */
  useEffect(() => {
    if (cart !== inCart) {
      if (!inCart) {
        // Add to cart
        props.addToCart({...product, quantity : 1}, auth);
      } else {
        if (cart > inCart) {
          // Add quantity
          props.addQuantity(product, auth);
        } else if (cart > 0) {
          // Sub. quantity
          props.subQuantity(product, auth);
        } else {
          // Remove from cart
          props.removeFromCart(product, auth);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  if (loading) {
    return <IonItem lines="none" className="product-item loading" {...props} />;
  }


  /**
   * Handle redirect to product page.
   * @param {Element} e
   */
  const goToProduct = (e) => {
    e.preventDefault();
    props.setProduct(product);
    history.push(Helper.getRoute('product'));
  };

  // const navToLogin = () => {
  //   history.push(Helper.getRoute('login'));
  // }

  let isGuest = false;
  if (auth != null && auth['user'] != null) {
    if (auth.user['isGuest'] == true) {
      isGuest = true;
    }
  }

  const { name, price, image_url } = product;

  return (
    product && (
      <div className="product-item product-item-design-2">
        
        <div className='product-item-content'>
          <div className='product-item-image'>
              <ReactImageAppear
                className="flex-none"
                loaderClass="loader"
                animation="none"
                showLoader
                src={image_url || noImageFound}
              />
            </div>
          <div className="info product-item-info" onClick={handleSelect}>

            <div className="min-w-0 flex-auto">
              <p className="name">{name}</p>
              <p className="price product-price">
                {Helper.amountFormat({ amount: price })}
              </p>
            </div>
          </div>
          <div className="actions">
            {cart <= 0 && (
              <div>
                <Button
                  icon={cartOutline}
                  onClick={() => {setCart(1)}}
                  variant="outline"
                  small
                  raduis={false}
                  full
                  track={['Add to cart', {}]}
                >
                  {t('Add to cart')}
                </Button>
              </div>
            )}
            {cart > 0 && (
              <div className="add-remove">
                <IonGrid>
                  <IonRow>
                    <IonCol size="4">
                      <Button className={'btn-add-remove'} onClick={() => setCart(cart + 1)} track="Cart Change - plus">
                        <IonIcon className='trash-icon' icon={addOutline} />
                      </Button>
                    </IonCol>
                    <IonCol size="4"><span className="quantity">{cart}</span></IonCol>
                    <IonCol size="4">
                    <Button
                      className={'btn-add-remove'} onClick={() => cart && setCart(cart - 1)} track="Cart Change - minus">
                        {cart != 1 ? (
                          <>
                            <IonIcon className='trash-icon' icon={removeOutline} />
                          </>
                        ) : (
                          <>
                            <IonIcon className='trash-icon' icon={trashOutline} />
                          </>
                        )}
                      
                    </Button>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

ProductItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  product: PropTypes.shape({
    // name: PropTypes.shape({
    //   en: PropTypes.string,
    //   ar: PropTypes.string,
    // }),
    name : PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.string,
    img: PropTypes.string,
    image : PropTypes.string,
    quantity: PropTypes.number,
  }),
};

ProductItem.defaultProps = {
  product: null,
  loading: false,
};

export default connect(cartMapStateToProps, mapDispatchToProps)(withNamespaces()(ProductItem));

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setProduct: (data) => dispatch(SelectAction.setProduct(data)),
    ...cartMapDispatchToProps(dispatch),
  };
}
