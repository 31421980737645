import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as smoothscroll from 'smoothscroll-polyfill';
import $ from 'jquery';

import {
  isPlatform,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonSlides,
  IonSlide,
  IonFooter,
  IonTitle,
  IonToast,
  IonButton,
  IonListHeader,
  IonPopover
} from '@ionic/react';

import './ProductView.scss';
import { Page, Modal } from 'components';
import ReactImageAppear from 'react-image-appear';
import { rollingLoading } from 'components/Icons';

import {
  IonSegment,
  IonSegmentButton,
  IonContent,
  IonList,
  IonLabel,
  IonToolbar,
} from '@ionic/react';

import Helper from 'Helper';
import { funnelOutline, addOutline, cloudUploadOutline, checkmarkCircleOutline } from 'ionicons/icons';
import ViewCart from 'components/ViewCart/ViewCart';
import BlockBuilder from 'components/BlockBuilder/BlockBuilder';
import { Radio, Button, Input } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import ProductItem from 'components/Customs/ProductItem/ProductItem';
import AppService from 'services/app.service';
import ProductService from 'services/product.service';

import Loading from 'components/Customs/Loading/Loading';

import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { byStats } from '../Store/StoreData';
import SelectAction from 'store/actions/select';
import CartAction from 'store/actions/cart';

import Http from 'libs/http';
import { db } from 'services/firebase/firebase';

smoothscroll.polyfill();

class ProductView extends Component {
  constructor(props) {
    super(props);

    // const { id } = this.props.history.location.state;
    const id = 12;
    console.log('product id ', props);
    // const product_id = 2157;
    
    this.http = new Http;
    
    this.state = {
      showAddToCartToast : false,

      addToCartButtonLoading : false,

      product_id : id,
      categories: [],
      category: {},
      products: [],
      blocks : [],
      categoryType: 'MAIN',
      subCategory: 0,
      orderBy: '1',
      customImg: null,
      uploadedFile: null,

      productName: null,
      productDescription: null,

      productAddedToCart : false,

      refresher: false,
      refreshing: false,
      addingProduct: false,
      loading: true,
      productLoaded : false,
      uploading: false,
      sort: false,
      html : '',
      product : null,
      quantity : 1,
      variation : null,
      toast : {
        show : false,
        message : ""
      },
      popoverQuantityState : { showPopover: false, event: undefined }
    };

    this.container = null;
    this.categoryRef = null;
    this.uploadImageRef = null;
    this.refresher = null;
    this.subCategoryRef = null;
    this.subCategoryItemsRef = null;
    this.timeoutRef = null;

    this.service = new AppService();
    this.productService = new ProductService();

    this.handleScrollEvent = this.handleScrollEvent.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);

  }

  get categories() {
    // const { categories, category } = this.state;
    // return categories.find((c) => c.id === category.id);
  }

  /**
   * Handles cart changes.
   * @param {Number} q
   */
  // handleChangeCart(q) {
  //   if (!cart) {
  //     // Add to cart
  //     props.addToCart(product, auth);
  //   } else {
  //     if (q > cart) {
  //       // Add quantity
  //       props.addQuantity(product, auth);
  //     } else if (cart > q && q > 0) {
  //       // Sub. quantity
  //       props.subQuantity(product, auth);
  //     } else {
  //       // Remove from cart
  //       props.removeFromCart(product, auth);
  //     }
  //   }

  //   setCart(q);
  // };

  /**
   * On init
   */
  async componentDidMount() { 
    if (this.state.productLoaded == false) {
      this.getProduct();
    }
  }

  /**
   * On unload
   */
  async componentWillUnmount() {
    window.removeEventListener('beforeunload', () => {});
  }

  /**
   * On state update.
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(pp, ps) {
    
  }

  handleQuantityChange(e) {
    const { product } = this.state;

    if (product.quantity == 0) {
      e = 1;
      this.setState({ 
        toast : {
          show : true,
          message : 'للاسف هذا المنتج نفد من المخزون'
        }
      });
      
    } else if (product.quantity < e) {
      e = product.quantity;
      this.setState({ 
        toast : {
          show : true,
          message : ` للاسف لا يمكنك طلب اكثر من ${product.quantity} `
        }
      });
      
    }

    this.setState({
      quantity : e,
      popoverQuantityState : { showPopover: false, event: undefined }
    });
  }

  getProduct() {
    const self = this;
    const { product_id } = this.state;
    this.productService.getProductWithView(product_id)
      .then(data => {
        let { html, product } = data;

        let triggerHooked = false;
        this.setState({ html, product, productLoaded : true }, () => {
          
          if (triggerHooked == false) {
            triggerHooked = true;
            window.eval(`new Swiper('.swiper-container', {
              loop: true,
              pagination: {
                el: '.swiper-pagination',
              }
            });`);

            $("#option-choice-form input").change((e) => {
              let val = $(e.target).val();
              self.handleVariationChange();
            });

          }
        });

      })
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * Handle auto scroll for categories.
   */
  categoryScroll(ref = this.categoryRef) {
    if (ref) {
      Helper.scrollTo(ref, 'left');
    }
  }


  /**
   * Handles the selected category align.
   */
  handleSubCategoryChange(e, value) {
    if (e != null) {
      e.preventDefault();  
    }
    this.setState({ subCategory: value }); 
  }

  /**
   * Handles scroll event.
   * @param {Event} e
   */
  handleScrollEvent(e) {
    const { refresher } = this.state;
    const top = e.detail.scrollTop;

    // if (isPlatform('ios')) {
    if (this.subCategoryRef) {
      if (top > 0) {
        this.subCategoryRef.style.position = 'fixed';
      } else {
        this.subCategoryRef.style.position = 'absolute';
      }
    }

    if (top < 0 && Math.abs(top) >= 120 && !refresher) {
      this.handleRefresh();
      this.setState({ refresher: true });
    }
    // }
  }

  /**
   * Handles on refresh event.
   */
  handleRefresh() {
    if (!isPlatform('ios')) {
      // this.subCategoryRef.style.top = '0px';
    }

    this.setState({ refreshing: true });
  }

  async handleCustomProductDismiss() {
    const { productAddedToCart, addToCartButtonLoading } = this.state;
    this.setState({ productAddedToCart: !productAddedToCart });
    // await this.handleCancelProduct();
  }

  async handleVariationChange() {
    const { product } = this.state;
    const self = this;
    let productParams = {};
    $( "#option-choice-form" )
      .serializeArray()
      .forEach(param => {
        productParams[param.name] = param.value;
        // if (param.name != 'id' && param.name != 'quantity') {
        //   variation += '-' + param.value; 
        // }
      });
    
    this.http.post('products/variant_price', productParams)
    .then(res => {
      console.log('variant_price', res);
      if ("product_id" in res) {
        console.log('product variation loaded');
        $('.product-price-primary').html(res.price_string);
        // let price = Number(res.price.replace('ريال', '').replace(',', ''));
        let price = res.price;
        self.setState({
          product : { ...product, price, variation : res.variation },
          variation : res.variation,
          product : {...product, quantity : res.quantity}
        }, () => {
          console.log('variant has been updated')
        });
      }
    })
    .catch(err => {
      console.log('variant_price', err);
    })
    
  }

  async handleAddToCart() {
    const { cart } = this.props;

    const { product, quantity, variation, addToCartButtonLoading, productAddedToCart } = this.state;

    if (product.quantity == 0) {
      this.setState({ 
        toast : {
          show : true,
          message : "للاسف هذا المنتج نفذت كميته"
        }
      });
      
      return;
    }


    let productCartData = {
      id : product['id'],
      name : product['name'],
      image : product['image'],
      price : product['price'],
      quantity : quantity,
      // variant : productParams,
      variation : variation
    };
    
    
    console.log('product added to cart', productCartData);
    // for(let i = 0; i < cart.items; i++) {
    //   if (cart.items[i]['id'] == product.id) {
    //     this.props.addToCart(productCartData, {});
    //     break;
    //   }
    // }

    this.setState({
      addToCartButtonLoading : true
    });

    setTimeout(() => {
      this.props.addToCart(productCartData, {});
    
      this.setState({ productAddedToCart: !productAddedToCart, addToCartButtonLoading : false });
    }, 500);
  }

  navToCart() {
    const { history } = this.props;

    history.push(Helper.getRoute('cart'));
  }

  // handleQuantityChange(e) {
  //   // if (!cart) {
  //   //   // Add to cart
  //   //   props.addToCart(product, auth);
  //   // } else {
  //   //   if (q > cart) {
  //   //     // Add quantity
  //   //     props.addQuantity(product, auth);
  //   //   } else if (cart > q && q > 0) {
  //   //     // Sub. quantity
  //   //     props.subQuantity(product, auth);
  //   //   } else {
  //   //     // Remove from cart
  //   //     props.removeFromCart(product, auth);
  //   //   }
  //   // }
  // }

  /**
   * Render the component.
   */
  render() {
    const { t } = this.props;
    const {
      html,
      product,
      category,
      subCategory,
      sort,
      orderBy,
      loading,
      addingProduct,
      customImg,
      uploading,
      categories,
      products,
      blocks,
      showAddToCartToast,
      productAddedToCart,
      productLoaded,
      addToCartButtonLoading,
      popoverQuantityState,
      toast
    } = this.state;

    if (!categories) {
      return <Redirect to={Helper.getRoute('home')} />;
    }

    /**
     * Loading animation
     */
    // const Loading = () => (
    //   <IonList className="product-list loading">
    //     <IonGrid className="gs-products-grid">
    //       <IonRow>
    //         {[0, 1, 2, 3, 4, 5, 6].map((e) => (
    //           <IonCol key={String(e)} size="6">
    //             <ProductItem loading />
    //           </IonCol>
    //         ))}
    //       </IonRow>
    //     </IonGrid>
    //   </IonList>
    // );

    const PopoverQuantityList = () => {
      const { popoverQuantityState } = this.state;
      return (
        <IonPopover
          cssClass='popover-quantity-list'
          event={popoverQuantityState.event}
          isOpen={popoverQuantityState.showPopover}
          onDidDismiss={() => 
            this.setState({popoverQuantityState : { showPopover: false, event: undefined }})}
        >
          <IonList>
            <IonListHeader>اختر الكمية</IonListHeader>
            <IonItem button onClick={() => {
              //  this.setState({quantity : 1}) 
              this.handleQuantityChange(1) 
            }}>1</IonItem>
            <IonItem button onClick={() => {
              //  this.setState({quantity : 2}) 
              this.handleQuantityChange(2) 
            }}>2</IonItem>
            {/* <IonItem button onClick={() => {
              //  this.setState({quantity : 3}) 
              this.handleQuantityChange(3) 
            }}>3</IonItem>
            <IonItem button onClick={() => {
              //  this.setState({quantity : 4}) 
              this.handleQuantityChange(4) 
            }}>4</IonItem> */}
          </IonList>
        </IonPopover>

      )
    }

    return (
      <>
        <Page
          withPage={false}
          backButton
          hasSearch
          // title={t('Groups')}
          className="product-view-page"
        >
          <IonContent
            mode="md"
            scrollEvents
            onIonScrollStart={this.handleScrollEvent}
            onIonScroll={this.handleScrollEvent}
            class="fixed-sub-category not-pulling"
            ref={(ref) => (this.container = ref)}
          >

            {productLoaded == false ? (
              <div style={{ padding : '60px 0', 'textAlign' : 'center' }}>
                <Loading></Loading>
              </div>
            ) : (
              <>
                <div id="product-view-container" dangerouslySetInnerHTML={{__html: html}}></div>
                <IonFooter className="add-to-cart-button-container ion-no-border"
                  vertical="bottom" horizontal="end" slot="fixed"
                >
                  <div className="add-to-cart-button-content">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="8" onClick={() => this.handleAddToCart()}>

                          <div className="btn-add-to-cart">
                            { addToCartButtonLoading ? <span><IonIcon icon={rollingLoading}></IonIcon></span> : 'أضف لسلة' }
                          </div>
                          
                        </IonCol>
                        <IonCol size="4">
                          <PopoverQuantityList></PopoverQuantityList>
                          <div className="btn-quantity"
                            onClick={(e) => {
                              e.persist();
                              this.setState({ popoverQuantityState : {showPopover: true, event: e} })
                            }}
                          >
                            { this.state.quantity }
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonFooter>
              </>
            )}

            <IonToast
              position="top"
              color="danger"
              isOpen={toast.show}
              onDidDismiss={() => this.setState({toast : { show : false, message : '' }})}
              message={toast.message}
              duration={800}
            />


          </IonContent>
        </Page>
        <Modal
            title={t('تم اضافة المنتج لسلة')}
            onDismiss={this.handleProductAddedToCartDismiss}
            isOpen={productAddedToCart}
          >
            <div className="group" style={{ padding: '0 20px' }}>
              <form onSubmit={this.handleAddCustomProduct}>

                <div className="text-center">
                  <img src="/assets/images/tick.svg" style={{ width : '90px', height : '90px' }} />
                </div>

                {/* <div className="form-control">
                  {t('The fee for this order is')} {Helper.amountFormat(2)}
                </div> */}
                <div className="form-control">
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonButton 
                          expand="block" 
                          color="light" 
                          onClick={() => { this.setState({productAddedToCart : !productAddedToCart}) }}
                        >إكمال التسوق</IonButton>
                      </IonCol>
                      <IonCol>
                      <IonButton 
                        expand="block" 
                        color="primary"
                        onClick={() => { this.navToCart() }}
                      >إتمام الشراء</IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </form>
            </div>
          </Modal>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(ProductView));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ cache, cart, auth, select }) {
  const { location } = createBrowserHistory();
  return {
    cart,
    cache,
    auth,
    history: select.history,
    category: select.category,
    location,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setCategory: (data) => dispatch(SelectAction.setCategory(data)),
    addToCart: (data, auth) => dispatch(CartAction.addToCart(data, auth)),
  };
}
