import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Orders.scss';
import { Page } from 'components';
import { withNamespaces } from 'react-i18next';
import {
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonChip,
  IonLoading,
  IonToolbar,
  IonSegment,
  IonSegmentButton
} from '@ionic/react';
import OrderService from 'services/order.service';
import SelectAction from 'store/actions/select';
import Helper from 'Helper';

import Http from 'libs/http';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.http = new Http;

    this.state = {
      openOrders : [],
      allOrders : [],
      
      // ['openOrders', 'allOrders'] 
      tab : 'openOrders',

      loading: true,
      orders: [],
    };

    this.service = new OrderService();

    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { auth } = this.props;
    const { user } = auth;
    console.log('auth', auth);
    if (user['isGuest'] != true) {
      this.getOrders();
    }
  }

  async getOrders() {
    console.log('retu getting orders')
    this.http.post('customer/orders/index')
    .then(data => {
      const { orders } = data;
      this.setState({ loading: false, orders});
    })
    .catch(err => {
      console.log('err', err);
    })
    // this.http.post('user/orders', {})
    //   .then(data => {
    //     const { orders } = data;
    //     // let openOrders = orders.filter((item) => {
    //     //   if (item.delivery_status === 'delivered' || item.delivery_status == 'cancelled') {
    //     //     return false;
    //     //   }

    //     //   return true;
    //     // })
        
    //     let allOrders = orders.map((item) => {
    //       return item;
    //     });

    //     console.log('open', orders);

    //     this.setState({ loading: false, orders, allOrders, openOrders : allOrders});
    //   })
    //   .catch(err => {
    //     console.error('err', err)
    //     this.setState({ loading: false });
    //   });
    // this.service
    //   .getOrdersByUser()
    //   .then((orders) => {
    //     let openOrders = orders.filter((item) => item.delivery_status === 'pending')
        
    //     let allOrders = orders.map((item) => {
    //       return item;
    //     });

    //     console.log('open', orders);

    //     this.setState({ loading: false, orders, allOrders, openOrders});
    //   })
    //   .catch((err) => {
    //     console.error('err', err)
    //     this.setState({ loading: false });
    //   });
  }

  /**
   * Order status.
   * @param {String} status
   */
  status(status) {
    // const { t } = this.props;

    // // if (!status) {
    // //   return { type: 'yello', label: t('Waiting') };
    // // }

    // // const statuses = {
    // //   IN_PROGRESS: { type: 'info', label: t('In Progress') },
    // //   DELIVERED: { type: 'success', label: t('Delivered') },
    // //   CANCELLED: { type: 'danger', label: t('Cancelled') },
    // // };

    return 'info';
  }

  /**
   * Handles select the order.
   * @param {Object} order
   */
  handleSelect(order) {
    this.props.setOrder(order);
    this.props.history.push(Helper.getRoute('order'));
  }

  navToSignin() {
    this.props.history.push(Helper.getRoute('signup'), {
      returnTo : Helper.getRoute('orders')
    });
  }

  /**
   * Render the component.
   */
  render() {
    const { orders, loading, tab, allOrders, openOrders } = this.state;
    const { t, auth } = this.props;
    
    let isGuest = false;
    if (auth != null && auth['user']['type'] == 'guest') {
      isGuest = true;
    }

    const DeliveryStages = {
      // https://storyset.com/illustration/confirmed/bro
      0 : {
        image : 'pending.svg',
        name : 'En attente',
      },
      // https://storyset.com/illustration/checking-boxes/amico
      1 : {
        image : 'confirmed.png',
        name : 'En préparation'
      },
      2 : {
        image : 'on_delivery.png',
        name : 'Prêt'
      },
      // https://storyset.com/illustration/delivery-address/amico
      3 : {
        image : 'on_delivery.png',
        name : 'à la livraison'
      },
      // https://storyset.com/illustration/messenger/pana
      4 : {
        image : 'delivered.svg',
        name : 'Livrée'
      },
      // https://storyset.com/illustration/cancelled-event/rafiki
      5 : {
        image : 'cancelled.svg',
        name : 'Annulé'
      }
    };

    const DeliveryStatusText = (status) => {
      console.log('ordre', status);
      let name = '';
      if (status != undefined || status != null) {
        name = DeliveryStages[status]['name'];
      }
      
  
      return name;
    }

    const DeliveryStatusImage = (order) => {
  
      const {image, name} = DeliveryStages[order.delivery_status];
  
      return (
        <img className="status-img" src={`assets/images/delivery-status/${image}`} />
      );
    }

    const OrderCard = (props) => {
      const { order, onClick } = props;
      return (
        <IonItem detail onClick={onClick}>
          <IonGrid>
            <IonRow>
              <IonCol className="id-date" size="6">
                <div className="order-card-info">
                  <div className="status-img-content">
                    {/* {DeliveryStatusImage(order)} */}
                    {/* <img src={order.shop.image_url} style={{width : '60px', height : '60px'}} /> */}
                  </div>
                  <div className="status-info">
                    <span>{order.code}</span>
                    <div className="order-date">{order.date}</div>
                  </div>
                </div>
              </IonCol>
              
                <IonCol>
                  <IonChip className='info'>
                    <IonLabel>
                      {DeliveryStatusText(order.status)}
                    </IonLabel>
                  </IonChip>
                </IonCol>
              
              {/* <IonCol className="price">{Helper.amountFormat(o.amount, false)}</IonCol> */}
              <IonCol className="price">{Helper.amountFormat({amount: order.grand_total})}</IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      );
    }

    return (
      <Page title={t('My orders')} className="orders-page">
        
        { isGuest == true && (
          <div style={{ background:"#fff", padding:"20px" }}>
            <div>
              {t('Please you should sign-in first')}
            </div>
            <div style={{ paddingTop : "10px" }}>
              <button className="btn btn-xs" style={{ fontSize:"12px", padding: "4px 20px", "height" : "unset" }}
                onClick={() => this.navToSignin()}
              >
                {t('Sign-in')}
              </button>
            </div>
          </div>
        )}
        { isGuest == false && (
          <div>
            <IonLoading mode="ios" isOpen={loading} />

            {/* {!loading && openOrders.length == 0 && tab == 'openOrders' && (
              <div className="">
                <div style={{paddingTop : '130px', textAlign : 'center'}}>
                  <img src="assets/icon/open-orders.png" style={{ width:'140px' }}/>
                  <div style={{paddingTop : '5px'}}>
                    للاسف لا توجد طلبات مفتوحة
                  </div>
                </div>
              </div>
            )} */}

            {!loading && orders.length <= 0 && (
              <div className="">
                <div style={{paddingTop : '130px', textAlign : 'center'}}>
                  <img src="assets/icon/open-orders.png" style={{ width:'140px' }}/>
                  <div style={{paddingTop : '5px'}}>
                    {t("You don't have orders yet.")}
                  </div>
                </div>
              </div>
            )}

            <IonList lines="none" mode="ios">
              { orders.map((order) => (
                <OrderCard key={order.id} onClick={() => this.handleSelect(order)} order={order}/>
                ))}
            </IonList>
            
          </div>
        )}
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Orders));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, select }) {
  return {
    auth,
    history: select.history,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setOrder: (auth) => dispatch(SelectAction.setOrder(auth)),
  };
}
