import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonButton,
  IonBadge,
  IonCheckbox,
  IonLabel,
  IonItem
} from '@ionic/react';

import Helper from 'Helper';

import './ProductOptions.scss';
import { useHistory } from 'react-router';

/**
 * Dynamic app header.
 */
const ProductOptions = ({ variants, className, onSelectError, onSelectSucess, handleOptionsChanges, t, ...props }) => {
  const history = useHistory();

  const [options, setOptions] = useState(variants);
  const [errors, setErrors] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {

    let newOptions = options.map((option) => {
      return {
        ...option,
        items : option.items.map((item) => {
          return {
            ...item,
            selected : false,
          }
        })
      }
    });

    setOptions(newOptions);
    // this.setState({
    //   options : newOptions,
    // })

  }, []);

  useEffect(() => {
    getValues();
  }, [options])

  const getValues = () => {
    let required_title = '';
    let required_left = false;

    let selected_options = [];
    options.forEach((option) => {

      if (option['required'] == true) {
        required_left = true;
        required_title = option['title'];
        option.items.forEach(item => {
          if (item.selected == true) {
            required_left = false;
          }
        })
      }

      option.items.forEach(item => {
        if (item['selected'] == true) {
          selected_options.push({
            ...item,
            option_id : option.id
          });
        }

        // if (item['selected'] == true && option['type'] == 'note') {
        //   options_notes.push(item.name)
        // }
      })
    });

    if (required_left == true) {
      handleOptionsChanges(selected_options, required_title)
      return;
    }

    handleOptionsChanges(selected_options, null)
    // onSelectError(required_title);
    // onSelectSucess(selected_options);
  }

  const handleOptionChange = (group, selectedItem, check) => {
    // const { options } = this.state;
    const { max, required } = group;

    let newOptions = options.map((option) => {
      let newItems = option.items;
      let disabled = option.disabled;
      // only filter the group options
      if (group.id == option.id) {
        let selected_size = 0;
        newItems = option.items.map((item) => {
          let selected = item.selected;
          if (selectedItem['id'] == item['id']) {
            selected = check;
          }

          if (selected == true) {
            selected_size++;
          }

          return {
            ...item,
            selected,
          }
        });

        if (selected_size == max) {
          disabled = true;
        } else {
          disabled = false;
        }
      }

      return {
        ...option,
        disabled,
        items : newItems
      }
    });

    setOptions(newOptions);
    // this.
  }


  return (
    <div className='options-container'>
      {options && options.map((group) => (
        <div key={group.id}> 
          <h1 className='option-title'>
            {group.title}
            {group?.required == true && (
              <span className='required-flag float-end'>{t('required')}</span>
            )}
          </h1>
          {group.items.map((item) => (
            <IonItem key={item.name}>
              <IonLabel>
              {item.name}    
              {item.price && (
                <span className='option-price'>{Helper.amountFormat({amount : item.price})}</span>
              )}
              </IonLabel>
              <IonCheckbox slot="end"
                checked={item.selected}
                disabled={group.disabled && item.selected == false}
                onIonChange={(e) => {
                  handleOptionChange(group, item, e.detail.checked)
                }} />
            </IonItem>
          ))}
        
        </div>
      ))}
    </div>
  );
};

ProductOptions.propTypes = {
  // Custom content
  onSelectSucess : PropTypes.func,
  onSelectError : PropTypes.func,
  handleOptionsChanges : PropTypes.func,
  options: PropTypes.array,
  // Header title
  // title: PropTypes.string,
  // // Custom class name
  // classeName: PropTypes.string,
  // // Back button
  // backButton: PropTypes.bool,
  // // Right side buttons
  // buttons: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     icon: PropTypes.string,
  //     href: PropTypes.string,
  //     onClick: PropTypes.func,
  //     badge: PropTypes.string,
  //   })
  // ),
};

// Default values...
ProductOptions.defaultProps = {
  options: [],
  handleOptionsChanges : Function,
  onSelectSucess : Function,
  onSelectError : Function
};

export default ProductOptions;
