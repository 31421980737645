import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Verify.scss';
import { IonItem, IonLabel, IonText, IonAlert } from '@ionic/react';
import { Button, Input } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import Layout from '../Layout/Layout';
import { withRouter } from 'react-router';
import AuthService from 'services/auth.service';
import Helper from 'Helper';
import AuthAction from 'store/actions/auth';

import Http from 'libs/http';

import * as _ from 'lodash';

class Verify extends Component {
    constructor(props) {
      super(props);

      this.http = new Http;
      
      this.state = {
        loading: false,
        resending: false,
        sentCode: false,
        code: null,
        alert: null,
      };

      this.service = new AuthService();

      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleResend = this.handleResend.bind(this);
    }

    async componentDidMount() {
      const { cache } = this.props;
      const { authPage } = cache;
      console.log("auth-page-data", authPage);
      // const { state } = this.props.location;

      // if (!state || !state.phoneNumber) {
      //   this.service.logOut();
      //   return this.props.history.push(Helper.getRoute('login'));
      // }
    }

    /**
     * Handle resend button.
     */
    handleResend(e) {
        e.preventDefault();
        const { cache } = this.props;
        const { authPath } = cache;
        const { name, phone, email } = authPath;

        this.setState({ resending: true, alert: null });
        this.service
            .signUp({
                name,
                phone,
                email,
                resend: true,
            })
            .then((res) => {
                if (!res.success) {
                    return this.setState({ resending: false, alert: res.message });
                }

                this.setState({ sentCode: true, resending: false });
            })
            .catch((err) => {
                this.setState({ resending: false, alert: 'Something went wrong, please try again.' });
                console.error(err);
            });
    }

    /**
     * Handles submit button.
     */
    handleSubmit(e) {
        e.preventDefault();

        const { code } = this.state;
        const { state } = this.props.location;
        const { cache, setCache } = this.props;
        const { authPage } = cache;
        const { name, phone, email, returnTo } = authPage;

        console.log('authPage', authPage);

        // const returnTo = _.get(this.props, 'history.location.state.returnTo', null);
        
        
        if (!code) {
          return this.setState({ alert: this.props.t('Please enter the code.') });
        }

        this.setState({ loading: true, alert: null });
        this.http.post('customer/auth/verify-code', {
          code,
          phone,
        })
        .then(data => {
          const { error, user, message, access_token } = data;
          this.setState({ loading: false });
          if (error) {
            return this.setState({ alert: message });
          }
          
          this.props.setUser({ ...user, access_token });
          this.props.history.push(returnTo || Helper.getRoute('home'));
        })
        .catch((err) => {
            this.setState({ loading: false });
            console.error(err);
        });
    }

    /**
     * Render the component.
     */
    render() {
        const { loading, resending, sentCode, alert } = this.state;
        const { t, history } = this.props;

        /**
         * Handles go back button.
         */
        const handleGoBack = () => {
            history.push(Helper.getRoute('login'), this.props.location.state);
        };

        return (
            <Layout>
                {sentCode && (
                    <IonAlert
                        mode="ios"
                        onDidDismiss={() => this.setState({ sentCode: false })}
                        isOpen={true}
                        buttons={['Ok']}
                        message={t('The code sent successfully.')}
                    />
                )}
                <form className="verify-page" onSubmit={this.handleSubmit}>
                    <IonItem lines="none">
                        <IonLabel className="head ion-text-wrap">
                            <IonText color="primary">
                                <h1>{t('Code verification')}</h1>
                            </IonText>
                            <p>{t('Please enter the code you received from the SMS')}</p>
                        </IonLabel>
                    </IonItem>
                    {alert !== null && <div className="form-control alert alert-danger">{alert}</div>}
                    <IonItem lines="none">
                        <Input name="code" type="number" onChange={(code) => this.setState({ code })} placeholder={t('Verification code')} />
                    </IonItem>
                    <IonItem lines="none">
                        <Button type="button" onClick={handleGoBack} variant="outline" full large>
                            {t('Back')}
                        </Button>
                        <div style={{ width: 30 }}></div>
                        <Button type="submit" loading={loading} full large>
                            {t('Sign in')}
                        </Button>
                    </IonItem>
                    <IonItem className="resend-code" lines="none">
                        <IonLabel>
                            <span>{t("You didn't get the code?")}</span>{' '}
                            {!resending ? (
                                <a onClick={this.handleResend} href="/">
                                    {t('Resend')}
                                </a>
                            ) : (
                                t('Sending...')
                            )}
                        </IonLabel>
                    </IonItem>
                </form>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(Verify)));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ cache }) {
  return {
    cache,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setUser: (data) => dispatch(AuthAction.setUser(data)),
  };
}
