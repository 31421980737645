import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ShopItem.scss';
import { IonItem, IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react';
import ReactImageAppear from 'react-image-appear';
import Helper from 'Helper';
import { noImageFound } from 'components/Icons';
import { thumbsUpOutline } from 'ionicons/icons';
import { withNamespaces } from 'react-i18next';

/**
 * Shop Item.
 */
const ShopItem = ({ children, t, editable, className, shop, onClick, ...props }) => {

  return (
    shop && (
      <div className='shop-card' onClick={onClick}>
        <div className='shop-image'>
          <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={shop.image_url} alt="" />
        </div>
        <div className="shop-info">
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-gray-900">{shop.name}</p>
            <p className="shop-title">{shop.title}</p>
          </div>
        </div>
        <div className="shop-features">
          <div className="text-sm leading-6 text-gray-900">
            <div className='like-counter'>
              <IonIcon icon={thumbsUpOutline}></IonIcon> {shop.likes} 
            </div>
              {/* <br /> 3km */}
            </div>
        </div>
      </div>
    )
  );
};

ShopItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  editable: PropTypes.bool,
  shop : PropTypes.any,
  onClick : PropTypes.func
};

ShopItem.defaultProps = {
  shop: null,
  editable: true,
  onClick : () => {
    return;
  }
};

export default withNamespaces()(ShopItem);
