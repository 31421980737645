export const ADD_TO_FAV = 'ADD_TO_FAV';
export const REMOVE_FROM_FAV = 'REMOVE_FROM_FAV';

export default class FavAction {
  /**
   * Add to cart action
   * @param {Object} data
   */
  static addToFav(data, auth) {
    return {
      type: ADD_TO_FAV,
      data,
      auth,
    };
  }

  /**
   * Remove from cart action
   * @param {Object} data
   */
  static removeFromFav(data, auth) {
    return {
      type: REMOVE_FROM_FAV,
      data,
      auth,
    };
  }

}
