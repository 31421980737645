import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as smoothscroll from 'smoothscroll-polyfill';
import $ from 'jquery';
import {
  isPlatform,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonSlides,
  IonSlide,
} from '@ionic/react';

import './Collection.scss';
import { Page, Modal, Loading } from 'components';
import ReactImageAppear from 'react-image-appear';

import {
  IonSegment,
  IonSegmentButton,
  IonContent,
  IonList,
  IonLabel,
  IonToolbar,
} from '@ionic/react';
import Helper from 'Helper';
import { funnelOutline, addOutline, cloudUploadOutline } from 'ionicons/icons';
import ViewCart from 'components/ViewCart/ViewCart';
import BlockBuilder from 'components/BlockBuilder/BlockBuilder';
import { Radio, Button, Input } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import ProductItem from 'components/Customs/ProductItem/ProductItem';
import AppService from 'services/app.service';
import Http from 'libs/http';
import { createBrowserHistory } from 'history';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { byStats } from '../Store/StoreData';
import SelectAction from 'store/actions/select';
import CartAction from 'store/actions/cart';
import ProductService from 'services/product.service';

smoothscroll.polyfill();

class Collection extends Component {
  constructor(props) {
    super(props);

    const collection_id = this.props.history.location.state;
    
    this.http = Http;
    
    this.state = {
      collection_id : collection_id,
      categories: [],
      category: {},
      products: [],
      blocks : [],
      categoryType: 'MAIN',
      subCategory: 0,
      orderBy: '1',
      customImg: null,
      uploadedFile: null,

      productName: null,
      productDescription: null,

      refresher: false,
      refreshing: false,
      addingProduct: false,
      loading: true,
      uploading: false,
      sort: false,
    };

    this.container = null;
    this.categoryRef = null;
    this.uploadImageRef = null;
    this.refresher = null;
    this.subCategoryRef = null;
    this.subCategoryItemsRef = null;
    this.timeoutRef = null;

    this.service = new AppService();
    this.productService = new ProductService();

    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleSubCategoryChange = this.handleSubCategoryChange.bind(this);
    this.handleScrollEvent = this.handleScrollEvent.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);

    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleCustomProductDismiss = this.handleCustomProductDismiss.bind(this);
    this.handleAddCustomProduct = this.handleAddCustomProduct.bind(this);
    this.handleCancelProduct = this.handleCancelProduct.bind(this);

  }

  get categories() {
    const { categories, category } = this.state;
    return categories.find((c) => c.id === category.id);
  }

  /**
   * On init
   */
  async componentDidMount() {

    this.timeoutRef = setTimeout(() => {});
    
    window.addEventListener('beforeunload', () => {
      
    });

    this.getBlocks();
  }

  /**
   * On unload
   */
  async componentWillUnmount() {
    window.removeEventListener('beforeunload', () => {});
  }

  /**
   * On state update.
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(pp, ps) {
    
  }

  getBlocks() {
    this.service.loadCollections(this.state.collection_id)
      .then(blocks => {
        this.setState({
          blocks
        });
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * Fetch products.
   */
  getProducts() {
    
    const { category, subCategory, orderBy } = this.state;
    const { auth } = this.props;

    this.setState({ loading: true });

    const params = {
      // storeId: auth.store.id,
      category: category.id,
      subCategory : subCategory,
      // orderByPrice: orderBy === '2',
    };

    // if (subCategory) {
    //   params.subCategory = subCategory;
    // }

    // if ('native' in category) {
    //   this.productService.getFavoriteProducts(auth).then((products) => {
        
    //     this.setState({ loading: false, products });
    //   });
    // } else {
      
    // }


    this.productService.getProducts(params).then((products) => {
      console.log('products', products);
      this.setState({ loading: false, products });
    });

  }

  /**
   * Handle auto scroll for categories.
   */
  categoryScroll(ref = this.categoryRef) {
    if (ref) {
      Helper.scrollTo(ref, 'left');
    }
  }

  /**
   * Handles the selected category align.
   */
  handleCategoryChange(selected) {
    const { categoryType } = this.state;
    const { setCategory } = this.props;
    setCategory({ type: categoryType, data: selected });
    
    this.setState({ category: selected });
    // if ( 'native' in selected) {
    //   this.setState({ category: selected });
    // } else {
    //   this.setState({ category: selected, subCategory: selected['sub'][0]['id'] });
    //   this.handleSubCategoryChange(null, selected['sub'][0]['id']);
    // }
    
    if (this.subCategoryItemsRef) {
      this.subCategoryItemsRef.scrollTo(0, 0);
    }
    this.categoryScroll();

    // this.slideRef.slideNext();
  }

  /**
   * Handles the selected category align.
   */
  handleSubCategoryChange(e, value) {
    if (e != null) {
      e.preventDefault();  
    }
    this.setState({ subCategory: value }); 
  }

  /**
   * Handles scroll event.
   * @param {Event} e
   */
  handleScrollEvent(e) {
    const { refresher } = this.state;
    const top = e.detail.scrollTop;

    // if (isPlatform('ios')) {
    if (this.subCategoryRef) {
      if (top > 0) {
        this.subCategoryRef.style.position = 'fixed';
      } else {
        this.subCategoryRef.style.position = 'absolute';
      }
    }

    if (top < 0 && Math.abs(top) >= 120 && !refresher) {
      this.handleRefresh();
      this.setState({ refresher: true });
    }
    // }
  }

  /**
   * Handles on refresh event.
   */
  handleRefresh() {
    if (!isPlatform('ios')) {
      // this.subCategoryRef.style.top = '0px';
    }

    this.setState({ refreshing: true });
  }

  async handleCustomProductDismiss() {
    const { addingProduct } = this.state;
    this.setState({ addingProduct: !addingProduct });
    await this.handleCancelProduct();
  }

  /**
   * Handles canceling custom product.
   */
  async handleCancelProduct() {
    const { uploadedFile } = this.state;
    if (uploadedFile) {
      this.setState({ customImg: null, uploadedFile: null });
      await uploadedFile.delete();
    }
  }

  /**
   * Handles upload file.
   */
  async handleUploadFile(e) {
    const file = e.target.files[0];
    if (file) {
      this.setState({ uploading: true });
      try {
        const { ref } = await this.service.uploadFile(file);
        const customImg = await ref.getDownloadURL();
        this.setState({ customImg, uploading: false, uploadedFile: ref });
      } catch (e) {
        console.error('Failed to upload the image.');
      }
    }
  }

  /**
   * Handles adding new custom product.
   * @param {Object} e
   */
  handleAddCustomProduct(e) {
    e.preventDefault();
    const { customImg, productName, productDescription, addingProduct } = this.state;
    const { addToCart, auth } = this.props;

    const product = {
      id: Helper.uid(16),
      name: { en: productName, ar: productName },
      price: 0,
      description: productDescription,
      img: customImg,
      type: 'CUSTOM',
    };

    addToCart(product, auth);

    this.setState({ addingProduct: !addingProduct, customImg: null, uploadedFile: null });
  }

  /**
   * Render the component.
   */
  render() {
    const { t } = this.props;
    const {
      category,
      subCategory,
      sort,
      orderBy,
      loading,
      addingProduct,
      customImg,
      uploading,
      categories,
      products,
      blocks,
      collection_id
    } = this.state;


    return (
      <>
        <Page
          withPage={false}
          backButton
          hasSearch
          hasCart
          title={t('إقطفها صح')}
          className="collection-page"
        >
          <IonContent
            mode="md"
            scrollEvents
            onIonScrollStart={this.handleScrollEvent}
            onIonScroll={this.handleScrollEvent}
            class="fixed-sub-category not-pulling"
            ref={(ref) => (this.container = ref)}
          >

            <BlockBuilder collection_id={collection_id}></BlockBuilder>

          </IonContent>
        </Page>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Collection));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ cache, auth, select }) {
  const { location } = createBrowserHistory();
  return {
    cache,
    auth,
    history: select.history,
    category: select.category,
    location,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setCategory: (data) => dispatch(SelectAction.setCategory(data)),
    addToCart: (data, auth) => dispatch(CartAction.addToCart(data, auth)),
  };
}
