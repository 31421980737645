import React from 'react';

import './Support.scss';
import { Page } from 'components';
import { withNamespaces } from 'react-i18next';
import { Button } from 'components/Form';

import { callSharp, mailSharp, chatboxSharp, logoWhatsapp } from 'ionicons/icons';

const Support = ({ t }) => {
  return (
    <Page className="support-page" backButton title={t('Support')}>
      <h3>{t('Contact Us')}</h3>
      {/* <p>يسعدنا خدمتك عبر : </p> */}
      <div className="form-control">
        <a href="https://wa.me/212710628107">
          <Button icon={logoWhatsapp} full>
            {t('WhatsApp')}
          </Button>
        </a>
      </div>
      <div className="form-control">
        <a href="tel:0710628107">
          <Button icon={callSharp} full>
            {t('Call us')}
          </Button>
        </a>
      </div>
      <div className="form-control">
        <a href="mailto:info@freshsoq.com">
          <Button icon={mailSharp} full>
             {t('Email')}
          </Button>
          </a>
      </div>
    </Page>
  );
};

export default withNamespaces()(Support);
