import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

import './Order.scss';
import { Page } from 'components';
import { withNamespaces } from 'react-i18next';
import ReactImageAppear from 'react-image-appear';
import { IonText, IonItem, IonFooter, IonGrid,
  IonRow,
  IonCol } from '@ionic/react';
import SelectAction from 'store/actions/select';
import { Redirect } from 'react-router';
import Helper from 'Helper';
import { noImageFound } from 'components/Icons';
import { IonIcon } from '@ionic/react';
import { rollingLoading } from 'components/Icons';
import { cdnImage } from 'libs/pipes';
import OrderService from 'services/order.service';

import Http from 'libs/http';

const Order = ({ t, order, ...props }) => {

//   order = {
//     "id": 544,
//     "code": "20230111-054320",
//     "user_id": 119,
//     "payment_type": "",
//     "payment_status": "unpaid",
//     "payment_status_string": "Unpaid",
//     "delivery_status": "pending",
//     "delivery_status_string": "pending",
//     "shipping_cost": null,
//     "subtotal": null,
//     "grand_total": "92",
//     "date": "11-01-2023"
// };
  const [orderDetails, setOrderDetails] = useState({});
  const [items, setItems] = useState(order.items);
  const [loading, setLoading] = useState(false);
  const service = new OrderService();
  const http = new Http();

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = () => {
    setLoading(true);
    // http.post('customer/orders/order-details', {order_id : order.id})
    //   .then(res => {
    //     const { order, items } = res
    //     setLoading(false);
    //     setItems(items);
    //     setOrderDetails(order[0]);
    //   })
    //   .catch(err => {
    //     console.error('res', err);
    //     setLoading(false);
    //   })
  }

  if (!order) {
    return <Redirect to={Helper.getRoute('orders')} />;
  }

  const navToBilling = () => {
    props.history.push(Helper.getRoute('order-billing'));
  }

  const handleGoToProduct = (product) => {
    // window['blockLink']('product', product['id'], JSON.stringify(product));
  }

  const DeliveryStatus = () => {
    // "confirmed"
    // color : EF8480
    const status = {
      // https://storyset.com/illustration/confirmed/bro
      0 : {
        image : 'pending.svg',
        name : 'تم إستلام طلبك'
      },
      // https://storyset.com/illustration/checking-boxes/amico
      1 : {
        image : 'confirmed.png',
        name : 'جاري تجهيز طلبك'
      },
      // https://storyset.com/illustration/delivery-address/amico
      2 : {
        image : 'on_delivery.png',
        name : 'طلبك مع المندوب'
      },
      // https://storyset.com/illustration/messenger/pana
      3 : {
        image : 'delivered.svg',
        name : 'تم توصيل الطلب'
      },
      // https://storyset.com/illustration/cancelled-event/rafiki
      4 : {
        image : 'cancelled.svg',
        name : 'تم إلغاء الطلب'
      }
    };

    const {image, name} = status[order.status];

    return (
      <div className="delivery-status-container">
        <div className="header">
          <img src={`assets/images/delivery-status/${image}`} />
        </div>
        <div className="body">
          {order.delivery_status_string}
        </div>
      </div>
    );
  }

  const ProductCard = ({item}) => {
    const { product_name, unit_price, image } = item || {};
    
    return (
      <div className='order-item' onClick={() => handleGoToProduct(item)}>
        <IonGrid>
          <IonRow>
            <IonCol size="1">
              <div className='order-item-quantity'>x{item.quantity}</div>
            </IonCol>
            <IonCol size="8">
              <div className='title'>{product_name}</div>
            </IonCol>
            <IonCol size="3">
            <div className='order-item-price'>{ Helper.amountFormat({amount: item.price}) } </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }

  return (
    <Page backButton title={t('Order number') + ' - ' + order.id} className="order-page">
      {/* <h3>
        {t('Order Date')} : {order.date}
      </h3> */}
      {order.reason && order.reason !== '' && (
        <div className="form-control alert alert-danger no-raduis">
          <div className="body">
            <b>{t('Cancelation reason')}:</b>
            <div>{order.reason}</div>
          </div>
        </div>
      )}

      <div className="">
        <DeliveryStatus></DeliveryStatus>
      </div>

      <div className="form-control">
        <div className="label">
          {t('Products')}
        </div>
        <div className="order-items-container">
          {items.map((item) => (
            <ProductCard key={item.product_id} item={item} />
          ))}
        </div>
      </div>

      <div className="form-control">
        <div className="totals gs-block">
          <div>
            <IonItem lines="none">
              <IonText slot="start">
                <p>{t('Payment method')}</p>
              </IonText>
              <IonText className="payment-method" slot="end" color="secondary">
                {/* {(order.payment_method || '').toLowerCase()} */}
                <p>
                  {t('Cash')}
                </p>
              </IonText>
            </IonItem>
          </div>
        </div>
      </div>

      <div className="form-control" style={{display : "none"}}>
        <div className="totals gs-block">
          <div>
            <IonItem lines="none">
              <IonText slot="start">
                <p>{t('Delivery to')}</p>
              </IonText>

              <IonText className="payment-method" slot="end" color="secondary">
              <div className="loading-icon">
                    <IonIcon color="#333" icon={rollingLoading}></IonIcon>
                  </div>
                { (loading == false && orderDetails?.shipping_address) ? (
                  <>
                    <p>{orderDetails.shipping_address['name']}</p>
                    <p>{orderDetails.shipping_address['phone']}</p>
                    <p>{orderDetails.shipping_address['country']}, {orderDetails.shipping_address['city']}</p>
                    <p>{orderDetails.shipping_address['city']}</p>
                  </>
                ) : (
                  <div >
                    <IonIcon icon={rollingLoading}></IonIcon>
                  </div>
                )}
                
              </IonText>
            </IonItem>
          </div>
        </div>
      </div>

      <div className="form-control">
        <div className="totals gs-block last">
          <div>
            <IonItem lines="none">
              <IonText slot="start">
                <p>{t('Subtotal')}</p>
                <p>{t('Delivery fees')}</p>
              </IonText>
              <IonText className="align-right" slot="end" color="secondary">
                {/* <p>{Helper.amountFormat(order.amount, false)}</p> */}
                
                <p>{Helper.amountFormat({amount: order?.subtotal})}</p>
                <p>{Helper.amountFormat({amount: order?.shipping_cost, html: false}) || t('Free')}</p>
              </IonText>
            </IonItem>
          </div>
          <div>
            <IonItem lines="none">
              <div slot="start">{t('Total')}</div>
              <IonText className="align-right" slot="end" color="secondary">
                {Helper.amountFormat({amount: order.grand_total})}
              </IonText>
            </IonItem>
          </div>
        </div>
      </div>

      {/* <IonFooter>
        <div style={{ fontSize : '12px', color: '#888', fontWeight : 'normal' }}
          onClick={ () => navToBilling() }
        >
          عرض الفاتورة
        </div>
      </IonFooter> */}
    </Page>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Order));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ select }) {
  return {
    order: select.order,
    history: select.history
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setOrder: (auth) => dispatch(SelectAction.setOrder(auth)),
  };
}
