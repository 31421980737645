import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TagInput } from 'reactjs-tag-input';
import './Store.scss';
import { Page, Modal } from 'components';
import List from './List/List';
import Banner from './Banner/Banner';
import { withNamespaces } from 'react-i18next';
import AppService from 'services/app.service';
import CacheAction from 'store/actions/cache';
import { IonLoading, IonRow, IonHeader, IonToolbar, IonContent } from '@ionic/react';
import Helper from 'Helper';
import SelectAction from 'store/actions/select';
import { Button, Input } from 'components/Form';
import CartAction from 'store/actions/cart';
import { db, firebase } from 'services/firebase/firebase';

class Store extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      categories: [],
      addCustomProduct: false,
      customProducts: [],
    };

    this.service = new AppService();

    this.tagInput = null;

    this.handleSelectCetegory = this.handleSelectCetegory.bind(this);
    this.handleAddCustomProducts = this.handleAddCustomProducts.bind(this);
  }

  async componentDidMount() {
    const { cache, setCache } = this.props;

    this.setState({ loading: true });

    const now = new Date().getTime();

    /**
     * Generate expire date.
     */
    const getExpireDate = () => {
      const date = new Date();
      date.setSeconds(
        date.getSeconds() + parseInt(process.env.REACT_APP_CACHE_CATEGORIES_TIME)
      );
      return date.getTime();
    };

    let categories;
    if (!cache.categories || !cache.categories.data || cache.categories.expireAt < now) {
      categories = await this.service.getCategories();
      setCache({ categories: { data: categories, expireAt: getExpireDate() } });
    } else {
      categories = cache.categories.data;
    }

    this.setState({ categories, loading: false });
  }

  /**
   * Handle select category.
   * @param {String} type
   * @param {Object} category
   */
  handleSelectCetegory(type, category) {
    const { history, setCategory } = this.props;
    setCategory({ type, data: category });
    history.push(Helper.getRoute('products'));
  }

  /**
   * Handle Add Custom Products.
   */
  handleAddCustomProducts() {
    const { addToCart, auth } = this.props;
    const { customProducts } = this.state;

    for (const product of customProducts) {
      const name = product.displayValue;
      addToCart(
        {
          id: Helper.uid(16),
          name: { en: name, ar: name },
          price: 0,
          img: null,
          type: 'CUSTOM',
        },
        auth
      );
    }

    this.setState({ addCustomProduct: false });
  }

  navToTextOrder() {
    const { setStore, initCart, cart, auth, history } = this.props;

    history.push(Helper.getRoute('text-order'));
  }

  async loadFavsProducts(auth) {
    
    let products_ids = await db.doc('users/' + auth.user.id)
      .get()
      .then(doc => {
        if ('favorite' in doc.data()) {
          return doc.data()['favorite'];
        }

        return [];
      })

    this.setState({ favs_products: products_ids });
  }
  
  /**
   * Render the component.
   */
  render() {
    const { categories, loading, customProducts, addCustomProduct } = this.state;
    
    const { t , auth } = this.props;
    console.log('auth', auth);

    /**
     * Slider.
     */
    const sliderBanners = [
      {
        href: '#',
        img: '/assets/images/banners/banner-3.jpg',
      }
    ];

    // const sliderBanners = auth.store.slides;

    return (
      <>
        <Page hasDeliverTo line hasCart hasSearch className="store-page">
          <IonLoading cssClass="gs-loading" mode="ios" isOpen={loading} />
          <Banner slide banners={sliderBanners} />
          {/* <Banner banners={sliderBanners} /> */}
          <h3>{t('By Category')}</h3>
          <div className="category-list">
            <IonRow>
              {/* <List
                  key="favs"
                  type="images"
                  img="https://www.flaticon.com/svg/static/icons/svg/3237/3237429.svg"
                  name="المفظلة"
                  onSelect={() => this.handleSelectCetegory('MAIN', {
                    sub : []
                  })}
                /> */}
              {categories.map((c) => (
                <List
                  key={c.id}
                  type="images"
                  onSelect={() => this.handleSelectCetegory('MAIN', c)}
                  {...c}
                />
              ))}
            </IonRow>
          </div>
          <div className="form-control" style={{ display : 'none' }}>
            <Button
              onClick={() => this.navToTextOrder()}
              className="request-products"
              full
              variant="outline"
              large
            >
              {t('Request custom products')}
            </Button>
          </div>
          
        </Page>
        {addCustomProduct && (
          <Modal
            title={t('Enter product information')}
            onDismiss={() => this.setState({ addCustomProduct: false })}
            isOpen={true}
          >
            <IonHeader>
              <IonToolbar>
                Hello there
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <div className="group" style={{ padding: '0 20px' }}>
                <div className="form-control">
                  <Input type="textarea" placeholder="حليب المراعي"></Input>
                </div>
                <div className="form-control tag-input"
                  style={{ display:'none' }}
                >
                  <TagInput
                    tags={customProducts}
                    ref={(ref) => (this.tagInput = ref)}
                    placeholder={t('Enter products by name...')}
                    onTagsChanged={(e) => {
                      this.tagInput.input.focus();
                      this.setState({ customProducts: e });
                    }}
                  />
                </div>
                <div className="form-control">
                  <Button onClick={this.handleAddCustomProducts} large full>
                    {t('Add To Cart')}
                  </Button>
                </div>
              </div>
            </IonContent>
          </Modal>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Store));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ select, cache, auth }) {
  return {
    cache,
    auth,
    history: select.history,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setCategory: (data) => dispatch(SelectAction.setCategory(data)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
    addToCart: (data, auth) => dispatch(CartAction.addToCart(data, auth)),
  };
}
