import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Modal.scss';
import { IonBackdrop, IonIcon } from '@ionic/react';

/**
 * A sample Modal
 */
const Modal = ({ children, isOpen, closeButton, title, onDismiss, raduis, className }) => {
  // Handle close button
  const handleClose = (e) => {
    e.preventDefault();
    onDismiss();
  };

  if (isOpen === undefined) {
    return <div />;
  }

  return (
    <div
      className={classNames('modal', isOpen && 'open', className, raduis && 'raduis')}
      aria-modal="true"
    >
      <div className={classNames('dialog', isOpen ? 'open' : 'close')}>
        {closeButton && (
          <IonIcon className="close" onClick={handleClose} name="close"></IonIcon>
        )}
        {title && <h3 className="title">{title}</h3>}
        {children}
      </div>
      <IonBackdrop onIonBackdropTap={onDismiss} className={!isOpen && 'close'} />
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  closeButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  raduis: PropTypes.bool,
  onDismiss: PropTypes.func,
};

Modal.defaultProps = {
  raduis: true,
  onDismiss: () => null,
};

export default Modal;
