// import firebase from 'firebase/app';
// import 'firebase/remote-config';
// import 'firebase/firestore';
// import 'firebase/auth';
// import 'firebase/storage';

// /**
//  * Firebase Configuration.
//  */
// const firebaseConfig = {
//   apiKey: "AIzaSyBZDqMliEWlmFi6u9KArxnzrSmO09lex4g",
//   authDomain: "enab-307316.firebaseapp.com",
//   projectId: "enab-307316",
//   storageBucket: "enab-307316.appspot.com",
//   messagingSenderId: "60087195158",
//   appId: "1:60087195158:web:f84c55fe35c10abc0d4737"
// };

// // Init the App
// firebase.initializeApp(firebaseConfig);

// const db = firebase.firestore();
// const remoteConfig = firebase.remoteConfig()
// remoteConfig.settings.minimumFetchIntervalMillis = 0;

// export { db, firebase, remoteConfig };



// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/remote-config";
// import { getFirestore } from "firebase/firestore";
// import { getAnalytics, logEvent  } from "firebase/analytics";
// import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyAO71JBDbiA-hGLEV4UfQPDYyGEKqKH8A0",
  authDomain: "fresh-vast.firebaseapp.com",
  projectId: "fresh-vast",
  storageBucket: "fresh-vast.appspot.com",
  messagingSenderId: "640622112954",
  appId: "1:640622112954:web:4d04662a4c080537365283",
  measurementId: "G-6NNCX7MF4J"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
const db = firebase.firestore();
const remoteConfig = firebase.remoteConfig();

export { app, analytics, db, remoteConfig };

