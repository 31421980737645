import React, { Component } from 'react';
import { connect } from 'react-redux';
// var classNames = require('classnames');
import * as classNames from 'classnames';
import { TagInput } from 'reactjs-tag-input';
import './Categories.scss';
import { Page, Modal } from 'components';
import List from './List/List';
import Banner from './Banner/Banner';
import { withNamespaces } from 'react-i18next';
import AppService from 'services/app.service';
import CacheAction from 'store/actions/cache';

import { 
  IonLoading,
  IonRow,
  IonHeader,
  IonToolbar,
  IonContent,
  IonGrid,
  IonCol,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/react';

import Helper from 'Helper';
import SelectAction from 'store/actions/select';
import { Button, Input } from 'components/Form';
import CartAction from 'store/actions/cart';
import { db, firebase } from 'services/firebase/firebase';

import { cdnImage } from 'libs/pipes';

class Categories extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      allCategories : [],
      subCategories : [],
      categories: [],
      selectedCategory : {},
      addCustomProduct: false,
      customProducts: [],
    };

    this.service = new AppService();

    this.tagInput = null;

    this.handleSelectCetegory = this.handleSelectCetegory.bind(this);
    this.handleAddCustomProducts = this.handleAddCustomProducts.bind(this);
  }

  async componentDidMount() {
    const { cache, setCache, category } = this.props;

    this.setState({ loading: true });

    const now = new Date().getTime();

    /**
     * Generate expire date.
     */
    const getExpireDate = () => {
      const date = new Date();
      date.setSeconds(
        date.getSeconds() + parseInt(process.env.REACT_APP_CACHE_CATEGORIES_TIME)
      );
      return date.getTime();
    };

    let categories;
    if (!cache.categories || !cache.categories.data || cache.categories.expireAt < now) {
      categories = await this.service.getExploreCategories();
      setCache({ categories: { data: categories, expireAt: getExpireDate() } });
    } else {
      categories = cache.categories.data;
    }

    let primaryCategories = [];
    primaryCategories = categories.filter((item) => {
      if (item.level == 0) {
        return item;
      }
    });

    this.setState({ 
      allCategories : categories,
      categories : primaryCategories,
      loading: false
    });

    console.log('---category---', category)
    if(category && category.haveSubCategories){
      this.handleSelectCetegory(category);
    }
    else{
      this.handleSelectCetegory(primaryCategories[0]);
    }
  }

  /**
   * Handle select category.
   * @param {String} type
   * @param {Object} category
   */
  handleSelectCetegory(category) {
    const { history, setCategory } = this.props;
    const { allCategories } = this.state;
    

    let subCategories = allCategories.filter(item => {
      if (category['id'] == item['parent_id']) {
        return item;
      }
    });

    if(subCategories.length === 0){
      this.handleOpenCategory(category)
    }
    setCategory({...category, haveSubCategories: subCategories.length > 0})
    this.setState({ subCategories, selectedCategory : category });
  }

  /**
   * Handle Add Custom Products.
   */
  handleAddCustomProducts() {
    const { addToCart, auth } = this.props;
    const { customProducts } = this.state;

    for (const product of customProducts) {
      const name = product.displayValue;
      addToCart(
        {
          id: Helper.uid(16),
          name: { en: name, ar: name },
          price: 0,
          img: null,
          type: 'CUSTOM',
        },
        auth
      );
    }

    this.setState({ addCustomProduct: false });
  }

  navToTextOrder() {
    const { setStore, initCart, cart, auth, history } = this.props;

    history.push(Helper.getRoute('text-order'));
  }

  async loadFavsProducts(auth) {
    
    let products_ids = await db.doc('users/' + auth.user.id)
      .get()
      .then(doc => {
        if ('favorite' in doc.data()) {
          return doc.data()['favorite'];
        }

        return [];
      })

    this.setState({ favs_products: products_ids });
  }

  async handleOpenCategory(item) {
    window['blockLink']('products', null, JSON.stringify({
      subcategory_id : item.id,
      category_id: item.parent_id
    }));
  }

  async handleOpenSubCategory(item) {
    window['blockLink']('products', null, JSON.stringify({
      subcategory_id : item.id,
      category_id: item.parent_id
    }));
  }
  
  /**
   * Render the component.
   */
  render() {
    const { 
      categories,
      loading,
      customProducts, 
      addCustomProduct, subCategories, selectedCategory } = this.state;
    
    const { t , auth } = this.props;

    /**
     * Slider.
     */
    const sliderBanners = [];
    // const sliderBanners = [
    //   {
    //     href: '#',
    //     img: '/assets/images/banners/banner-3.jpg',
    //   }
    // ];

    // const sliderBanners = auth.store.slides;

    return (
      <>
        <Page className="categories-page">
          <IonLoading cssClass="gs-loading" mode="ios" isOpen={loading} />
          {/* <Banner slide banners={sliderBanners} /> */}
          {/* <Banner banners={sliderBanners} /> */}
          {/* <h3>{t('By Category')}</h3> */}

          <IonGrid className="d">
            <IonRow>
              <IonCol size="3">
                <IonList>
                  {categories.map((c) => (
                    <div
                      key={c.id}
                      onClick={() => this.handleSelectCetegory(c)}
                      // className={'category-item '  + c.id == selectedCategory?.id ? 'active' : ''}
                      className={classNames({
                        'category-item': true,
                        'active': c.id == selectedCategory?.id
                      })}
                    >
                      <span>{ c.name }</span>
                    </div>
                  ))}
                </IonList>
              </IonCol>
              <IonCol size="9">
                <div className="categories-walls">
                  <div className="parent-category-banner" onClick={() => { this.handleOpenCategory(selectedCategory) }}>
                    <img src={cdnImage(selectedCategory.banner_file)} />
                  </div>
                  <div className="">
                    <IonGrid>
                      <IonRow>
                        { subCategories.map((item) => (
                          <IonCol size="4" key={item.id}>
                            <div className="category-icon-item" onClick={() => { this.handleOpenSubCategory(item) }}>
                              <img

                                style={{width:"100px"}}
                                src={cdnImage(item.icon_file, "https://firebasestorage.googleapis.com/v0/b/atlas-website-core.appspot.com/o/files%2F65226_36394.png?alt=media&token=c2ad2d38-a44a-4a1e-ab0d-d62b4f521047")} />

                              <div className="title">{item.name}</div>
                            </div>
                          </IonCol>
                        ))}
                      </IonRow>
                    </IonGrid>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          {/* <IonGrid>
            <IonRow>
              <IonCol size="4">
                <IonList>
                  {categories.map((c) => (
                    <IonItem 
                      key={c.id}
                      onClick={() => this.handleSelectCetegory('MAIN', c)}
                    >
                      <IonLabel>{ c.name }</IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid> */}

        </Page>

      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Categories));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ select, cache, auth }) {
  return {
    cache,
    auth,
    category: select.category,
    history: select.history,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setCategory: (data) => dispatch(SelectAction.setCategory(data)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
    addToCart: (data, auth) => dispatch(CartAction.addToCart(data, auth)),
  };
}
