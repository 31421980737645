import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Login.scss';
import { IonItem, IonLabel, IonText, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { Input, Button, Select } from 'components/Form';
import { withNamespaces } from 'react-i18next';
import Layout from '../Layout/Layout';
import { withRouter } from 'react-router';
import AuthService from 'services/auth.service';
import AuthAction from 'store/actions/auth';
import Helper from 'Helper';

import * as _ from 'lodash';

import CacheAction from 'store/actions/cache';
import Http from 'libs/http';

class Login extends Component {
    constructor(props) {
        super(props);

        const { name, phone, email } = _.get(this.props, 'cache.authPage', {});
        
        this.state = {
          name: name || '',
          phone: (phone || '').replace('+966', ''),
          email :  email || '',
          mobile: 2,
          alert: null,
          loading: false,
          returnTo : null
        };

        // this.history = useHistory();

        this.http = new Http;

        this.service = new AuthService();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
      const {setCache, cache} = this.props;
      const { authPage } = cache;
      const returnTo = _.get(this.props, 'history.location.state.returnTo', null);
      console.log('return', returnTo);
      if (returnTo != null) {
        setCache({
          authPage : {...authPage, returnTo}
        });
      }
    }

    handleNameChange(name) {
      if(name.match("^[a-zA-Z\u0621-\u064A0-9٠-٩_]*$")){
        this.setState({name})
      }
    }

    handleSubmit(e) {
      e.preventDefault();
      const { name, phone, email, code } = this.state;
      const { state } = this.props.location;
      const { cache, setCache } = this.props;
      const { authPage } = cache;

      const returnTo = _.get(this.props, 'history.location.state.returnTo', null);
      // const { name, phone, email } = authPage;
      
      if (!code) {
        // return this.setState({ alert: this.props.t('Please enter the code.') });
      }

      this.setState({ loading: true, alert: null });

      setCache({
        authPage : {
          ...authPage,
          phone
        }
      });

      this.http.post('customer/auth/signin-byphone', {
        phone,
      })
      .then(data => {
        const { error, message } = data;
        this.setState({ loading: false });
        if (error) {
          setTimeout(() => {
            this.setState({ alert: null })
          }, 5000);
          return this.setState({ alert: message });
        }

        // this.props.setUser({ ...user, access_token });
        this.props.history.push(Helper.getRoute('verify'));
      })
      .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
      });
    }

    /**
     * Handles form submit.
     * @param {Object} e
     */
    async handleSubmit_normal(e) {
      const {setCache, cache} = this.props;
      const { authPage } = cache;
        e.preventDefault();
        const { name, phone, loading, email } = this.state;

        setCache({
          authPage : {...authPage, name, phone, email}
        });

        let fullNumber = phone;
        if (!name || !phone) {
            this.setState({ alert: this.props.t('Please fill all fields.') });
            return;
        }

        if(!(phone.match(/^[05][0-9]{8,9}$/) && ((phone.length == 9 && phone.startsWith('5')) || (phone.length == 10 && phone.startsWith('0'))))){
            this.setState({ alert: this.props.t('Phone number is not valid') });
            return;
        }
        if(phone.length === 9){
            fullNumber = '0' + phone
        }

        
        if(!loading){
            this.setState({ loading: true, alert: null });
            this.service
                .signIn({
                    name,
                    phone: fullNumber,
                })
                .then((res) => {
                    this.setState({ loading: false });
                    if (res.error) {
                      return this.setState({ alert: res.message });
                    }
    
                    this.props.history.push(Helper.getRoute('verify'), {});
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    console.error(err);
                });
        }
    }

    /**
     * Handles back button action.
     */
    handleGoBack() {
        // return this.props.history.goBack();
    }

    /**
     * Render the component.
     */
    render() {
        const { loading, name, phone, alert, email } = this.state;
        const { t } = this.props;

        return (
            <Layout title={t('Signup')}>
                {/* <IonHeader mode="ios" className={'header ion-no-border '}>
                    <IonToolbar>
                        <IonButtons slot="start">
                        <IonButton className="back-button" onClick={ this.handleGoBack() }>
                            <IonIcon icon="chevron-back" />
                        </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    </IonHeader> */}
                <form onSubmit={this.handleSubmit}>
                    <IonItem lines="none">
                        <IonLabel className="head ion-text-wrap">
                            <IonText color="primary">
                                <h1>{t('Your contact information')}</h1>
                            </IonText>
                            <p>{t('Please enter your full name and phone number to continue')}</p>
                        </IonLabel>
                    </IonItem>
                    {alert !== null && <div className="form-control alert alert-danger">{alert}</div>}

                    <IonItem className="phone-number" lines="none">
                        <Input
                            type="tel"
                            value={phone}
                            onChange={(phone) => this.setState({ phone })}
                            placeholder={t('0xxxxxxxx')}
                        />
                        {/* <Select
                            disabled
                            columns={[
                                {
                                    name: 'phone_number',
                                    selectedIndex: 0,
                                    options: [{ text: '+966', value: '966' }],
                                },
                            ]}
                        /> */}
                    </IonItem>

                    <IonItem lines="none">
                        <Button type="submit" loading={loading} full large>
                            {t('Continue')}
                        </Button>
                    </IonItem>
                </form>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(Login)));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, select, cache }) {
    return {
      cache,
      history: select.history,
      auth,
    };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setUser: (data) => dispatch(AuthAction.setUser(data)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}
