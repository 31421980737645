import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  IonTabBar,
  IonTabButton,
  IonLabel,
  IonRouterOutlet,
  IonTabs,
  IonBadge,
  IonIcon,
} from '@ionic/react';

import { Route, withRouter, matchPath } from 'react-router';

import './AppTabs.scss';
import { Routes } from './AppRouter';
import { withNamespaces } from 'react-i18next';
import SelectAction from 'store/actions/select';
import { onlyCartMapStateToProps } from 'store/maps/cart';
/**
 * App menu tabs
 */
export class AppTabs extends Component {
  constructor(props) {
    super(props);

    const { history, setHistory, location, t } = this.props;

    setHistory(history);

    // Generate the routes
    this.routes = [];
    for (const route of Routes) {
      if (route.tab) {
        
        const { icon, hideTab: outside } = route.tab;
        
        // let label = t(route.name);
        
        // if (route.label) {
        //   label = t(route.label);
        // }

        this.routes.push({
          path: route.path,
          label : route.label || route.name,
          icon,
          outside,
        });

      }
    }

    this.state = {
      hideTabs: this.hiddenTabs.indexOf(this.getRoutePath(location.pathname)) > -1,
    };

    history.listen(() => {
      // location is an object like window.location
      setHistory(history);
      this.handleTabsChange(this.getRoutePath(history.location.pathname));
    });

    this.handleTabsChange = this.handleTabsChange.bind(this);
  }

  /**
   * Get hidden tabs.
   */
  get hiddenTabs() {
    const hiddenTabs = [];
    for (const route of Routes) {
      if (route.tab) {
        const { hideTab: outside } = route.tab;
        if (outside) {
          hiddenTabs.push(route.path);
        }
      } else {
        hiddenTabs.push(route.path);
      }
    }

    return hiddenTabs;
  }

  /**
   * Get route path.
   * @param {String} pathname
   */
  getRoutePath(pathname) {
    if (pathname === '/') {
      return pathname;
    }
    return this.hiddenTabs.find((path) => {
      const route = matchPath(pathname, { path });
      return route && route.url !== '/';
    });
  }

  /**
   * Handles tabs change.
   * @param tab current tab.
   */
  handleTabsChange(pathname) {
    this.setState({
      hideTabs: this.hiddenTabs.indexOf(pathname) > -1,
    });
  }

  /**
   * Render the compoent.
   */
  render() {
    const { hideTabs } = this.state;
    const { inCart, t } = this.props
    /**
     * Tab buttons
     * @var {JSX.Element}
     */
    const TabButtons = this.routes.map(({ path, icon, label }, key) => {

      return (
        <IonTabButton key={key} href={path} tab={path}>
          <IonIcon icon={icon} />
          { path == '/cart' && (
            <IonBadge color="primary">
              { inCart }
            </IonBadge>
          )}
          <IonLabel>{t(label)}</IonLabel>
        </IonTabButton>
      );
    });

    return (
      !hideTabs && (
        <IonTabs className="app-tabs">
          <IonRouterOutlet>
            {this.routes.map(({ path }, i) => (
              <Route key={i} path={path} />
            ))}
          </IonRouterOutlet>
          <IonTabBar className='main-tabs-bar d-none' slot="bottom">{TabButtons}</IonTabBar>
        </IonTabs>
      )
    );
  }
}

export default connect(
  onlyCartMapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withRouter(AppTabs)));


/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setHistory: (data) => dispatch(SelectAction.setHistory(data)),
  };
}
