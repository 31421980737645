export const SET_CACHE = 'SET_CACHE';
export const RESET_CACHE = 'RESET_CACHE';

export default class CacheAction {
  /**
   * Set cache action.
   * @param {Object} data
   */
  static setCache(data) {
    return {
      type: SET_CACHE,
      data,
    };
  }

  /**
   * Reset cache action.
   * @param {Object} data
   */
  static resetCache() {
    return {
      type: RESET_CACHE,
    };
  }
}
