import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Location.scss';
import { Page, Modal } from 'components';
import { withNamespaces } from 'react-i18next';
import { Input, Button } from 'components/Form';
import { IonIcon } from '@ionic/react';
import { aim } from 'components/Icons';
import { locationSharp } from 'ionicons/icons';
import { GoogleMap, Autocomplete, LoadScript } from '@react-google-maps/api';
import Helper from 'Helper';

const libraries = ['places'];

class Location extends Component {
  constructor(props) {
    super(props);

    this.map = null;

    this.state = {
      location: {
        lat : 24.7910982,
        lng : 46.6119003
      },
      enterLabel: false,
      label: null,
      loaded: false,
      zoom: 16,
    };

    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    this.handleSelectPosition();
    this.setState({ loaded: true });
  }

  /**
   * Handles Select Position.
   */
  handleSelectPosition() {
    const { location } = this.state;
    if (location && this.map) {
      const { zoom } = this.map.state.map;
      this.setState({ location: null });
      setTimeout(() => this.setState({ location, zoom }), 100);
      return;
    }

    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const { latitude, longitude } = coords;
      this.setState({ location: { lat: latitude, lng: longitude } });
    });
  }

  /**
   * Handle confirm button.
   */
  handleConfirm() {
    this.setState({ enterLabel: false });
    if (this.map) {
      const { center } = this.map.state.map;

      this.props.history.push(Helper.getRoute('locations'), {
        location: { lat: center.lat(), lng: center.lng() },
        label: this.state.label,
      });
    }
  }

  /**
   * Render the component.
   */
  render() {
    const { zoom, location, enterLabel, loaded } = this.state;
    const { t } = this.props;

    /**
     * Google Map Container.
     */
    const MapContainer = loaded && (
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        libraries={libraries}
      >
        <GoogleMap
          ref={(ref) => (this.map = ref)}
          mapContainerClassName="map"
          center={location}
          zoom={zoom}
          options={{
            disableDefaultUI: true,
          }}
        >
          <Autocomplete className="search-input">
            <Input placeholder={t('Search for your place')} />
          </Autocomplete>
        </GoogleMap>
      </LoadScript>
    );

    return (
      <>
        <Page className="location-page has-no-tabs" backButton title={t('Select locations')}>
          <div className="wrapper">
            {MapContainer}
            <IonIcon className="location-mark" icon={locationSharp} />
            <div className="actions">
              <div className="info">
                <IonIcon
                  onClick={() => this.handleSelectPosition()}
                  className="auto-select"
                  icon={aim}
                />
              </div>
              <div className="button">
                <Button onClick={() => this.setState({ enterLabel: true })} full large>
                  {t('Confirm')}
                </Button>
              </div>
            </div>
          </div>
        </Page>
        {enterLabel && (
          <Modal isOpen={true}>
            <div className="enter-label">
              <div className="form-control">
                <Input
                  name="label"
                  onChange={(label) => this.setState({ label })}
                  placeholder={t('Label')}
                />
              </div>
              <div className="form-control">
                <Button onClick={this.handleConfirm} large full>
                  {t('Save')}
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, null)(withNamespaces()(Location));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ select }) {
  return {
    history: select.history,
  };
}
