export const SET_HISTORY = 'SET_HISTORY';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_ORDER = 'SET_ORDER';
export const SET_LOGIN = 'SET_LOGIN';
export const RESET = 'RESET';
export const SET_SHOP = 'SET_SHOP';

export default class SelectAction {
  
  /**
   * Set history provider action.
   * @param {Object} data
   */
  static setHistory(data) {
    return {
      type: SET_HISTORY,
      data,
    };
  }

  /**
   * Set category action.
   * @param {Object} data
   */
  static setLogin(data) {
    return {
      type: SET_LOGIN,
      data,
    };
  }

  /**
   * Set category action.
   * @param {Object} data
   */
  static setCategory(data) {
    return {
      type: SET_CATEGORY,
      data,
    };
  }

  /**
   * Set product action.
   * @param {Object} data
   */
  static setShop(data) {
    return {
      type: SET_SHOP,
      data,
    };
  }

  /**
   * Set product action.
   * @param {Object} data
   */
  static setProduct(data) {
    return {
      type: SET_PRODUCT,
      data,
    };
  }

  /**
   * Set order action.
   * @param {Object} data
   */
  static setOrder(data) {
    return {
      type: SET_ORDER,
      data,
    };
  }

  /**
   * Reset action.
   */
  static reset() {
    return {
      type: RESET,
    };
  }
}
