import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import * as classNames from 'classnames';
import './Cart.scss';
import { Page } from 'components';
import { IonContent, IonFooter, IonAlert, IonGrid,
  IonToast,
  IonRow,
  IonCol,
  IonIcon
 } from '@ionic/react';

import $ from 'jquery';

import CartItem from 'components/Customs/CartItem/CartItem';
import Loading from 'components/Customs/Loading/Loading';
import LoginModal from 'components/Login/LoginModal';
import { withNamespaces } from 'react-i18next';
import Helper from 'Helper';

import Http from 'libs/http';
import AddressService from 'services/address.service';

import { chevronForwardOutline } from 'ionicons/icons';

import { store } from 'store';
import { Button, Input } from 'components/Form';
import { remoteConfig } from 'services/firebase/firebase';

import CacheAction from 'store/actions/cache';

import mixpanel from 'mixpanel-browser';

import * as smoothscroll from 'smoothscroll-polyfill';
import ShopItem from 'components/Customs/ShopItem/ShopItem';
smoothscroll.polyfill();

class Cart extends Component {
    constructor(props) {
        super(props);

        this.http = new Http;
        this.addressService = new AddressService();

        const { cache } = this.props;

        const { shop } = cache;

        let coupon_code = '';
        if ('coupon_code' in cache) {
          coupon_code = cache['coupon_code'];
        }

        // if (isNaN(address?.id)) {
        //   address['id'] = 0;
        //   console.log('error address');
        // }
        // else{
        //   console.log('llded', address);
        // }
        
        this.state = {
          showAlert: null,
          coupon_code : coupon_code,
          coupon : {},
          address : null,
          toast : {
            show : false,
            message : ""
          },
          loginModalOpen : false,
          subtotal : 0,
          discount : 0,
          shipping_cost: 0,
          total : 0,
          maxCartQuantity: 2,
          isFetching: true,
          shop
        };

        // this.state.cart = store.getState().cart;
        this.state.store = store.getState();
        this.handleNext = this.handleNext.bind(this);
        this.handleCloseLoginModal = this.handleCloseLoginModal.bind(this);
        this.handleSubmitCoupon = this.handleSubmitCoupon.bind(this);
        this.handleRemoveCoupon = this.handleRemoveCoupon.bind(this);
        this.handleChangeLocation = this.handleChangeLocation.bind(this);
        this.handleCreateDeliveryLocation = this.handleCreateDeliveryLocation.bind(this);

        this.footerRef = createRef();
        this.selectLocationBtn = createRef();
        this.contentRef = createRef();
    }

    /**
     * Delivery fee.
     */
    get fee() {
      const { delivery_fees } = this.state;
      return 1;
    } 

    get delivery_fees () {
      return this.currentAddress?.shipping_cost || 9;
    }

    get getCartItems () { 
      const { cart, cache } = this.props;
      const { shop } = cache;
      return cart.items.filter((item) => {
        
        // if (shop.id == item.shop_id) {
        //   return true;
        // }

        return true;
      })
    }

    /**
     * Total amount.
     */
     get subtotal() {
      const { discount } = this.state;

      let amount = 0;

      for(let i = 0; i < this.getCartItems.length; i++) {
        amount += this.getCartItems[i]['price'] * this.getCartItems[i]['quantity'];
      }

      return amount;
    }

    /**
     * Total amount.
     */
    get total() {
      const { discount, address } = this.state;
      const { cart } = this.props;

      let shipping_cost =  address?.shipping_cost || 9;

      const amount = (this.subtotal - discount) + shipping_cost;
      return amount;
    }

    get currentAddress() {
      const { address } = this.state;
      return address || null 
    }

    /**
     * On init
     */
    async componentDidMount() {
      await remoteConfig.fetchAndActivate().then(_ => {
        const maxCartQuantity = remoteConfig.getValue("max_cart_quantity")?._value;
        // const shipping_cost = remoteConfig.getValue("shipping_cost")?._value;

        this.setState({
          isFetching: false,
          // shipping_cost : shipping_cost || 9,
          maxCartQuantity: maxCartQuantity || 8,
        })
      })

      const { coupon_code } = this.state;
      const { cart } = this.props;

      if (coupon_code) {
        this.handleSubmitCoupon();
      }

      this.loadLocations();

      if (cart.items.length == 0) {
        return;
      }
      

      // const footerEl = this.footerRef.current;
      // setTimeout(() => {
      //   let tabsElOffset = $('ion-tab-bar').offset();
      //   let bodyHeight = $('body').height();
      //   let footerBottom = (bodyHeight - tabsElOffset['top']);
      //   footerEl.style['bottom'] = Number(footerBottom)+ 'px';
      // }, 200);
      
    }

    calcShippingCost() {

    }

    handleCloseLoginModal() {
      this.setState({loginModalOpen : false});
    }

    handleRemoveCoupon() {
      const { setCache } = this.props;
      this.setState({discount: 0, coupon_code: ''})
      setCache({coupon_code: ''});
    }

    handleSubmitCoupon() {
      const { setCache, auth } = this.props;
      const { user } = auth;

      if (user['isGuest'] == true) {
        return;
      }

      const { coupon_code } = this.state;
      const subtotal = this.subtotal;
      this.http.post('checkout/coupon', {
        code: coupon_code,
        // subtotal
      })
      .then(res => {
        const { error, coupon } = res;
        if(error || !coupon) {
          const { message } = coupon || { message: 'كود الخصم غير صالح' }
          this.setState({ 
            discount : 0,
            toast : {
              show : true,
              message : message,
              position : 'top',
              color : 'danger',
              time : 1200
            }
          });
          return
        }
        const { code, discount, discount_type, message } = coupon;
        setCache({coupon_code: code});
        this.setState({ 
          discount,
          coupon: code,
          toast : {
            show : true,
            message : 'مبروك عليك الخصم',
            position : 'top',
            color : 'success',
            time : 1200
          }
        });
      })
      .catch(err => {
        console.error('err', err);
      });
    }

    loadLocations() {
      
      const { auth, cache, setCache } = this.props;
      const user = auth.user;
      
      if (user.id) {
        const { address } = cache;
        console.log('load address', address);
        this.setState({
          address
        });

        this.addressService.getUserAddresses()
          .then(({error, addresses}) => {
            if(error) return null
            this.setState({
              address : address ? addresses.filter(addrs => addrs.id === address.id)[0] : addresses[0],
              loading : false 
            })
          })
          .catch(err => {
            console.log('err', err);
          });
      }
    }

    handleChangeLocation() {
      const { history } = this.props;
      history.push(Helper.getRoute('checkout/select-address'));
    }

    handleCreateDeliveryLocation() {
      const { history } = this.props;
      history.push(Helper.getRoute('checkout/add-location'));
    }

    /**
     * Handles next button.
     */
    handleNext() {
      const { t, cart, history, auth, cache, setCache } = this.props;
      const { authPage } = cache;
      const { address, store, loginModalOpen } = this.state;
      const { user } = auth;

      if (!address) {
        if (this.contentRef.current) {
          $(this.contentRef.current.shadowRoot)
            .find('.inner-scroll')[0]
            .scroll({ top: 0, left: 0, behavior: 'smooth' });

            setTimeout(() => {
              this.selectLocationBtn.current.classList.add('animate__shakeX');
              setTimeout(() => {
                this.selectLocationBtn.current.classList.remove('animate__shakeX');
              }, 600);
            }, 200);
        }

        return;
      }

      let isGuest = false;
      if (auth != null && auth['user']['type'] == 'guest') {
        isGuest = true;
      }
      
      if (isGuest == true) {
        // this.setState({ loginModalOpen : !loginModalOpen })
        setCache({
          authPage : {
            ...authPage,
            returnTo : Helper.getRoute('checkout'),
          }
        })
        
        history.push(Helper.getRoute('signup'));
        
        return;
      }

      const hasCustomProduct = cart.items.find((item) => item.type !== 'MAIN');

      if (!hasCustomProduct && cart.amount < store.min_order) {
          return this.setState({
              showAlert: t('The minimum amount for the order is') + ': ' + Helper.amountFormat({amount: store.min_order, html: false}),
          });
      }

      history.push(Helper.getRoute('checkout'));
    }

    /**
     * Render the component.
     */
    render() {
        const { 
          showAlert, 
          loginModalOpen, 
          toast, 
          discount, 
          coupon_code,
          address,
          maxCartQuantity,
          isFetching,
          shop
        } = this.state;

        const { t, cart, auth } = this.props;
        const { user } = auth;
        if(isFetching){
          return (
            <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Loading />
            </div>
          )
        }
        
        return (
            <>
                <Page 
                  title={t('Cart')} 
                  hasNeedHelp 
                  withPage={false}
                  className={classNames({
                    'cart-page' : true,
                    'has-no-tabs' : true,
                    'cart-empty' : cart.items.length <= 0,
                  })}
                  backButton={true}
                >
                  <IonContent 
                    ref={this.contentRef} 
                    scrollEvents={true}
                    className={classNames({
                      'cart-empty' : cart.items.length <= 0,
                    })}>
                    <div className="_wrapper">
                        {cart.items.length <= 0 ? (

                          <div style={{paddingTop : '130px', textAlign : 'center'}}>
                            <img src="assets/icon/cart.png" style={{width:'130px'}} />
                            <div className="empty-cart" style={{paddingTop : '5px'}}>{t('Your cart is empty.')}</div>
                          </div>
                        ) : (
                            <>
                              <div className="wrapper">
                                {/* { user?.isGuest != true && ( */}
                                  <div className="selected-location">
                                    <div className="selected-location-header">
                                      <div className="header-title">
                                        {t('Delivery to')}
                                      </div>

                                      <div className="btn-change-location d-none" onClick={this.handleChangeLocation}>
                                        {t('Change location')}
                                      </div>
                                    </div>
                                    <div className="selected-location-content">
                                      {!address ? (
                                        <>
                                          <div className="">
                                            <div 
                                              className=""
                                              style={{textAlign:'center'}}
                                            >
                                              <img src="assets/images/checkout/select-location.png" 
                                                style={{width:'120px'}}
                                              />
                                            </div>
                                            <div className="animate__animated" style={{textAlign:'center'}}
                                              ref={this.selectLocationBtn}
                                            >
                                              <Button 
                                                className={classNames({
                                                  'btn-select-location' : true,
                                                })}
                                                onClick={this.handleCreateDeliveryLocation}
                                                track="add new location - cart"
                                              >
                                                {t('Add delivery location')}
                                              </Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div className="selected-address" onClick={this.handleChangeLocation}>
                                          <div className="" style={{paddingLeft:'10px'}}>
                                            <img src="assets/images/checkout/selected-location-icon.png" 
                                              style={{width:'50px'}}
                                            />
                                          </div>
                                          <div className="" style={{paddingTop:'6px'}}>
                                            <div className="location-title">
                                              {address?.street}
                                            </div>
                                            <div className="location-phone">
                                              {address?.city} | {address?.phone}
                                            </div>
                                          </div>
                                          <div className='nav-icon'>
                                            <IonIcon slot="end" icon={chevronForwardOutline}></IonIcon>
                                          </div>
                                          
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                {/* )} */}

                                {/* <div className='shop-container_cart' style={{marginTop : '15px'}}>
                                  <div className="label">
                                    {t('Store')}
                                  </div>
                                  <div className=''>
                                    <ShopItem 
                                      shop={shop}
                                    ></ShopItem>
                                  </div>
                                </div> */}
                                
                                <div className="label">
                                    {t('Cart Details')}{' '}
                                    <span>
                                        {this.getCartItems.length} {t('Items')}
                                    </span>
                                </div>
                                <div className="products">
                                    {!isFetching && this.getCartItems.map((p) => (
                                      <CartItem key={p.id} product={p} maxCartQuantity={Number.parseInt(maxCartQuantity)}/>
                                    ))}
                                </div>
                              </div>
                                {/* <div className="totals gs-block last"> */}
                                <div className="cart-summary totals last wrapper">
                                  <div>
                                    <div className="coupon-container">
                                      <IonGrid className="ion-no-padding">
                                        <IonRow>
                                          <IonCol size="8">
                                            <Input className="coupon-control"
                                              value={coupon_code}
                                              placeholder={t('You have coupon?')}
                                              onChange={(coupon_code) => this.setState({ coupon_code })}
                                            />
                                          </IonCol>
                                          <IonCol>
                                            <div className="btn-container">
                                              <Button className="btn" onClick={this.handleSubmitCoupon}>{t('Apply')}</Button>
                                            </div> 
                                          </IonCol>
                                        </IonRow>
                                      </IonGrid>
                                    </div>
                                    { discount && coupon_code ? (
                                      <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 5}}>
                                        <button className="btn-text" onClick={this.handleRemoveCoupon}>إزالة رمز الخصم</button> 
                                      </div>
                                    ): null }
                                  </div>

                                    <div style={{padding: 5}}>
                                      <div className="cart-summary-item mega-place-row">
                                        {/* <p> */}
                                          <div className="float-end">
                                            {t('Subtotal')}
                                          </div>
                                          <div className="float-start">
                                            {Helper.amountFormat({amount: this.subtotal})}
                                          </div>
                                        {/* </p> */}
                                      </div>

                                      <div className="cart-summary-item mega-place-row">
                                        {/* <p> */}
                                          <div className="float-end">
                                            {t('Discount')}
                                          </div>
                                          <div className="float-start">
                                            {Helper.amountFormat({amount: discount})}
                                          </div>
                                        {/* </p> */}
                                      </div>

                                      
                                        <div className="cart-summary-item mega-place-row">
                                          {/* <p> */}
                                            <div className="float-end">
                                              {t('Delivery fees')}
                                            </div>
                                            <div className="float-start">
                                              {/* {this.currentAddress != null && ( */}
                                              {Helper.amountFormat({amount: this.delivery_fees})}
                                              {/* )} */}
                                            </div>
                                          {/* </p> */}
                                        </div>
                                      

                                      <div className="cart-summary-item mega-place-row">
                                        {/* <p> */}
                                          <div className="float-end">
                                            {t('Total')}
                                          </div>
                                          <div className="float-start">
                                            {Helper.amountFormat({amount: this.total})}
                                          </div>
                                        {/* </p> */}
                                      </div>

                                    </div>
                                    <div style={{textAlign:"center", paddingTop:"20px"}}>
                                      <img src="assets/images/cart-payments-footer.png" 
                                        style={{maxHeight:"35px"}}
                                      />
                                    </div>
                                </div>
                            </>
                        )}
                      </div>

                    </IonContent>
                    {cart.items.length > 0 &&
                      <IonFooter ref={this.footerRef}>
                        <Button full onClick={this.handleNext}
                          track="Checkout Button"
                          // className={classNames({
                          //   'btn-cart-disabled' : (this.currentAddress == null && user.isGuest != true)
                          // })}
                        >
                          {t('Next')}
                        </Button>
                      </IonFooter>
                    }

                    
                    
                    {/* {cart.items.length > 0 && <ViewCart onClick={this.handleNext}>{t('Next')}</ViewCart>} */}
                </Page>

                <IonAlert
                    mode="ios"
                    isOpen={showAlert !== null}
                    onDidDismiss={() => this.setState({ showAlert: null })}
                    message={showAlert}
                    buttons={['OK']}
                />


                <LoginModal
                  onDismiss={this.handleCloseLoginModal}
                  isOpen={loginModalOpen}
                >

                </LoginModal>

                <IonToast
                  position={toast.position ? toast['position'] : "top"}
                  color={toast.color ? toast['color'] : "danger"}
                  isOpen={toast.show}
                  onDidDismiss={() => this.setState({toast : { show : false, message : '' }})}
                  message={toast.message}
                  duration={toast.time ? toast.time : 800}
                />

            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Cart));


/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ cart, auth, select, cache }) {
    // const userCart = () => {
    //     try {
    //       return cart;
    //     } catch (e) {
    //       return {
    //           items: [],
    //           amount: 0,
    //           total: 0,
    //       };
    //     }
    // };
    return {
      auth : auth,
      cart,
      // cart: userCart(),
      store: auth.store,
      cache : cache,
      history: select.history,
    };
}


/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function mapDispatchToProps(dispatch) {
  return {
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}
