import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './CartItem.scss';
import { IonItem, IonGrid, IonRow, IonCol } from '@ionic/react';
import ReactImageAppear from 'react-image-appear';
import Helper from 'Helper';
import { cartMapStateToProps, cartMapDispatchToProps } from 'store/maps/cart';
import { noImageFound } from 'components/Icons';
import { withNamespaces } from 'react-i18next';

import { cdnImage } from 'libs/pipes';

/**
 * Product Item.
 */
const CartItem = ({ children, t, product, editable, className, maxCartQuantity, inCart, auth, ...props }) => {
  const [quantity, setQuantity] = useState(product.quantity);
  const [isAddable, setIsAddable] = useState(quantity < maxCartQuantity)
  // console.log(quantity, '----', maxCartQuantity, isAddable)
  const { name, price, image, type, variants } = product;

  const handleAddQuantity = () => {
    if(quantity < maxCartQuantity - 1){
      setQuantity(quantity + 1)
    }
    else if (quantity == maxCartQuantity - 1){
      setQuantity(quantity + 1)
      setIsAddable(false)
    }
  }

  const handleMinusQuantity = () => {
    if(quantity > 0){
      setQuantity(quantity - 1)
      setIsAddable(true)
    }
  }

  /**
   * Listen to cart quantity changes.
   */
  useEffect(() => {
    if (quantity !== inCart) {
      if (quantity > inCart) {
        // Add quantity
        props.addQuantity(product, auth);
      } else if (quantity > 0) {
        // Sub. quantity
        props.subQuantity(product, auth);
      } else {
        // Remove from cart
        props.removeFromCart(product, auth);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setQuantity, quantity]);

  return (
    product && (
      <div className='cart-item'>
        <div className='cart-item-img mx-auto' style={{
          background:`url(${image || noImageFound}`,
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}>

        </div>
        {/* <div style={{width: '30vw'}}>
          <ReactImageAppear className="image" src={cdnImage(image) || noImageFound} />    
        </div> */}
        <div style={{width: '70vw'}}>
          <p className='item-name'>{name}</p>
          {/* <p style={{color : '#333', fontSize: 10}}>
            العلامة التجارية
          </p> */}
          <div className='item-options'>
            {variants.map(option => (
              <p><small>{option.name}</small></p>
            ))}  
          </div>

          <span style={{color : '#333', fontSize: 16}}>
            { Helper.amountFormat({ amount: price }) }
          </span>

          {type && <p>{type === 'MAIN' ? t('From store') : 'طلب خاص'}</p>}
          
          <span>{type === 'MAIN' && Helper.amountFormat({ amount: price })}</span>
          {children}
          <div className="add-quantity float-end">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <button disabled={!isAddable} style={!isAddable ? {color: '#ccc'} : {}} onClick={handleAddQuantity}>+</button>
                </IonCol>
                <IonCol>
                  <div className="quantity-display">
                    {quantity}
                  </div>
                </IonCol>
                <IonCol>
                  {editable && (
                    <button onClick={handleMinusQuantity}>-</button>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <IonRow style={{justifyContent: 'flex-end', marginTop: 15, padding: 5, color: '#FF000070'}}>
            {quantity == maxCartQuantity && <small>لايمكنك تحديد كمية اكثر</small>}
          </IonRow>
        </div>

      </div>
    )
  );
};

CartItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  editable: PropTypes.bool,
  product: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.string,
    img: PropTypes.string,
    quantity: PropTypes.number,
  }),
};

CartItem.defaultProps = {
  product: null,
  editable: true,
};

export default connect(
  cartMapStateToProps,
  cartMapDispatchToProps
)(withNamespaces()(CartItem));
