import CartAction from 'store/actions/cart';

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
export function onlyCartMapStateToProps({ cart, auth }) {
  try {
    let shop_id = 0;
    
    if (cart.items.length != 0) {
      let lastItem = cart.items[cart.items.length - 1];
      shop_id = lastItem?.shop_id;
    }


    let inCart = 0;
    for(let i = 0; i < cart.items.length; i++) {
      let item = cart.items[i];
      if (shop_id == item.shop_id) {
        inCart += item.quantity;
      }
    }    

    return {
      cart: cart,
      inCart,
      // inCart: cart.items.reduce((total, curr) => total + curr.quantity, 0),
      auth,
    };
  } catch (e) {
    return {
      auth,
      cart: {
        items: [],
        total: 0,
        amount: 0,
      },
    };
  }
}

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
export function cartMapStateToProps({ cart: cartState, auth }, { product }) {
  const { cart } = onlyCartMapStateToProps({ auth, cart: cartState });

  let inCart = 0;
  if (cart.items) {
    for (const item of cart.items) {
      if (product && item.id === product.id) {
        inCart += item.quantity;
      }
    }
  }

  return {
    cartItems: cart.items,
    inCart,
    auth,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
export function cartMapDispatchToProps(dispatch) {
  return {
    addToCart: (data, auth) => dispatch(CartAction.addToCart(data, auth)),
    removeFromCart: (data, auth) => dispatch(CartAction.removeFromCart(data, auth)),
    subQuantity: (data, auth) => dispatch(CartAction.subQuantity(data, auth)),
    addQuantity: (data, auth) => dispatch(CartAction.addQuantity(data, auth)),
  };
}
