import React from 'react';
const IMAGE_CDN = process.env.REACT_APP_CDN;

export const cdnImage = (file, placeholder = '') => {
  
  if (typeof file == 'string') {
    if (file.startsWith('http')) {
      return file; 
    }

    return IMAGE_CDN + '/' + file; 
  }

  if (file == null || typeof file != 'object') {
    return placeholder;
  }

  if ('file_name' in file) {
    return IMAGE_CDN + '/' + file['file_name']; 
  }
};


export const amountFormat = (amount, html = true) => {
    const price = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount > 0 ? amount : 0);
    // const currency = i18next.language === 'en' ? 'SAR' : 'ريال';
    const currency =  'ريال';

    if (!html) {
      return price + ' ' + currency;
    }

    return (
      <>
        <span>{price}</span> {currency}
      </>
    );
}