import React from 'react';
import PropTypes from 'prop-types';

import './Layout.scss';
import { Page } from 'components';
import { IonPage, IonImg, IonContent, IonItemGroup, IonToolbar } from '@ionic/react';
import classNames from 'classnames';

export const Layout = ({ children, title, className }) => {
  return (
    <Page backButton title={title} className={classNames('auth auth-page', className)}>
      {/* <IonContent className="content"> */}
        <div className="content">
          {/* <div className="logo" style={{textAlign : 'center'}}>
            <img src="/assets/images/logo-icon.png" alt="logo"
              style={{ width : '100px', height : '100px' }}
            />
          </div> */}
          <IonItemGroup>{children}</IonItemGroup>
        </div>
      {/* </IonContent> */}
    </Page>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  title : PropTypes.string
};

export default Layout;
