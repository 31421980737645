import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Select.scss';
import { IonPicker } from '@ionic/react';
import { Input } from 'components/Form';

const Select = ({
  label,
  disabled,
  required,
  className,
  onChange,
  columns,
  isOpen: isOpenProp,
  value: valueProp,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(isOpenProp);
  const [value, setValue] = useState(valueProp);

  /**
   * Handles select picker
   * @param {Object} column
   */
  const handleSelect = (column) => {
    const { options, name } = columns[0];
    const value = column[name];
    const selectedIndex = options.findIndex((o) => o.value === value.value);
    setValue(value);
    return onChange({ selectedIndex, value });
  };

  const { options, selectedIndex, name } = columns[0] || {};

  // Picker buttons
  const buttons = [
    {
      // This will be consider as a title if IonPicker.cssClass contains `has-title`.
      text: name,
      cssClass: 'title',
    },
    {
      text: 'Save',
      cssClass: 'btn',
      handler: handleSelect,
    },
  ];

  // Reset the picker
  options &&
    options.forEach((element) => {
      delete element.selected;
      delete element.duration;
      delete element.transform;
    });

  // Generate default value
  const defaultText = options && options[selectedIndex].text;

  // Select label
  const Label = () => (
    <>
      <div className="label">{value ? value.text : defaultText}</div>
      <div className="select-icon" role="presentation" part="icon">
        <div className="select-icon-inner"></div>
      </div>
    </>
  );

  return (
    <div
      className={classNames('select', className)}
      onClick={() => !disabled && setIsOpen(!isOpen)}
    >
      <Input id="picker-select" readOnly={true} {...restProps} />
      <Label />
      <IonPicker
        mode="ios"
        cssClass="select-picker has-title"
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(!isOpen)}
        columns={[
          {
            name,
            selectedIndex,
            options,
          },
        ]}
        buttons={buttons}
      />
    </div>
  );
};

Select.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          value: PropTypes.any.isRequired,
          disabled: PropTypes.bool,
          selected: PropTypes.bool,
        })
      ),
    })
  ),
};

Select.defaultProps = {
  columns: [],
  onChange: () => null,
};

export default Select;
