import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ViewImg.scss';
import ReactImageAppear from 'react-image-appear';
import { IonContent, IonToolbar, IonButton, IonButtons, IonHeader } from '@ionic/react';
import { withNamespaces } from 'react-i18next';

/**
 * Image modal.
 */
const ViewImg = ({ t, src, onClose, className }) => {
  return (
    <IonContent className={classNames('view-img', className)}>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={onClose}>{t('Close')}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <div className="content">
        <div className="image-loader">
          <ReactImageAppear src={src} />
        </div>
      </div>
    </IonContent>
  );
};

ViewImg.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

ViewImg.defaultProps = {
  onClose: () => null,
};

export default withNamespaces()(ViewImg);
