import { SET_CACHE, RESET_CACHE } from '../actions/cache';

const initialState = {
  version : 0,
  categories: {
    data: null,
  },
  block : {
    blocks : [],
    collections : {},
    scripts : []
  },
  user : {
    address_id : null,
  },
  authPage : {
    name : '',
    email : '',
    phone : '',
    returnTo : null,
  },
  catalog : {
    data : {},
    created_at : null,
  },
  branch : null,
  address : {},
  firstTime: true,
  filters : [],
  coupon_code : '',
  locations : [],
  lang : 'en',
  shop : null
};

const cache = (state = initialState, action) => {
  switch (action.type) {
    case SET_CACHE:
      return {
        ...state,
        ...action.data,
      };

    case RESET_CACHE:
      return initialState;

    default:
      return state;
  }
};

export default cache;
 