import React, { useState } from 'react';
import { connect } from 'react-redux';

import './Product.scss';
import { heartSharp, flagOutline } from 'ionicons/icons';
import { Page } from 'components';
import ReactImageAppear from 'react-image-appear';
import { Button } from 'components/Form';
import { noImageFound } from 'components/Icons';
import { IonToast, IonRow, IonCol } from '@ionic/react';
import ViewImg from './ViewImg/ViewImg';
import Helper from 'Helper';
import { withNamespaces } from 'react-i18next';
import { Redirect } from 'react-router';
import i18next from 'i18next';
import { cartMapDispatchToProps, onlyCartMapStateToProps } from 'store/maps/cart';
import ViewCart from 'components/ViewCart/ViewCart';
import FavButton from 'components/Customs/FavButton/FavButton';
import AppService from 'services/app.service';
import { useHistory } from 'react-router-dom';

const Product = ({ t, auth, select, ...props }) => {
  
  const [showToast1, setShowToast1] = useState(false);
  const history = useHistory();
  const product = null;
  
  console.log('props', props);
  /**
   * Get product from params.
   */
  const getProduct = () => {
    return new Promise((resolve, reject) => {
      const { cart } = props;

      if (!select || !select.product) {

        return null;
      }

      const product = select.product;
      if (cart.items) {
        for (const item of cart.items) {
          if (item.id === product.id) {
            return resolve(item);
          }
        }
      }
      return resolve(product);
    })
  };

  


  /**
   * States
   */
  const [viewImg, setViewImg] = useState();
  const [cart, setCart] = useState(product && product.quantity ? product.quantity : 0);

  /**
   * Handles cart changes.
   * @param {Number} q
   */
  const handleChangeCart = (q) => {
    if (!cart) {
      // Add to cart
      props.addToCart(product, auth);
    } else {
      if (q > cart) {
        // Add quantity
        props.addQuantity(product, auth);
      } else if (cart > q && q > 0) {
        // Sub. quantity
        props.subQuantity(product, auth);
      } else {
        // Remove from cart
        props.removeFromCart(product, auth);
      }
    }

    setCart(q);
  };

  if (!product) {
    return <Redirect to={Helper.getRoute('products')} />;
  }

  const { name, price, size, image } = product;

  const service = new AppService();
  const buttons = [
    // {
    //   icon: heartSharp,
    //   onClick: () => {
    //     setShowToast1(true);
    //     service.editFavorite(auth, product.id, 'add')
    //       .then(res => {

    //       })
    //   },
    // },
  ];

  const navToLogin = () => {
    history.push(Helper.getRoute('login'));
  }

  let isGuest = false;
  if (auth != null && auth['user'] != null) {
    if (auth.user['isGuest'] == true) {
      isGuest = true;
    }
  }

  const { fav } = props;

  return (
    <>
      <Page backButton buttons={buttons} className="product-page">
        <div className="wrapper">
          <div className="image image-loader">
            <ReactImageAppear
              onClick={() => setViewImg(image)}
              animation="none"
              src={image || noImageFound}
            />
          </div>
        </div>
        
        <div className="info" style={{paddingTop:'10px', borderBottom : '1px solid #eee'}}>
          <h2 style={{fontSize:'16px'}} className="title">{name}</h2>
          <div className="price" style={{ fontSize : '20px' }}>{Helper.amountFormat({amount: price})}</div>
          <small>شامل الضريبة</small>
        </div>
        <div>
          <div style={{ width : '100%', overflow : 'hidden', paddingTop : '6px' }}>
            <div style={{ float:'right', marginLeft : '8px' }}>
              {!cart && (
                <div className="add-to-cart">
                  <div className="action">
                    {/* {isGuest == true && (
                      <Button  onClick={() => navToLogin()}>{t('Add To Cart')}</Button>
                    )}
                    {isGuest == false && ( */}
                      <Button  onClick={() => handleChangeCart(cart + 1)}>{t('Add To Cart')}</Button>
                    {/* )} */}
                  </div>
                </div>
              )} 
              {cart > 0 && (
                <div className="quantity">
                  <div className="action">
                    <button type="button" onClick={() => handleChangeCart(cart + 1)}>
                      +
                    </button>
                    {cart}
                    <button type="button" onClick={() => cart && handleChangeCart(cart - 1)}>
                      -
                    </button>
                  </div>
                </div>
              )}
            </div>

            <FavButton favList={fav} product={product} />
          </div>
          <div>
          {!cart && (
            <div className="price" style={{ fontSize : '20px' }}>{Helper.amountFormat({amount: price})}</div>
          )}
          {cart > 0 && (
              <div className="quantity">
                <div className="prices">
                  <IonRow>
                    <IonCol>
                      <div className="block">
                        <label>{t('Unit Price')}</label>
                        <b>{Helper.amountFormat({amount: price})}</b>
                      </div>
                    </IonCol>
                    <IonCol>
                      <div className="block">
                        <label>{t('Total Price')}</label>
                        <b>{Helper.amountFormat({amount: price * cart})}</b>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
            )}
          </div>

          {/* <div className="" style={{ display:'none' }}>
            <div style={{float:"right"}}>
              <FavButton favList={fav} product={product} />
            </div>
            <div style={{float:"right"}}>
              {!cart && (
                <div className="add-to-cart">
                  <div className="action">
                    <Button  onClick={() => handleChangeCart(cart + 1)}>{t('Add To Cart')}</Button>
                    <div className="price">{Helper.amountFormat(price)}</div>
                  </div>
                </div>
              )}
            {cart > 0 && (
              <div className="quantity">
                <div className="action">
                  <button type="button" onClick={() => handleChangeCart(cart + 1)}>
                    +
                  </button>
                  {cart}
                  <button type="button" onClick={() => cart && handleChangeCart(cart - 1)}>
                    -
                  </button>
                </div>

                <div className="prices">
                  <IonRow>
                    <IonCol>
                      <div className="block">
                        <label>{t('Unit Price')}</label>
                        <b>{Helper.amountFormat(price)}</b>
                      </div>
                    </IonCol>
                    <IonCol>
                      <div className="block">
                        <label>{t('Total Price')}</label>
                        <b>{Helper.amountFormat(price * cart)}</b>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
            )}
            </div> 
            
          </div> */}

        </div>
        <IonToast
          color="danger"
          isOpen={showToast1}
          onDidDismiss={() => setShowToast1(false)}
          message="تم اضافة المنتج للمفضلة"
          duration={1200}
        />
      </Page>
      <ViewCart href={Helper.getRoute('cart')} />
      {/* <IonFab className="hide-if-ee" vertical="bottom" horizontal="end" slot="fixed">
        {props.cart.total > 0 && <IonBadge color="tertiary">{props.cart.total}</IonBadge>}
        <IonFabButton routerLink={Helper.getRoute('cart')} color="secondary">
          <IonIcon icon={cartIcon} />
        </IonFabButton>
      </IonFab> */}
      {viewImg && <ViewImg onClose={() => setViewImg(null)} src={viewImg} />}
    </>
  );
};

export default connect(mapStateToProps, cartMapDispatchToProps)(withNamespaces()(Product));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
export function mapStateToProps({fav, select, ...state }) {
  return {
    select,
    fav,
    ...onlyCartMapStateToProps(state),
  };
}
