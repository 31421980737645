import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import './SelectAddress.scss';

import { withNamespaces } from 'react-i18next';
import { Page } from 'components';
import { Button } from 'components/Form';
import { IonIcon, IonLoading, IonSpinner } from '@ionic/react';
import { shopFlat } from 'components/Icons';
import { Link } from 'react-router-dom';
import AppService from 'services/app.service';
import AddressService from 'services/address.service';
import Helper from 'Helper';
import AuthAction from 'store/actions/auth';
import CartAction from 'store/actions/cart';
import CacheAction from 'store/actions/cache';

import Http from 'libs/http';

class SelectAddress extends Component {
  constructor(props) {
    super(props);

    this.http = new Http;

    this.state = {
      loading: true,
      stores: [],
      addresses : []
    };

    this.service = new AppService();
    this.addressService = new AddressService();
  }

  async componentDidMount() {
    
    // this.setState({ loading: true });
    const { auth, cache } = this.props;
    const { locations } = cache;
    console.log('cachce', cache['locations']);

    this.setState({
      loading : true
    });

    const user = auth.user;

    this.http.post('customer/address/index', {
      user_id : user.id
    })
    .then(res => {
      console.log('res', res);
      const { error, addresses } = res
      if(!error){
        this.setState({
          addresses : addresses,
          loading : false 
        })
      }
    })
    .catch(err => {
      console.log('err', err);
    });
    
    // const state = this.props.history.location.state;

    // if (state) {
    //   location = state.location;
    //   this.service.setUserLocation(user.id, firstUserLocationId, {
    //     geolocation: location,
    //     label: state.label,
    //   });
    // } else if (
    //   userLocation &&
    //   userLocation.geolocation &&
    //   Object.keys(userLocation.geolocation).length
    // ) {
    //   location = userLocation.geolocation;
    // } else {
    //   return this.props.history.push(Helper.getRoute('find-location'));
    // }

    // this.service.getStoresByLocation(location).then((stores) => {
    //   this.setState({ addresses, loading: false });
    // });
  }

  /**
   * Handles select.
   * @param {Object} store
   */
  handleSelect(address) {
    const { history, setCache } = this.props;

    // if (!store.delivery_times) {
    //   alert('Store is not open at the moment.');
    //   return;
    // }

    // const cartExists = () => {
    //   try {
    //     return cart[auth.user.id][store.id];
    //   } catch (e) {
    //     return false;
    //   }
    // };

    // if (!cartExists()) {
    //   initCart({ ...auth, store });
    // }

    // setStore(store);

    setCache({
      address
    })
    
    history.push(Helper.getRoute('cart'));
  }

  render() {
    const { addresses, stores, loading } = this.state;
    const { t } = this.props;

    /**
     * Item content.
     */
    const Content = ({
      city,
      phone,
      name,
      isLocation,
      children,
    }) => (
      <>
        {isLocation && (
          <div>
            <div style={{width : '100%'}}>
              <strong>{name}</strong>
            </div>
            
            <div className="attrs">
              <p>
                {phone}
              </p>
              <p>
                { city }
              </p>
            </div>
          </div>
        )}
        {children}
      </>
    );

    /**
     * Item options.
     */
    const Options = ({ isDefault }) => (
      <div className="options">
        <button className={classNames('text', isDefault ? 'default' : '')}>
          {isDefault ? t('Default') : t('Make default')}
        </button>
      </div>
    );

    return (
      <Page className="select-address-page has-no-tabs" backButton title={t('My locations')}>
        {/* <IonLoading cssClass="gs-loading" mode="ios" isOpen={loading} /> */}
        

        {loading && (
          <div className='text-center' style={{padding : 20}}>
            <IonSpinner></IonSpinner>
          </div>
        )} 

        {loading == false && (
          <div className="locations">
            <h4>{t('Select your location')}</h4>
            {addresses.map((address, i) => (
              <div key={i} className="item" onClick={() => this.handleSelect(address)}>
                <Content isLocation icon={shopFlat} {...address} />
              </div>
            ))}
            <Button full style={{ marginTop: 40, textAlign: 'center', border : '1px solid #eee' }}>
              <Link className="text-white" to={Helper.getRoute('checkout/add-location')}>{t('Add new address')}</Link>
            </Button>
          </div>
        )}
        
      </Page>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(SelectAddress));

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ auth, cart, cache, select }) {
  return {
    auth,
    cart,
    cache,
    history: select.history,
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    setStore: (data) => dispatch(AuthAction.setStore(data)),
    initCart: (auth) => dispatch(CartAction.emptyCart(auth)),
    setCache: (data) => dispatch(CacheAction.setCache(data)),
  };
}
